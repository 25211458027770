import store from '@/store'
import router from '@/router'
import request from "@/utils/request";
import nf from "../utils/notification";


export default {
    loggedIn() {
        let data = {
            token: store.getters.token
        };
        if (store.getters.token) {
            let url = 'api-token-verify/';
            request.post(url, data).then(() =>{
            }).catch(() => {
                nf.showWarnMsg("你的会话已经超时，请重新登录");
                store.dispatch("logout").then(() => {
                    router.replace("/login").then();
                });

            });
        }
        else {
            store.dispatch("logout").then(() => {
                router.replace("/login").then();
            });
        }
        return store.getters.loggedIn
    },
}

export function has_perm(value: string) {
    // console.log(store.getters.permissionList)
    let permissionList = store.getters.permissionList

    for (let permission of permissionList) {
        let children_permission = permission.children
        for (let cp in children_permission) {
            if (children_permission.hasOwnProperty(cp)) {
                for (let item of children_permission[cp]) {
                    if (item.name === value) {
                        return true
                    }
                }
            }
        }
    }
    return false
}

export function has_perm_combo(value: string, org: any, customer: any) {
    let org_level_priority = 0
    if (org.level_code_organization != null) {
        org_level_priority = org.level_code_organization.level_priority
    }
    let is_shadow = org_level_priority == 10000
    let is_same_company = org.sid == customer.organization.sid
    if (is_shadow) {
        return has_perm(value) && is_same_company
    } else {
        let customer_level_priority = 0
        if (customer.organization.level_code_organization != null) {
            customer_level_priority = customer.organization.level_code_organization.level_priority
        }
        let higher_priority = org_level_priority > customer_level_priority
        return has_perm(value) && (higher_priority || is_same_company)
    }
}