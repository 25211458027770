<template>
  <v-card style="width: 100%">
    <v-card-title class="pb-0">
      <v-subheader>{{ instance.instance_name }}</v-subheader>
      <span v-show="false">{{ trigger }}</span>
      <v-spacer></v-spacer>
      <!-- <v-tooltip bottom><v-icon color="success" :loading="dialog_view_report" left slot="activator" style="padding-right: 8px" @click="showReport">mdi-file-pdf-box</v-icon><span>看报告</span></v-tooltip> -->
      <v-tooltip
        bottom
        v-if="
          this.$store.getters.showReviewHistory &&
          instance.internal_status !== 'INIT'
        "
      >
        <template v-slot:activator="{ on }">
          <v-icon color="primary" left v-on="on" @click="doSetReviewHistory"
            >mdi-timetable</v-icon
          >
        </template>
        <span>隐藏审核历史</span></v-tooltip
      >
      <v-tooltip bottom v-else-if="instance.internal_status !== 'INIT'">
        <template v-slot:activator="{ on }">
          <v-icon
            color="grey darken-1"
            left
            v-on="on"
            @click="doSetReviewHistory"
            >mdi-timetable</v-icon
          >
        </template>
        <span>显示审核历史</span></v-tooltip
      >
    </v-card-title>
    <v-card-text class="py-0">
      <v-subheader
        class="caption font-weight-medium"
        style="padding-top: 0px; padding-bottom: 0px; height: 30px"
      >
        {{ customer.individual_no }}-{{ customer.individual_name }}-{{
          customer.organization.org_name
        }}
      </v-subheader>
    </v-card-text>
    <v-card-text class="py-0" v-if="isViewPassword">
      <v-subheader
        class="caption font-weight-medium"
        style="padding-top: 0px; padding-bottom: 0px; height: 30px"
      >
        {{ instance.sid | getSidNoDash }}
      </v-subheader>
    </v-card-text>
    <v-card-text class="pt-2">
      <v-layout row wrap>
        <v-flex d-flex xs12 class="px-6 py-6">
          <v-text-field
            v-model="instance.report_date"
            label="报告日期"
            readonly
            style="padding: 0px"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="report_template.report_type === 'S'">
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
          <v-select
            v-model="select"
            :hint="`送检单位：${select.org_name}`"
            :items="org_infos"
            item-text="org_name"
            item-value="sid"
            label="送检单位"
            persistent-hint
            return-object
            single-line
            :rules="report_instance_rules.test_company"
            readonly
          ></v-select>
        </v-flex>
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
          <v-text-field
            label="样本编号"
            v-model="instance.sample_no"
            :rules="report_instance_rules.sample_no"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
          <v-text-field
            label="样本类型"
            v-model="instance.sample_type"
            :rules="report_instance_rules.sample_type"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
          <v-text-field
            label="采样日期"
            v-model="instance.sample_date"
            :rules="report_instance_rules.sample_date"
            hint="格式YYYY-MM-DD"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
          <v-text-field
            label="检测日期"
            v-model="instance.test_date"
            :rules="report_instance_rules.test_date"
            hint="格式YYYY-MM-DD"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex
          d-flex
          xs12
          sm6
          md4
          class="px-9 pt-6"
          v-for="template_detail in report_instance_details"
          :key="template_detail.sid"
        >
          <v-layout row wrap>
            <v-flex d-flex xs12>
              <v-subheader style="height: 32px">
                {{ template_detail.character_instance.instance_name }}
              </v-subheader>
            </v-flex>
            <v-radio-group
              v-model="template_detail.selected_value"
              row
              class="pb-6 pl-3"
              readonly
            >
              <v-flex
                d-flex
                xs12
                style="padding: 12px 0px; height: 32px"
                v-for="instance_detail in template_detail.instance_details"
                :key="instance_detail.sid"
                @click="() => handleCharactersClick(instance_detail)"
              >
                <v-radio
                  :label="
                    instance_detail.instance_name +
                    '(' +
                    instance_detail.happen_date +
                    ')'
                  "
                  :value="instance_detail.sid"
                  color="primary"
                ></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        class="mr-2"
        :loading="dialog_view_report"
        @click.stop="showReport"
      >
        <v-icon dark left>mdi-file-pdf-box</v-icon>
        看报告
      </v-btn>
      <v-dialog
        v-model="dialog_publish"
        persistent
        max-width="290"
        v-if="(instance.internal_status == 'RRA') & has_publish_report_right"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="warning" class="mr-2" v-on="on">发布报告</v-btn>
        </template>
        <v-card v-if="dialog_publish">
          <v-card-title class="title">您确认发布报告吗?</v-card-title>
          <v-card-text
            >一旦发布，报告将直接发送给C端客户，无法撤回。请仔细核对</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click.native="dialog_publish = false"
              :loading="dialog_save"
              >取消</v-btn
            >
            <v-btn
              color="error"
              text
              @click.native="doPublishReport"
              :loading="dialog_save"
              >确认发布</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
    </v-card-title>
  </v-card>
</template>
<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "ViewReport",
  inject: ['individualId', 'handleShowDrawer', 'handleDrawerClose'],
  props: {
    id: {
      type: String,
    },
    sid: {
      type: String,
    },
  },
  data() {
    return {
      valid: false,
      modal: false,
      dialog_publish: false,
      dialog_save: false,
      dialog_view_report: false,
      customer: {
        doctor: {},
        organization: {},
      },
      isShow: false,
      trigger: false,
      isViewPassword: false,
      instance_details: [],
      report_instance_details: [],
      instance: {},
      org_info: {
        sid: "",
        org_name: "",
      },
      select: { org_name: "", sid: "" },
      org_infos: [],
      report_template: {},
      report_instance_rules: {
        sample_no: [(v) => !!v || "不能为空"],
        sample_type: [(v) => !!v || "不能为空"],
        test_company: [(v) => !!v || "不能为空"],
        sample_date: [
          (v) => !!v || "不能为空，格式YYYY-MM-DD",
          (v) =>
            /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
              v
            ) || "日期格式错误",
        ],
        test_date: [
          (v) => !!v || "不能为空，格式YYYY-MM-DD",
          (v) =>
            /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
              v
            ) || "日期格式错误",
        ],
      },
    };
  },
  computed: {
    has_publish_report_right() {
      return this.$store.getters.getPermission('reportinstance', 'publish');
    },
  },
  mounted() {
    this.doLoadData();
  },
  methods: {
    async doLoadData() {
      //获得模版实例
      this.instance = await api.get(
        "report_instances/" + this.sid + "/?",
        null
      );
      this.org_infos = await api.get("organizations/?", null);
      for (let org of this.org_infos) {
        if (org.sid === this.instance.test_company) {
          this.select.sid = org.sid;
          this.select.org_name = org.org_name;
        }
      }
      this.report_template = await api.get(
        "report_templates/" + this.instance.report_template.sid + "/?",
        null
      );
      let data = await api.get(
        "users/can_view_report_instance_password/?",
        null
      );
      this.isViewPassword = data["result"];
      //获取模版实例明细
      this.report_instance_details = await api.get(
        "report_instance_details/?instance=" + this.instance.sid,
        null
      );
      this.customer = await api.get("individuals/" + this.id + "/?", null);
      this.instance_details = await api.get(
        "report_templates/" +
          this.instance.report_template.sid +
          "/get_available_template_instances/?d=" +
          this.instance.report_date +
          "&i=" +
          this.customer.sid +
          "&sid=" +
          this.instance.sid,
        null
      );
      for (let template_detail of this.report_instance_details) {
        template_detail.instance_details = [];
        template_detail.selected_value = template_detail.character_instance.sid;
        for (let instance_detail of this.instance_details) {
          if (
            template_detail.character_instance.template.sid ===
            instance_detail.template.sid
          ) {
            template_detail.instance_details.push(instance_detail);
          }
        }
      }
      this.trigger = !this.trigger;
    },
    async doPublishReport() {
      this.dialog_save = true;
      await api.put(
        "report_instances/" + this.instance.sid + "/report_publish/?",
        null
      );
      nf.showSuccessMsg("报告发布成功！");
      this.dialog_save = false;
      
      this.doBackHistory();
    },
    doSetReviewHistory() {
      this.$store.dispatch("review_history");
    },
    doBackHistory() {
      this.$router.back(-1);
    },
    async showReport() {
      if (this.report_template.report_type=='W') {
        this.handleDrawerClose()
        await this.$nextTick()
        this.handleShowDrawer(this.instance.instance_name, 'WebReportFrameContain', {
          url: `${location.origin}/#/RPT-MPBH-V1/0/${this.sid}`
        })
        return
      }
      this.dialog_view_report = true;
      let data = await api.get(
        "report_instances/" + this.sid + "/get_pdf_report/?",
        { responseType: "blob" }
      );

      let binaryData = [];
      binaryData.push(data);

      let filename =
        this.customer.individual_name +
        "_" +
        this.instance.instance_name +
        "_" +
        this.customer.individual_no +
        "_" +
        this.instance.report_date.replace(/-/g, "");
      let url = window.URL.createObjectURL(
        new Blob(binaryData, { type: "application/pdf" })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename + ".pdf");
      document.body.appendChild(link);
      link.click();
      this.dialog_view_report = false;
    },
    async handleCharactersClick(detail) {
      this.handleDrawerClose()
      await this.$nextTick()
      this.handleShowDrawer(detail.instance_name, 'CharacterViewRecord', { id: this.individualId, sid: detail.sid })
    }
  },
};
</script>
