import { render, staticRenderFns } from "./characters-trend.vue?vue&type=template&id=33c5c258&scoped=true&"
import script from "./characters-trend.vue?vue&type=script&lang=js&"
export * from "./characters-trend.vue?vue&type=script&lang=js&"
import style0 from "./characters-trend.vue?vue&type=style&index=0&id=33c5c258&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c5c258",
  null
  
)

export default component.exports