<template>
  <v-card style="width: 100%" v-show="data.length > 0">
    <v-subheader>
      健康管理评价报告
      <v-spacer />
      <v-switch
        style="margin:0 16px"
        v-model="showCurrent"
        label="仅显示当前管理计划"
        hide-details
      />
    </v-subheader>
    <v-simple-table>
      <thead>
        <tr>
          <th>名称</th>
          <th>编号</th>
          <th>评价日期</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in tableData" :key="d.sid">
          <td><a @click="() => handleReportClick(d)">{{d.report_name}}</a></td>
          <td>{{d.report_no}}</td>
          <td>{{d.report_date}}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
export default {
  name: "EvaluateReport",
  inject: ['individualId', 'handleShowDrawer', 'handleDrawerClose'],
  data() {
    return {
      showCurrent: false,
      data: [],
      planId: ''
    }
  },
  computed: {
    tableData() {
      let data = this.data
      if (this.showCurrent) {
        data = data.filter(d => d.plan == this.planId)
      }
      return data
    }
  },
  async mounted() {
    const { msg } = await api.get(`health_manage/get_evaluation_report_list/?i=${this.individualId}`)
    msg.map(d => {
      if (d.report_no && d.report_no.indexOf('-') > 1) {
        d.report_no = d.report_no.split('-')[1].substr(2)
      }
    })
    this.data = msg
    const actionDetailList = await api.get(`health_manage/get_action_template/?a=${this.$route.params.actionId}`)
    this.planId = actionDetailList[0].action.plan
  },
  methods: {
    async handleReportClick(item) {
      this.handleDrawerClose()
      await this.$nextTick()
      switch(item.report_type) {
        case 'E':
          this.handleShowDrawer(item.report_name, 'StageEvaluationReport', { reportSid: item.sid })
          break
        default:
          this.handleShowDrawer(item.report_name, 'CurativeEffectReport', { reportSid: item.sid })
      }
    }
  }
}
</script>