<template>
  <v-row>
    <v-col sm="9">
      <v-row>
        <v-col sm="4">
          <div class="valueitem">
            <div class="valuehead">
              <v-icon class="valueicon">mdi-account-supervisor</v-icon>
              <p>客户总数量</p>
              <h1>{{customerCount}}</h1>
            </div>
            <div class="valuechart">
              <Echart :options="{
                grid: {
                  right: '8px',
                  bottom: '8px',
                  top: '16px'
                },
                tooltip: {
                  trigger: 'item',
                  position: 'bottom',
                  formatter: obj => {
                    const pl = 'style=\'padding-left:16px\''
                    const d = obj.data;
                    return `<table>
                      <tr><td colspan='2'><strong>${obj.name}</strong></td></tr>
                      <tr>
                        <td>总数量</td>
                        <td ${pl}>${d.allCount}</td>
                      </tr>
                      <tr>
                        <td>当月新增</td>
                        <td ${pl}>${d.value}</td>
                      </tr>
                    </table>`
                  }
                },
                xAxis: {
                  type: 'category',
                  show: false,
                  data: customerTrendX
                },
                yAxis: {
                  type: 'value'
                },
                onClick(params) {
                  $router.push({ path: `/customer/list?yearMonth=${params.name}` })
                },
                series: customerSeries
              }" />
            </div>
          </div>
        </v-col>
        <v-col sm="4">
          <div class="valueitem">
            <div class="valuehead">
              <v-icon class="valueicon">mdi-file-pdf-box</v-icon>
              <p>报告总数量</p>
              <h1>{{reportCount}}</h1>
            </div>
            <div class="valuechart">
              <Echart :options="{
                grid: {
                  right: '8px',
                  bottom: '8px',
                  top: '16px'
                },
                tooltip: {
                  trigger: 'item',
                  position: 'bottom',
                  formatter: obj => {
                    const pl = 'style=\'padding-left:16px\'';
                    const d = obj.data;
                    const str = [
                      `<tr><td colspan='2'><strong>${obj.name}</strong></td></tr>`,
                      `<tr><td>总数量</td><td ${pl}><strong>${d.allCount}</strong></td></tr>`,
                      `<tr><td>当月新增</td><td ${pl}><strong>${d.value}</strong></td></tr>`
                    ]
                    if (d.typeMap) {
                      str.push(`<tr><td colspan='2'><div style='background:grey;height:1px;margin:8px 0;'></div></td></tr>`)
                      Object.keys(d.typeMap).map(n => {
                        str.push(`<tr><td>${n}</td><td ${pl}><strong>${d.typeMap[n]}</strong></td></tr>`)
                      })
                    }
                    return `<table>${str.join('')}</table>`
                  }
                },
                xAxis: {
                  type: 'category',
                  show: false,
                  data: reportTrendX
                },
                yAxis: {
                  type: 'value'
                },
                series: reportSeries
              }" />
            </div>
          </div>
        </v-col>
        <v-col sm="4">
          <div class="valueitem">
            <div class="valuehead">
              <v-icon class="valueicon">mdi-calendar-text</v-icon>
              <p>管理计划总数量</p>
              <h1>{{planCount}}</h1>
            </div>
            <div class="valuechart">
              <Echart :options="{
                series: [
                  {
                    type: 'treemap',
                    top: 1,
                    left: 1,
                    bottom: 1,
                    right: 2,
                    breadcrumb: {show:false},
                    nodeClick: false,
                    roam: false,
                    label: {
                      formatter: obj => `${obj.data.name}\r\n${obj.data.value}`
                    },
                    data: planStatisticsData
                  }
                ]
              }" />
            </div>
          </div>
        </v-col>
        <v-col sm="4">
          <v-card :style="cardStyle">
            <Echart :options="{
              title: {
                text: '客户健康状态词云'
              },
              onClick(params) {
                $router.push({ path: `/customer/list?tagName=${params.name}` })
              },
              series:[{
                type: 'wordCloud',
                shape: 'circle',
                gridSize: 2,
                sizeRange: [12, 38],
                rotationRange: [0, 0],
                width: '100%',
                height: '100%',
                top: 16,
                drawOutOfBound: true,
                keepAspect: true,
                textStyle: {
                  color: () => `rgb(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowColor: '#333'
                },
                data: couldWordsData
              }]
            }" />
          </v-card>
        </v-col>
        <v-col sm="8">
          <v-card :style="cardStyle">
            <Echart :options="{
              title: {
                text: '客户性别年龄分布'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              legend: {
                data: ['男', '女']
              },
              grid: {
                top: 60, left: 46, bottom: 32, right: 32
              },
              xAxis: {
                type: 'category',
                data: ageStatisticsX,
                name: '岁'
              },
              yAxis: {
                type: 'value',
                name: '人'
              },
              onClick: handleAgeGenderClick,
              series: ageStatisticsSeries
            }" />
          </v-card>
        </v-col>
        <v-col>
          <CommonTools style="margin-top:0" />
        </v-col>
      </v-row>
    </v-col>
    <v-col sm="3">
      <v-card >
        <v-card-title style="padding: 8px 16px 0;font-weight:bold;">
          待办事项
        </v-card-title>
        <v-list style="height:729px;overflow: auto">
          <template v-for="(todo, i) in todoData">
            <v-divider :key="`${i}-${todo.name}`" />
            <v-list-item :key="i">
              <v-list-item-content>
                {{todo.name}}
                <v-list-item-subtitle :style="{color: todo.color}">{{todo.desc}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="() => handleTodo(todo)"><v-icon color="grey lighten-1">mdi-arrow-right-circle</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// 代办事项 超时概念
// 客户性别 年龄分布
// 产品销量 按机构统计 按医生统计 切换
// 管理计划终止率
// 新增客户 新增报告
import Echart from '@/components/echarts'
import { api } from '@/utils/api'
import CommonTools from "@/components/template/CommonTools";
const PLAN_STATE_MAP = {
  R: { text: '未开始', color: 'grey' },
  E: { text: '执行中', color: '#5470C6' },
  C: { text: '完成', color: '#91CC75' },
  O: { text: '超时', color: '#FAC858' },
  T: { text: '终止', color: '#EE6666' }
}
export default {
  name: 'Home',
  components: { Echart, CommonTools },
  data() {
    return {
      log: console.log,
      hide0Data: true, // 是否隐藏无数据节点
      customerCount: '-',
      customerTrendX: [],
      customerSeries: [],
      reportCount: '-',
      reportTrendX: [],
      reportSeries: [{ type: 'line', data: [] }],
      planCount: '-',
      planStatisticsData: [],
      cardStyle: { height: '340px', padding: '8px' },
      todoData: [],
      couldWordsData: [],
      ageStatisticsX: [],
      ageStatisticsSeries: [
        { name: '男', type: 'bar', data: [] },
        { name: '女', type: 'bar', data: [] }
      ]
    }
  },
  mounted() {
    this.initCustomerTrend()
    this.initReportTrend()
    this.initPlanStatistics()
    this.initTodo()
    this.initCouldWords()
    this.initGenderAge()
  },
  methods: {
    async initCustomerTrend () {
      const trend = await api.get(`dashboard_home/get_customer_trend/`)
      let count = 0
      const trendX = []
      const increaseTrend = []
      trend.map(t => {
        if (this.hide0Data && t.count === 0) return
        trendX.push(t.date)
        count += t.count
        increaseTrend.push({ value: t.count, allCount: count })
      })
      this.customerTrendX = trendX
      this.customerCount = count
      this.customerSeries = [{
        type: 'line',
        data: increaseTrend
      }]
    },
    async initReportTrend () {
      const trend = await api.get(`dashboard_home/get_report_trend/`)
      let count = 0
      const trendX = [] // 时间轴
      const increaseTrend = [] // 当月增长
      const reportSeries = [] // 配置项
      trend.map(t => {
        if (this.hide0Data && t.count === 0) return
        trendX.push(t.date)
        count += t.count

        let typeMap = null // 报告类型数据
        if (Object.keys(t.type_count).length > 0) {
          typeMap = {}
          Object.keys(t.type_count).map(reportName => typeMap[reportName] = t.type_count[reportName])
        }
        increaseTrend.push({ name: t.date, value: t.count, typeMap, allCount: count })
      })
      reportSeries.push({
        name: '当月新增',
        type: 'line',
        data: increaseTrend
      })
      this.reportCount = count
      this.reportTrendX = trendX
      this.reportSeries = reportSeries
    },
    async initPlanStatistics () {
      const data = await api.get(`dashboard_home/get_plan__statistics/`)
      const planStatisticsData = []
      let count = 0
      data.map(d => {
        const config = PLAN_STATE_MAP[d.state]
        count += d.count
        planStatisticsData.push({
          name: config.text,
          state: d.state,
          value: d.count,
          itemStyle: { color: config.color }
        })
      })
      this.planCount = count
      this.planStatisticsData = planStatisticsData
    },
    async initTodo() {
      const levelColor = {
        1: '#E91F60',
        2: '#FC9B12',
        3: '#4CB050'
      }
      const todoData = await api.get(`dashboard_home/get_todolist/`)
      todoData.map(todo => {
        todo.color = levelColor[todo.level]
      })
      this.todoData = todoData.sort((a, b) => a.level - b.level)
    },
    async initCouldWords() {
      this.couldWordsData = await api.get(`dashboard_home/get_health_status_cloud_words/`)
    },
    async initGenderAge() {
      let ageData = await api.get(`dashboard_home/get_customer_age_statistics/`)
      ageData = ageData.sort((a, b) => a.age - b.age)
      const ageStatisticsX = []
      const maleValue = []
      const femaleValue = []
      let maleCountAll = 0
      let femaleCountAll = 0
      const formatData = (age_min, age_max) => {
        const age_range_data = ageData.filter(ad => ad.age >= age_min && ad.age <= (age_max || 1000))
        if (age_range_data.length > 0) {
          let maleCount = 0
          let femaleCount = 0
          age_range_data.map(data => {
            if (data.MALE) {
              maleCount += data.MALE
            }
            if (data.FEMALE) {
              femaleCount += data.FEMALE
            }
          })
          if (!age_min) {
            ageStatisticsX.push(`≤${age_max}`)
          } else if (!age_max) {
            ageStatisticsX.push(`≥${age_min}`)
          } else {
            ageStatisticsX.push(`${age_min}-${age_max}`)
          }
          maleValue.push(maleCount)
          femaleValue.push(femaleCount)
          maleCountAll += maleCount
          femaleCountAll += femaleCount
        }
      } 
      formatData(0, 20)
      formatData(21, 34)
      formatData(35, 44)
      formatData(45, 54)
      formatData(55, 64)
      formatData(65, 74)
      formatData(75, 84)
      formatData(85)
      this.ageStatisticsX = ageStatisticsX

      const all = maleCountAll + femaleCountAll
      const malePer = maleCountAll / all

      this.ageStatisticsSeries = [{
        name: '男',
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#5470C6'
        },
        data: maleValue
      }, {
        name: '女',
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#91CC75'
        },
        label: {
          show: true,
          position: 'top',
          formatter: obj => {
            return femaleValue[obj.dataIndex] + maleValue[obj.dataIndex]
          }
        },
        data: femaleValue
      }, {
        type: 'pie',
        center: ['86%', '25%'],
        radius: '40%',
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          position: 'inside',
          formatter: obj => `${obj.data.value}\r\n${obj.data.per}%`
        },
        data: [
          { name: '男', value: maleCountAll, per: (malePer * 100).toFixed(0) },
          { name: '女', value: femaleCountAll, per: ((1 - malePer) * 100).toFixed(0) }
        ]
      }]
    },
    handleTodo(todo) {
      switch(todo.to) {
        case 'plan':
          this.$router.push({ name: '管理计划', params: { id: todo.individual_id }})
          break
        case 'action':
          this.$router.push({ name: '管理计划活动列表', params: { id: todo.individual_id, planId: todo.plan_id }})
          break
      }
    },
    handleAgeGenderClick(params) {
      const gender_map = { '男': 'MALE', '女': 'FEMALE' };
      let gender = null;
      let ageMin = null;
      let ageMax = null;
      const query = [];
      if (params.seriesType === 'pie') {
        gender = gender_map[params.name];
      } else {
        gender = gender_map[params.seriesName];
        if (params.name.indexOf('-') > -1) {
          [ageMin, ageMax] = params.name.split('-');
        } else if (params.name.indexOf('≤') > -1) {
          ageMax = params.name.split('≤')[1];
        } else if (params.name.indexOf('≥') > -1) {
          ageMin = params.name.split('≥')[1];
        }
      }
      gender && query.push(`gender=${gender}`);
      ageMin && query.push(`ageMin=${ageMin}`);
      ageMax && query.push(`ageMax=${ageMax}`);
      this.$router.push({ path: `/customer/list?${query.join('&')}` })
    }
  }
}
</script>
<style lang="scss" scoped>
.infoblock {
  width: 50%;
  text-align: center;
  padding: 16px 0;
  line-height: 32px;
}
.valueitem:last-child {
  margin: 0;
}
.valueitem {
  flex: 1;
  border: 1px solid #673AB7;
  .valuehead {
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    background: #673AB7;
    .valueicon {
      font-size: 48px;
      color: #ffffff;
      margin-top: 6px;
      margin-right: 8px;
    }
    p {
      margin: 16px 0 8px;
      font-size: 18px;
      font-weight: 500;
    }
    h1 {
      flex: 1;
      font-size: 40px;
      font-weight: 800;
      text-align: right;
    }
  }
  .valuechart {
    height: 170px;
  }
}
</style>
