export function isTextField(value: string) {
  let types = ["short text", "integer", "float", "date"];
  for (var index in types) {
    if (value == types[index]) {
      return true;
    }
  }
  return false;
}

export function isLongTextField(value: string) {
  let types = ["long text"];
  for (var index in types) {
    if (value == types[index]) {
      return true;
    }
  }
  return false;
}


export function isCheckBox(value: string) {
  return value == "checkbox";
}
export function isRadioButton(value: string) {
  return value == "radiobutton";
}

export function isGenderMatch(value: string, gender: string) {
  if (value === "COMMON" || value === "ADULT" || value === "CHILD") {
    return true;
  } else {
    return value === gender;
  }
}