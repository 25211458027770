<template>
    <v-list dense>
        <v-list-item to="/">
            <v-list-item-action class="mr-4">
                <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>首页</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <div v-for="menu in menus" :key="menu.order_no">
            <v-list-item :to="menu.path">
                <v-list-item-action class="mr-4">
                    <v-icon>{{menu.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{menu.name}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </div>

<!--        <v-list-item to="/about">-->
<!--            <v-list-item-action class="mr-4">-->
<!--                <v-icon>mdi-information</v-icon>-->
<!--            </v-list-item-action>-->
<!--            <v-list-item-content>-->
<!--                <v-list-item-title>关于</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      <v-list-item to="/echarts">-->
<!--        <v-list-item-action class="mr-4">-->
<!--          <v-icon>mdi-information</v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>血糖地图</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item to="/sunburst">-->
<!--        <v-list-item-action class="mr-4">-->
<!--          <v-icon>mdi-information</v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>心脑糖风险关系图</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item to="/sankey">-->
<!--        <v-list-item-action class="mr-4">-->
<!--          <v-icon>mdi-information</v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>心脑糖风险桑基图</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item to="/dmap">-->
<!--        <v-list-item-action class="mr-4">-->
<!--          <v-icon>mdi-information</v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>动态血糖地图</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item to="/ridaus">-->
<!--        <v-list-item-action class="mr-4">-->
<!--          <v-icon>mdi-information</v-icon>-->
<!--        </v-list-item-action>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>心脑健康地图</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
    </v-list>
</template>

<script>
  import {api} from "@/utils/api";

  export default {
    name: "LeftBar",
    data() {
      return {
        menus: []
      }
    },
    async mounted() {
      this.menus = await api.get('users/get_via_atlas_menu_resources/?', null)
    },
  }
</script>

<style scoped>

</style>