<template>
  <v-card class="record-dashboard" width="100%" style="padding:0 16px 8px;">
    <v-tabs active-class="reportactive" v-model="showType">
      <v-tab>评估报告</v-tab>
      <v-tab>评价报告</v-tab>
    </v-tabs>
    <div class="record-dashboard-action" v-if="has_add_right && showType == 0">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="primary" v-on="on" @click="showAddReport = true">mdi-plus-circle</v-icon>
        </template>
        <span>新增报告</span>
      </v-tooltip>
    </div>
    <div class="record-dashboard-contain">
      <v-data-table
        v-show="showType == 0"
        style="width:100%"
        :headers="[
          { text: '报告名称', value: 'instance_name' },
          { text: '报告编号', value: 'instance_no', align: 'center' },
          { text: '发生日期', value: 'report_date', align: 'center' },
          { text: '状态', value: 'status', align: 'center' },
          { text: '创建人', value: 'created_user', align: 'center' },
          { text: '', value: 'xxx', align: 'center' },
        ]"
        :items="template_items"
        :items-per-page="10"
        :footer-props="{ itemsPerPageText : '每页行数：', itemsPerPageOptions: [5, 10]}"
        disable-sort
      >
        <template v-slot:item.instance_name="{ item }">
          <router-link v-bind:to="{ name: '查看报告', params: { sid: item.sid }}" class="v-breadcrumbs__item" v-if="item.can_view_detail && has_view_right">
            {{ item.instance_name }}
          </router-link>
          <span v-else>{{ item.instance_name }}</span>
        </template>
        <template v-slot:item.report_date="{ item }">
          {{ item.report_date | formatDateDash }}
        </template>
        <template v-slot:item.status="{ item }">
          <template v-if="item.can_view_detail">
            {{ item.internal_status | getDescForOpinion }}
          </template>
          <template v-else>
            {{ item.internal_status | getDescForOpinionExternal }}
          </template>
        </template>
        <template v-slot:item.created_user="{ item }">
          {{ item.created_user ? item.created_user.first_name: item.doctor.doctor_name }}
        </template>
        <template v-slot:item.xxx="{ item }">
          <router-link v-bind:to="{ name: '编辑报告', params: { sid: item.sid } }" class="v-breadcrumbs__item" v-if="item.can_be_edit && has_change_right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon :color="item.is_existed?'#3F51B5':'survey'" v-on="on" class="py-1">
                  mdi-pencil-circle-outline
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
          </router-link>
          <v-tooltip bottom v-if="item.internal_status === 'RP' && has_view_right">
            <template v-slot:activator="{ on }">
              <v-icon color="success" :loading="dialog_view_report" v-on="on" class="py-1" @click="showReport(item)" >
                mdi-file-pdf-box
              </v-icon>
            </template>
            <span>看报告</span>
          </v-tooltip>
          <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <v-progress-circular
                :size="24"
                :width="2"
                :value="playProgress"
                color="#3F51B5"
                style="cursor: pointer"
                v-show="(playSid == item.sid) && playing"
                @click="$store.commit('stopAudio')"
              >
                <v-icon color="#3F51B5" :size="18" v-on="on">
                  mdi-stop
                </v-icon>
              </v-progress-circular>
            </template>
            <span>停止播报</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.audios.length > 0">
            <template v-slot:activator="{ on }">
              <v-icon
                color="#3F51B5"
                :loading="dialog_view_report"
                v-on="on"
                class="py-1"
                @click="playAudio(item)"
                v-show="(playSid != item.sid) || (playSid == item.sid && !playing)"
              >
                mdi-play-circle-outline
              </v-icon>
            </template>
            <span>语音播报</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-simple-table v-show="showType == 1" style="width:100%">
        <thead>
          <tr>
            <th>报告名称</th>
            <th>报告编号</th>
            <th>评价日期</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in type2Data" :key="d.sid">
            <td><a @click="() => handleType2ReportClick(d)">{{d.report_name}}</a></td>
            <td>{{d.report_no}}</td>
            <td>{{d.report_date}}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-dialog v-model="showAddReport" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">新增报告</span>
        </v-card-title>
        <v-card-text>
          <div v-for="report in report_templates" :key="report.sid">
            <router-link v-bind:to="{ name: '新增报告', params: { rid: report.sid }}" class="v-breadcrumbs__item" v-show="report.is_published" style="margin: 0 8px 8px 0">
              {{report.report_name}}
            </router-link>
          </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showAddReport = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import {api} from "@/utils/api";

  export default {
    name: "customers-v2-report-index",
    inject: ['individualId', 'instance', 'handleShowDrawer', 'handleDrawerClose'],
    data() {
      return {
        showType: '评估报告',
        type2Data: [],
        showAddReport: false,
        report_templates: [],
        items: [],
        items_tab: [],
        unfiltered_report_items: [],
        report_items: [],
        org: {},
        customer: {},
        total_item: [],
        dialog_view_report: false
      }
    },
    computed: {
      template_items() {
        let filtered_items = []
        for (let item of this.items_tab) {
          if (item.data_type === 'R') {
            if (item.instance_no && item.instance_no.indexOf('-') > -1) {
              item.instance_no = item.instance_no.split('-')[1].substr(2)
            }
            filtered_items.push(item)
          }
        }
        return filtered_items
      },
      has_add_right() {
        return this.$store.getters.getPermission('reportinstance', 'add')
      },
      has_change_right() {
        return this.$store.getters.getPermission('reportinstance', 'change')
      },
      has_view_right() {
        return this.$store.getters.getPermission('reportinstancedetail', 'view')
      },
      playSid() {
        return this.$store.state.audioSid
      },
      playProgress() {
        return this.$store.state.audioProgress
      },
      playing() {
        return this.$store.state.audioPlaying
      }
    },
    async mounted() {
      this.doLoadData()

      const { msg } = await api.get(`health_manage/get_evaluation_report_list/?i=${this.individualId}`)
      msg.map(d => {
        if (d.report_no && d.report_no.indexOf('-') > 1) {
          d.report_no = d.report_no.split('-')[1].substr(2)
        }
      })
      this.type2Data = msg
    },
    methods: {
      async doLoadData() {
        this.org = this.instance.org
        this.customer = this.instance.customer
        this.items_tab = []
        this.unfiltered_report_items = await api.get(`report_instances/?i=${this.individualId}`)
        this.unfiltered_report_items.map(async item => {
          item.is_visible = true
          item.audios = {}
          item.audios = await api.get(`report_instance_media/?i=${item.sid}`)
        })
        this.report_templates = await api.get(`report_templates/get_report_templates/?c=${this.individualId}`)
        for (let ui of this.unfiltered_report_items) {
          let is_existed = false
          for (let template of this.report_templates) {
            if (template.sid === ui.report_template.sid) {
              ui.data_type="R"
              ui.is_existed = true
              this.items.push(ui)
              is_existed = true
              break
            }
          }
          if (!is_existed && ui.report_template.is_shared) {
            ui.data_type="R"
            ui.is_existed = false
            this.items.push(ui)
          }
        }
        for (let item of this.items) {
          if (item.data_type === 'R') {
            for (let tag of item.report_template.report_tags) {
              let is_added = false
              for(let it of this.customer.individual_tags) {
                if (tag.tag_no.includes(it.tag_no)) {
                  is_added = true
                  break
                }
              }
              if (is_added) {
                this.items_tab.push(item)
                break
              }
            }
          }
        }
      },
      async showReport(item) {
        if (item.report_template.report_type=='W') {
          this.handleDrawerClose()
          await this.$nextTick()
          this.handleShowDrawer(item.instance_name, 'WebReportFrameContain', {
            url: `${location.origin}/#/RPT-MPBH-V1/0/${item.sid}`
          })
          return
        }
        this.dialog_view_report = true;
        let data = await api.get(
          "report_instances/" + item.sid + "/get_pdf_report/?",
          { responseType: "blob" }
        );

        let binaryData = [];
        binaryData.push(data);

        let filename = `${this.customer.individual_name}_${item.instance_name}_${this.customer.individual_no}_${item.report_date.replace(/-/g, "")}`;
        let url = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", filename + ".pdf");
        document.body.appendChild(link);
        link.click();
        this.dialog_view_report = false;
      },
      async playAudio(item) {
        this.$store.commit('stopAudio')

        window.audioCache || (window.audioCache = {})
        let audioUrl = window.audioCache[item.sid]
        if (!audioUrl) {
          const raws = []
          for(let audio of item.audios) {
            const raw = await api.get("report_instance_media/get_audio/?sid=" + audio.sid, { responseType: 'blob' })
            raws.push(raw)
          }
          const audioBlob = new Blob(raws, { type: 'audio/mp3' })
          audioUrl = window.URL.createObjectURL(audioBlob)
          window.audioCache[item.sid] = audioUrl
        }
        this.$store.commit('playAudio', { src: audioUrl, sid: item.sid })
      },
      async handleType2ReportClick(item) {
        this.handleDrawerClose()
        await this.$nextTick()
        switch(item.report_type) {
          case 'E':
            this.handleShowDrawer(item.report_name, 'StageEvaluationReport', { reportSid: item.sid })
            break
          default:
            this.handleShowDrawer(item.report_name, 'CurativeEffectReport', { reportSid: item.sid })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.record-dashboard {
  position: relative;
  .v-tabs-slider {
    background: #3F51B5;
  }
  .reportactive.v-tab.v-tab {
    color: #3F51B5;
  }
  .record-dashboard-contain {
    display: flex;
    align-items: center;
  }
  .record-dashboard-scrollX {
    flex: 1;
    // overflow-x: auto;
    // overflow-y: hidden;
    // white-space: nowrap;
    &::-webkit-scrollbar {
        width: 1px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: grey;
    }
    & > .report-card {
      display: inline-block;
    }
  }
  .record-dashboard-action {
    margin-left: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
</style>