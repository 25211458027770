<template>
    <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
        <v-card>
            <v-card-title style="padding-bottom: 0px">
                <v-subheader>{{ this.report_template.report_name }}</v-subheader>
                <span v-show="false">{{ trigger }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-2 hidden-sm-and-down" :disabled="!valid || dialog_save"
                       :loading="dialog_save" @click.stop="submit">
                    生成报告
                </v-btn>
                <v-btn color="grey" class="hidden-sm-and-down" @click="doBackHistory">
                    <v-icon dark left>remove_circle</v-icon>
                    取消
                </v-btn>
            </v-card-title>
            <v-card-text style="padding-top: 0px; padding-bottom: 0px">
                <v-subheader class="caption font-weight-medium"
                             style="padding-top: 0px; padding-bottom: 0px; height: 30px">{{ customer.individual_no }}-{{
                    customer.individual_name }}-{{ customer.organization.org_name }}
                </v-subheader>
            </v-card-text>
            <v-card-text style="padding-top: 8px">
                <v-layout row wrap>
                    <v-flex d-flex xs12 style="padding: 0px 36px ">
                        <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="report_template.report_date"
                                width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-on="report_template.report_date_readonly ? {}: on"
                                  v-model="report_template.report_date"
                                  label="报告日期"
                                  hint="生成报告需要先选择报告日期"
                                  persistent-hint
                                  readonly
                                  :rules="report_template.report_date_rules"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="report_template.report_date" @input="getAvailableDetails" scrollable
                                           color="primary" :max="current_date">
                            </v-date-picker>
                        </v-dialog>
                    </v-flex>
                  <v-flex d-flex xs12 style="padding: 12px 36px" v-if="report_template.report_type!='W'">
                    <v-checkbox v-model="report_instance.is_cover" label="是否生成封面"></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="report_template.report_type==='S' && show_simple_form">
                    <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                        <v-autocomplete
                                v-model="select"
                                :hint="`送检单位：${select.org_name}`"
                                :items="org_infos"
                                item-text="org_name"
                                item-value="sid"
                                label="送检单位"
                                persistent-hint
                                return-object
                                single-line
                                :rules="report_instance_rules.test_company"
                        ></v-autocomplete>
                    </v-flex>
                    <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                        <v-text-field label='样本编号' v-model="report_instance.sample_no"
                                      :rules="report_instance_rules.sample_no"></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                        <v-text-field label='样本类型' v-model="report_instance.sample_type"
                                      :rules="report_instance_rules.sample_type"></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                        <v-text-field label="采样日期" v-model="report_instance.sample_date"
                                      :rules="report_instance_rules.sample_date" hint="格式YYYY-MM-DD"
                                      required></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                        <v-text-field label="检测日期" v-model="report_instance.test_date"
                                      :rules="report_instance_rules.test_date" hint="格式YYYY-MM-DD"
                                      required></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex d-flex xs12 sm6 md4 class="px-12 pt-6"
                            v-for="template_detail in report_template_details" :key="template_detail.sid">
                        <v-layout row wrap>
                            <v-flex d-flex xs12>
                                <v-subheader class="px-0 py-3" style="height: 32px" v-if='template_detail.is_mandatory'>
                                    {{template_detail.character_template.template_name}}(必选)
                                </v-subheader>
                                <v-subheader class="px-0 py-3"  style="height: 32px" v-else>
                                    {{template_detail.character_template.template_name}}(可选)
                                    <v-btn icon x-small fab color="primary" @click="doClearSelect(template_detail)">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-subheader>
                            </v-flex>
                            <v-radio-group v-model="template_detail.selected_value" row class="px-3 py-3"
                                           :rules="template_detail.rules" @change="trigger=!trigger">
                                <v-flex d-flex xs12 style="padding: 12px 0px; height: 32px"
                                        v-for="instance_detail in template_detail.instance_details"
                                        :key="instance_detail.sid">
                                    <v-radio
                                            :label="instance_detail.instance_name + '(' + instance_detail.happen_date + ')'"
                                            :value="instance_detail.sid" color="primary"></v-radio>
                                </v-flex>
                            </v-radio-group>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-layout row wrap v-if="report_template.report_type==='V'">
                  <v-flex d-flex xs12 style="padding: 0px 36px">
                    <v-file-input
                        v-model="pdfFile"
                        accept=".pdf"
                        label="请选择报告文件（pdf报告）"
                        :rules="report_instance_rules.file" 
                        persistent-hint
                    ></v-file-input>
                  </v-flex>

                </v-layout>
            </v-card-text>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                       @click.stop="submit">
                    生成报告
                </v-btn>
                <v-btn color="grey" @click="doBackHistory">
                    <v-icon dark left>remove_circle</v-icon>
                    取消
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-title>
        </v-card>
    </v-form>
</template>
<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";
  import {getCurrentDateDash} from "@/utils/date";
  import axios from 'axios'

  export default {
    name: "AddReport",
    props: {
      id: {
        type: String
      },
      rid: {
        type: String
      }
    },
    data() {
      return {
        valid: false,
        modal: false,
        show_simple_form: false,
        report_template: {},
        customer: {
          doctor: {},
          organization: {}
        },
        org_info: {
          sid: '',
          org_name: ''
        },
        select: {org_name: '', sid: ''},
        org_infos: [],
        isShow: false,
        trigger: false,
        instance_details: [],
        report_template_details: [],
        dialog_save: false,
        report_instance: {
          sample_no: '',
          sample_type: '',
          test_company: '',
          sample_date: '',
          test_date: '',
          is_cover: false
        },
        report_instance_rules: {
          sample_no: [v => !!v || "不能为空"],
          sample_type: [v => !!v || "不能为空"],
          test_company: [v => !!v || "不能为空"],
          file: [v => !!v || "不能为空"],
          sample_date: [
            v => !!v || '不能为空，格式YYYY-MM-DD',
            v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
          ],
          test_date: [
            v => !!v || '不能为空，格式YYYY-MM-DD',
            v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
          ]
        },
        pdfFile: null
      }
    },
    computed: {
      current_date() {
        return getCurrentDateDash()
      }
    },
    mounted() {
      this.doLoadData()
    },
    methods: {
      async doLoadData() {
        //获得模版列表
        this.report_template = await api.get("report_templates/" + this.rid + "/?", null)
        this.customer = await api.get("individuals/" + this.id + "/?", null)
        this.report_template.individual = this.customer.sid;
        this.report_template.doctor = this.customer.doctor.sid;
        this.report_template.organization = this.customer.organization.sid;
        this.report_template.report_date_rules = [v => !!v || "不能为空"];
        if (this.report_template.report_type === 'W') {
          this.report_template.report_date = this.current_date;
          this.report_template.report_date_readonly = true;
        }
        this.report_template_details = await api.get("report_template_details/?template=" + this.rid, null)
      },
      doBackHistory() {
        // this.$router.replace({
        //   name: "客户信息",
        //   params: {id: this.customer.sid}
        // });
        this.$router.back(-1)
      },
      doClearSelect(value) {
        value.selected_value = ""
        this.trigger = !this.trigger
      },
      async submit() {
        if (this.$refs.form.validate()) {
          this.dialog_save = true
          //开始组装数据
          let instance_combo = {
            instance_name: "",
            template: "",
            individual: "",
            organization: "",
            doctor: null,
            report_date: null,
            sample_no: "",
            sample_type: "",
            test_company: "",
            sample_date: "",
            test_date: "",
            instance_details: []
          };
          instance_combo.instance_name = this.report_template.report_name;
          instance_combo.template = this.report_template.sid;
          instance_combo.individual = this.report_template.individual;
          instance_combo.organization = this.report_template.organization;
          instance_combo.doctor = this.report_template.doctor;
          instance_combo.sample_no = this.report_instance.sample_no;
          instance_combo.sample_type = this.report_instance.sample_type;
          instance_combo.test_company = this.select.sid;
          instance_combo.sample_date = this.report_instance.sample_date;
          instance_combo.test_date = this.report_instance.test_date;
          instance_combo.is_cover = this.report_instance.is_cover;
          instance_combo.report_date = this.report_template.report_date;
          for (let detail of this.report_template_details) {
            if (detail.instance_details.length <= 0) {
              continue
            }
            let instance_detail = {}
            instance_detail.character = detail.selected_value

            instance_combo.instance_details.push(instance_detail)
          }
          if (this.report_template.report_type==='V') {
            const fd = new FormData()
            fd.append('file', this.pdfFile)
            fd.append('template', this.report_template.sid)
            fd.append('individual', this.report_template.individual)
            fd.append('organization', this.report_template.organization)
            await axios.post('api/report_instances/upload_pdf/', fd, {
              headers: {
                Authorization: 'Martin ' + this.$store.getters.token
              }
            })
          }
          await api.post("report_instances/", instance_combo)
          this.dialog_save = false
          nf.showSuccessMsg("数据保存成功！");
          // await this.$router.replace({
          //   name: "客户信息",
          //   params: {id: this.customer.sid}
          // })
          this.$router.back(-1)
        }
      },
      async getAvailableDetails() {
        this.$refs.dialog.save(this.report_template.report_date)
        //获取可用的实例明细
        this.instance_details = await api.get("report_templates/" + this.rid + "/get_available_template_instances/?d=" + this.report_template.report_date + "&i=" + this.customer.sid, null)
        for (let template_detail of this.report_template_details) {
          template_detail.instance_details = []
          if (template_detail.is_mandatory) {
            template_detail.rules = [v => !!v || "不能为空"]
          }

          template_detail.selected_value = ""
          for (let instance_detail of this.instance_details) {
            if (template_detail.character_template.sid === instance_detail.template.sid) {
              if (template_detail.selected_value.length === 0) {
                template_detail.selected_value = instance_detail.sid
              }
              template_detail.instance_details.push(instance_detail)
            }
          }
        }
        this.trigger = !this.trigger
        this.report_instance.sample_date = this.report_template.report_date
        this.report_instance.test_date = this.report_template.report_date
        this.$refs.form.validate()
        this.org_infos = await api.get("organizations/?", null)
        this.show_simple_form = true
      }
    }
  }
</script>
