<template>
  <div class="record-card">
    <v-checkbox
      v-if="template_item.instance_count > 0"
      v-model="is_filter"
      @change="doFilter(template_item, is_filter)"
      style="margin:0;padding:0;"
      hide-details
    />
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <label v-on="on">{{template_item.short_name}}</label>
      </template>
      {{template_item.template_name}}
    </v-tooltip>
    <template v-if="template_item.instance_count > 0">[{{ template_item.instance_count }}]</template>
    <v-spacer></v-spacer>
    <v-tooltip bottom v-if="template_item.is_toC">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-on="on" @click="handleGetCode" style="margin-right:8px;">mdi-qrcode-scan</v-icon>
      </template>
      <span>生成小程序码</span>
    </v-tooltip>
    <router-link v-if="template_item.is_published && has_add_right" v-bind:to="{ name: '新增数据', params: { cid: template_item.sid }}" class="v-breadcrumbs__item">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="primary" v-on="on">mdi-plus-circle</v-icon>
        </template>
        <span>新增数据</span>
      </v-tooltip>
    </router-link>
    <v-dialog v-model="showQRCode" max-width="458">
      <v-card>
        <v-card-title class="title">{{template_item.template_name}} - 小程序码</v-card-title>
        <v-card-text>
          <img v-if="qrcodePath" :src="qrcodePath" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { api } from "@/utils/api";
  export default {
    name: "RecordCard",
    data() {
      return {
        isShow: false,
        is_filter: this.template_item.is_filter,
        showQRCode: false,
        qrcodePath: null
      }
    },
    props: {
      template_item: {
        type: Object,
        required: true,
        default: () => null
      },
      org_info: {
        type: Object,
        required: true,
        default: () => []
      },
      customer_info: {
        type: Object,
        required: true,
        default: () => []
      },
    },
    computed: {
      has_add_right() {
        return this.$store.getters.getPermission('charactertemplateinstance', 'add')
      }
    },
    watch: {
      reset_filter_flag: function () {
        if (this.is_filter) {
          this.is_filter = false
          this.template_item.is_filter = false
        }
      }
    },
    methods: {
      doFilter(value, filter_flag) {
        if (filter_flag) {
          this.$store.dispatch("addTemplateFilters", value);
        } else {
          this.$store.dispatch("deleteTemplateFilters", value);
        }
      },
      async handleGetCode() {
        // this.qrcodePath = `/api/character_templates/${this.template_item.sid}/get_mp_qrcode/`
        let data = await api.get(`character_templates/${this.template_item.sid}/get_mp_qrcode/`, { responseType: "blob" })
        let binaryData = [];
        binaryData.push(data);
        let src = window.URL.createObjectURL(new Blob(binaryData, { type: "image/jpeg" }))
        this.qrcodePath = src
        this.showQRCode = true
      }
    }
  }
</script>
<style lang="scss" scoped>
.record-card {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #AFAFAF;
  padding: 16px 0;
}
</style>