<template>
  <div>
    <v-card-title style="padding-bottom: 0px">
      <v-subheader>{{ title }}</v-subheader>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-card-title>
    <v-card-text style="padding-top: 0px; padding-bottom: 0px">
      <v-subheader class="caption font-weight-medium" style="padding-top: 0px; padding-bottom: 0px; height: 30px">
        {{ customer.individual_no}}-{{ customer.individual_name }}-{{ customer.organization.org_name }}
      </v-subheader>
    </v-card-text>
    <v-card-text style="padding-top: 8px; padding-bottom: 0px">
      <v-layout row wrap>
        <v-flex d-flex xs12 style="padding: 20px 16px 0px 28px">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="instance.happen_date" width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="instance.happen_date"
                label="记录日期"
                readonly
                style="padding: 0px"
                :rules="instance.happen_date_rules"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="instance.happen_date"
              @input="$refs.dialog.save(instance.happen_date)"
              scrollable
              locale="cn"
              color="primary"
            ></v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-card-text>
  </div>
</template>
<script>
export default {
  name: 'questionnaire-title',
  props: {
    title: {
      type: String,
      default: ''
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    instance: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modal: false
    }
  }
}
</script>