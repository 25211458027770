import Vue from 'vue'
import Vuex from 'vuex'
import sessionUser from './modules/sessionUser'
import layout from "./modules/layout"
import params from "./modules/params"
import permission from "./modules/permission";
import filter from "./modules/filter";
import routeParams from "./modules/routeParams";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    audio: null,
    audioSid: '',
    audioPlaying: false,
    audioProgress: 0
  },
  mutations: {
    playAudio(state, { src, sid }) {
      state.audioSid = sid
      state.audioPlaying = true

      state.audio = new Audio()
      state.audio.src = src
      state.audio.autoplay = true
      state.audio.ontimeupdate = () => {
        if (!state.audio) return
        state.audioProgress = state.audio.currentTime / state.audio.duration * 100
      }
      state.audio.onended = () => {
        state.audio = null
        state.audioSid = ''
        state.audioPlaying = false
        state.audioProgress = 0
      }
    },
    stopAudio(state) {
      if (!state.audio) return
      state.audio.pause()
      state.audio = null
      state.audioSid = ''
      state.audioPlaying = false
      state.audioProgress = 0
    }
  },
  modules: {
    sessionUser,
    layout,
    params,
    permission,
    filter,
    routeParams
  },
  plugins: [
    createPersistedState({
      key: "ipl",
      paths: ["sessionUser", "layout", "routeParams"]
    })
  ]
});
