<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col xl="4" lg="4" md="6" sm="8" xs="12">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>FDS Health Assessment System</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  class="white--text"
                  @click.stop="setDark"
                >
                  <v-icon v-html="$vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'"></v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text v-if="isCompatible">
                <v-form>
                  <v-text-field
                    :prepend-icon="isSms ? 'mdi-cellphone' : 'person'"
                    name="login"
                    :label="isSms ? '手机号码' : '用户名'"
                    type="text"
                    v-model="username"
                  />
                  <div v-if="isSms" class="sms">
                    <v-text-field
                      prepend-icon="mdi-message-processing"
                      name="password"
                      label="验证码"
                      v-model="code"
                      @keyup.enter.native="login"
                    >
                    </v-text-field>
                    <v-btn v-if="sending" class="sms-btn" disabled>{{cd}}S</v-btn>
                    <v-btn v-else class="sms-btn" color="primary" @click="sendSms">{{sended ? '重新发送' : '获取验证码'}}</v-btn>
                  </div>
                  <v-text-field
                    v-else
                    prepend-icon="lock"
                    name="password"
                    label="密码"
                    v-model="pass"
                    type="password"
                    @keyup.enter.native="login"
                  />
                  <div class="sms">
                    <v-text-field
                      prepend-icon="mdi-relative-scale"
                      name="imgcode"
                      label="图形验证码"
                      v-model="imgcode"
                      @keyup.enter.native="login"
                    >
                    </v-text-field>
                    <img style="width:110px;margin-left:8px;" :src="`/api/imgcode/?r=${r}`" @click="r = Date.now()"/>
                  </div>
<!--                  <a style="margin-left: 32px;">{{isSms ? '密码': '短信验证码'}}登录</a>-->
                </v-form>
              </v-card-text>
              <v-card-text v-if="!isCompatible" class="px-6 py-12 text-center">
                <span class="caption error--text" >您的浏览器不兼容，请使用Chrome/Safari/Firefox/Edge等兼容浏览器访问</span>
              </v-card-text>
              <v-card-actions v-if="isCompatible">
                <v-spacer></v-spacer>
                <v-btn color="primary white--text" @click="login" v-if="isCompatible">登录</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import nf from "@/utils/notification";
import {api} from "@/utils/api"

const checkPhone = function(phone) {
  const regu = /^1\d{10}$/
  return regu.test(phone)
}

// 首次生成随机数作为后续发动短信和登录的凭证
let lguid = localStorage.getItem('lguid')
if (!lguid) {
  localStorage.setItem('lguid', Math.random() * 10e17)
  lguid = localStorage.getItem('lguid')
}

export default {
  name: "Login",
  data() {
    return {
      username: this.$store.getters.userName,
      pass: "",
      code: '',
      imgcode: '',
      dark: false,
      // isSms: localStorage.getItem('lgtype') !== 'false',
      isSms: false,
      sended: false,
      sending: false,
      cd: 60,
      isCompatible: false,
      r: Date.now()
    };
  },
  computed: {
    ...mapGetters({
      getDark: "dark"
    })
  },
  mounted() {
    let userAgent = navigator.userAgent.toLowerCase()
    this.isCompatible = userAgent.indexOf('edge/') > -1 || userAgent.indexOf('applewebkit/') > -1 || userAgent.indexOf('chrome/') > -1 || userAgent.indexOf('firefox/') > -1
    this.$vuetify.theme.dark = this.$store.getters.dark
  },
  methods: {
    ...mapActions({
      setDark: "dark"
    }),
    handleSwitchLoginType() {
      this.isSms = !this.isSms
      localStorage.setItem('lgtype', this.isSms)
    },
    cooldown(cd) {
      if (cd < 0) {
        this.sending = false
        return
      }
      this.cd = cd
      cd--
      setTimeout(() => this.cooldown(cd), 1000)
    },
    async sendSms() {
      if (!checkPhone(this.username)) {
        nf.showWarnMsg("手机号码格式错误")
        return
      }
      this.sending = true
      this.cd = 60
      let result = await api.post('sms/login_code/', { phone: this.username, lguid, type: 'login' })
      if (result === 'True') {
        nf.showSuccessMsg("验证码已发送到您的手机，请注意查收");
        this.sended = true
        this.cooldown(60)
      } else {
        nf.showWarnMsg(result);
        this.sending = false
      }
    },
    async login() {
      if (!this.isSms && (!this.pass || !this.username)) {
        nf.showWarnMsg("请输入用户名和密码");
        return;
      }
      if (this.isSms && (!this.code || !this.username)) {
        nf.showWarnMsg("请输入手机号码和验证码");
        return;
      }
      if (this.isSms && this.code.length !== 4) {
        nf.showWarnMsg("验证码错误");
        return;
      }
      if (!this.isSms && this.imgcode.length !== 4) {
        nf.showWarnMsg("图形验证码错误");
        return;
      }
      let postData = {
        username: this.username,
        password: this.isSms? this.code: this.pass,
        is_sms: this.isSms,
        imgcode: this.imgcode,
        lguid
      };
      try {
        let result = await api.post('api-token-auth/', postData)
        if (result.error) {
          nf.showWarnMsg(result.error)
          this.r = Date.now()
          return
        }
        if (result) {
          await this.$store.dispatch("setUserInfo", result);
          await this.$router.replace("/");
          // this.$store.dispatch("setUserInfo", result).then(()=>{
          //   this.$router.replace("/");
          // })
        }
      } catch(e) {
        this.r = Date.now()
        // nf.showWarnMsg(e.response.data?.non_field_errors[0] || e.message || "登录失败"); 按照后端返回的提示不是特别好
        nf.showWarnMsg(e.message || "登录失败");
      }
    }
  }
};
</script>

<style scoped>
  .container.fill-height {
    align-items: center;
  }
  .sms {
    display: flex;
    align-items: center;
  }
  .sms-btn {
    margin-left: 16px;
  }
</style>
