import {api} from "@/utils/api";
import nf from "@/utils/notification";

// 获取个人健康状态标签
export const getHealthStatusData = async (individualId) => {
    try {
        const tagMap = {}
        const typeMap = {}
        const data = await api.get(`individuals/${individualId}/get_health_status/?`)
        if (data.some(d => d.tag.tag_name === 'H型高血压')) {
            data.filter(d => d.tag.tag_name === '高血压').map(d => {
                d.tag.tag_name = 'H型高血压'
            })
        }
        if (data.some(d => d.tag.tag_name === '糖尿病')) {
            data.filter(d => d.tag.tag_name === '糖尿病前期').map(d => {
                d.tag.tag_name = '糖尿病'
            })
        }
        data.map(d => {
            if (!tagMap[d.tag.tag_name]) {
                tagMap[d.tag.tag_name] = []
            }
            if (d.character) {
                if (!tagMap[d.tag.tag_name].some(dd => dd.character && d.character && dd.character.sid == d.character.sid)) {
                    tagMap[d.tag.tag_name].push(d)
                }
            } else {
                tagMap[d.tag.tag_name].push(d)
            }
            typeMap[d.tag.tag_name] = !d.character
            delete d.tag
        })
        const resultData = Object.keys(tagMap).map(tagName => {
            return {
                tagName,
                isRisk: typeMap[tagName],
                list: tagMap[tagName]
            }
        })
        return resultData
    } catch(e) {
        console.error(e)
        nf.showErrorMsg("获取客户健康状态失败！")
        return []
    }
}

// 获取个人最新特征指标
export const getNewestCharactersData = async (individualId) => {
    try {
        const newestGroup = []
        const data = await api.get(`individuals/${individualId}/get_newest_instance_value/`)
        data.map(d => {
            let group = newestGroup.find(ng => ng.title == d.category)
            if (!group) {
                group = {
                    title: d.category,
                    data: []
                }
                newestGroup.push(group)
            }
            group.data.push(d)
        })
        return newestGroup
    } catch(e) {
        console.error(e)
        nf.showErrorMsg("获取最新特征指标数据失败！")
        return []
    }
  }
