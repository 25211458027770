<template>
  <v-card>
    <v-toolbar color="primary" dark>创建活动</v-toolbar>
    <v-card-text>
      <v-form ref="form" lazy-validation v-on:submit.prevent="handleSubmitAction">
        <v-text-field
          name="name"
          label="活动名称"
          type="text"
          v-model="action.name"
          :rules="requireRules"
        />
        <v-radio-group name="action_type" label="" row v-model="action.action_type" @change="handleActionTypeChange">
          <v-radio :label="ACTION_TYPE_NAME_MAP['F']" value="F" />
          <v-radio :label="ACTION_TYPE_NAME_MAP['E']" value="E" />
          <v-radio :label="ACTION_TYPE_NAME_MAP['H']" value="H" />
          <v-radio :label="ACTION_TYPE_NAME_MAP['O']" value="O" />
        </v-radio-group>
        <v-layout row wrap class="pa-3">
          <v-flex v-if="!isNew">
            <v-text-field
              style="margin-right: 16px"
              readonly
              label="开始日期"
              :value="action.start_date"
            />
          </v-flex>
          <v-flex v-else>
            <v-menu
              v-model="action.showDate1"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  label="开始日期"
                  v-model="action.start_date"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="action.start_date"
                scrollable
                locale="cn"
                @change="
                  () => {
                    action.showDate1 = false;
                  }
                "
              />
            </v-menu>
          </v-flex>
          <v-flex>
            <v-menu
              v-model="action.showDate2"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  label="结束日期"
                  v-model="action.end_date"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="action.end_date"
                scrollable
                :min="action.start_date"
                locale="cn"
                @change="
                  () => {
                    action.showDate2 = false;
                  }
                "
              />
            </v-menu>
          </v-flex>
        </v-layout>
        <v-radio-group name="time_range" row v-model="action.time_range">
          <v-radio label="上午" value="09:00-11:00" />
          <v-radio label="中午" value="11:00-13:00" />
          <v-radio label="下午" value="13:00-18:00" />
          <v-radio label="晚上" value="18:00-23:00" />
          <v-radio label="全天" value="09:00-23:00" />
        </v-radio-group>
        <v-checkbox
          v-model="action.loop"
          label="周期活动（持续到管理计划结束）"
        ></v-checkbox>
        <v-radio-group
          v-show="action.loop"
          name="cycle_num"
          row
          v-model="action.cycle_num"
        >
          <v-radio label="每周" value="7" />
          <v-radio label="每2周" value="14" />
          <v-radio label="每月" value="30" />
          <v-radio label="每2月" value="60" />
        </v-radio-group>
        <v-text-field
          name="remarks"
          label="备注"
          type="text"
          v-model="action.remarks"
        />
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="handleSubmitAction">保存</v-btn>
      <v-btn color="primary" @click="$emit('on-close')">关闭</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
import nf from "@/utils/notification"
const ACTION_TYPE_NAME_MAP = {
  B: '基线随访',
  F: '阶段随访',
  E: '阶段评价',
  I: '最终评价',
  H: '健康宣教',
  O: '其他活动'
}
export default {
  props: {
    defaultData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ACTION_TYPE_NAME_MAP,
      requireRules: [v => !!v || '不能为空'],
      isNew: !this.defaultData.start_date,
      action: {
        ...this.defaultData, // { plan_id, start_date, end_date }
        name: '阶段随访',
        start_time: '09:00',
        end_time: '24:00',
        action_id: '',
        action_type: 'F',
        time_range: '09:00-23:00',
        remarks: '',
        state: 'R',
        cycle_num: '7'
      }
    }
  },
  methods: {
    handleActionTypeChange() {
      this.action.name = ACTION_TYPE_NAME_MAP[this.action.type]
    },
    async handleSubmitAction() {
      if (!this.$refs.form.validate()) return
      const data = this.action
      if (!data.loop) {
        data.cycle_num = ''
      }
      try {
        await api.post(`health_manage/create_or_update_action/`, data)
        nf.showSuccessMsg(`创建活动成功`)
        this.$emit('on-close')
      } catch(e) {
        nf.showErrorMsg(`创建活动失败`)
        console.error(e)
      }
    }
  }
}
</script>