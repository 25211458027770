import { ActionContext } from "vuex";
import context from "@/main.ts";

export interface State {
  drawer: boolean;
  mini: boolean;
  right: boolean;
  left: boolean;
  clipped: boolean;
  fixed: boolean;
  dark: boolean;
  showReviewHistory: boolean;
}

const state: State = {
  drawer: false,
  mini: false,
  right: false,
  left: false,
  clipped: true,
  fixed: false,
  dark: false,
  showReviewHistory: false,
};

const getters = {
  drawer: (state: State) => state.drawer,
  mini: (state: State) => state.mini,
  right: (state: State) => state.right,
  left: (state: State) => state.left,
  clipped: (state: State) => state.clipped,
  fixed: (state: State) => state.fixed,
  dark: (state: State) => state.dark,
  showReviewHistory: (state: State) => state.showReviewHistory
};

const mutations = {
  SET_DRAWER(state: State) {
    state.drawer = !state.drawer;
  },
  SET_MINI(state: State) {
    state.mini = !state.mini;
  },
  SET_RIGHT(state: State) {
    state.right = !state.right;
  },
  SET_LEFT(state: State) {
    state.left = !state.left;
  },
  SET_CLIPPED(state: State) {
    state.clipped = !state.clipped;
  },
  SET_FIXED(state: State) {
    state.fixed = !state.fixed;
  },
  SET_DARK(state: State) {
    context.$vuetify.theme.dark = !state.dark;
    state.dark = !state.dark;
  },
  review_history(state: State) {
    state.showReviewHistory = !state.showReviewHistory
  }
};

const actions = {
  dark(context: ActionContext<State, any>, result: any) {
    context.commit("SET_DARK", result);
  },
  drawer(context: ActionContext<State, any>, result: any) {
    context.commit("SET_DRAWER", result);
  },
  mini(context: ActionContext<State, any>, result: any) {
    context.commit("SET_MINI", result);
  },
  right(context: ActionContext<State, any>, result: any) {
    context.commit("SET_RIGHT", result);
  },
  left(context: ActionContext<State, any>, result: any) {
    context.commit("SET_LEFT", result);
  },
  clipped(context: ActionContext<State, any>, result: any) {
    context.commit("SET_CLIPPED", result);
  },
  fixed(context: ActionContext<State, any>, result: any) {
    context.commit("SET_FIXED", result);
  },
  review_history(context: ActionContext<State, any>, result: any) {
    context.commit("review_history", result);
  }
};

const layout = {
  state,
  mutations,
  getters,
  actions
};
export default layout;