<template>
    <v-container fluid>
        <v-form ref="formDataTrack" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
            <v-card>
                <v-card-title>
                    <v-flex d-flex xs12>
                        <v-select
                                v-model="select_template"
                                :items="templates"
                                item-text="template_name"
                                item-value="sid"
                                :rules="select_rule"
                                label="模版"
                                persistent-hint
                                return-object
                                single-line
                        ></v-select>
                    </v-flex>
                    <v-flex d-flex xs12>
                        <v-radio-group v-model="duration" row hide-details>
                            <v-radio label="所有相关客户" value="1"></v-radio>
                            <v-radio label="近7天" value="-7"></v-radio>
                            <v-radio label="近30天" value="-29"></v-radio>
                            <v-radio label="近90天" value="-89"></v-radio>
                            <v-radio label="按时间段" value="0"></v-radio>
                        </v-radio-group>
                        <v-dialog v-model="level_dialog" persistent max-width="400px">
                            <v-card>
                                <v-card-title>
                                    <span class="subtitle-1">设置 {{selected_item.individual_name}} 的级别</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-radio-group v-model="level" row hide-details>
                                        <v-radio label="无" value="N"></v-radio>
                                        <v-radio label="VIP" value="VIP"></v-radio>
                                        <v-radio label="SVP" value="SVP"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="doSetLevel">确认修改</v-btn>
                                    <v-btn color="blue darken-1" text @click="level_dialog = false">取消</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-flex>
                    <v-flex d-flex xs12 sm6>
                        <v-text-field label="开始日期"
                                      class="pr-10"
                                      v-model="start_date"
                                      :rules="date_rule"
                                      hint="格式YYYY-MM-DD"
                                      required v-if="duration==='0'"></v-text-field>
                        <v-text-field label="截止日期"
                                      v-model="end_date"
                                      :rules="date_rule"
                                      hint="格式YYYY-MM-DD"
                                      required v-if="duration==='0'"></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12>
                        <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="姓名/级别/管理机构/健康管理师"
                                single-line
                                hide-details
                                @keyup.enter.native="doSearch"
                                clearable
                                @click:clear="doSearchClear"
                        ></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12 class="pt-8">
                        <v-btn color="primary" class="mr-2" :loading="loading" @click.stop="doSearch">
                            查询
                        </v-btn>
                        <v-btn color="primary" class="mr-2" :loading="loading" :disabled="items.length===0"
                               @click.stop="doExport">
                            导出
                        </v-btn>
                    </v-flex>
                </v-card-title>
            </v-card>
        </v-form>
        <v-card class="mt-4">
            <v-card-text>
                <v-data-table
                        :headers="headers"
                        :items="instance_details"
                        :loading="loading"
                        item-key="sid"
                        :footer-props="{
                            itemsPerPageText: '每页行数：',
                            itemsPerPageOptions: pageItems
                          }"
                >
                    <template v-slot:no-data>
                        <p class="text-xs-center grey--text">对不起，暂时无数据</p>
                    </template>

                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.sid">
                            <template v-for="instance in item.template_instances">
                                <td class="hidden-md-and-down" v-if="instance.character.character_no==='individual_level' && item.can_edit_individual">
                                    <v-chip color="indigo" small dark v-if="item.individual_level==='SVP'" @click="doShowSetLevelDialog(item)">svp</v-chip>
                                    <v-chip color="teal" small dark v-else-if="item.individual_level==='VIP'" @click="doShowSetLevelDialog(item)">vip</v-chip>
                                    <v-chip color="grey" small dark v-else @click="doShowSetLevelDialog(item)"></v-chip>
                                </td>
                                <td class="hidden-md-and-down" v-if="instance.character.character_no==='individual_level' && !item.can_edit_individual">
                                    <v-chip color="indigo" small outlined v-if="item.individual_level==='SVP'">svp</v-chip>
                                    <v-chip color="teal" small outlined v-else-if="item.individual_level==='VIP'" >vip</v-chip>
                                    <v-chip color="grey" small outlined v-else></v-chip>
                                </td>
                                <td class="hidden-md-and-down"
                                    v-if="instance.is_adjust && instance.is_display_in_list && can_view_record && instance.character.character_no!=='individual_level'"
                                    :key="instance.sid">
                                    {{ instance.instance_value }}
                                </td>
                                <td class="hidden-md-and-down"
                                    v-if="instance.is_adjust && instance.is_display_in_list && !can_view_record && instance.character.character_no!=='individual_level'"
                                    :key="instance.sid">
                                    <span>-</span>
                                </td>
                                <td v-if="instance.is_display_in_list && !instance.is_adjust && can_view_record && instance.character.character_no!=='individual_level'"
                                    :key="instance.sid">
                                    <router-link
                                            v-bind:to="{ name: '客户信息', params: {id:item.individual_id}}"
                                            class="v-breadcrumbs__item" v-if="instance.is_key">
                                        {{ instance.instance_value }}
                                    </router-link>
                                    <span v-if="!instance.is_key">{{ instance.instance_value }}</span>
                                </td>
                                <td v-if="instance.is_display_in_list && !instance.is_adjust && !can_view_record && instance.character.character_no!=='individual_level'"
                                    :key="instance.sid">
                                    <span>-</span>
                                </td>
                                <td v-else-if="!instance.is_display_in_list && instance.instance_value==='action_to_display' && item.can_be_edit && can_edit_record"
                                    :key="instance.sid">
                                    <router-link
                                            v-bind:to="{ name: '编辑数据', params: {id:item.individual_id, sid:item.sid}}"
                                            class="v-breadcrumbs__item">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="primary" v-on="on" style="padding: 8px 0 0 0">
                                                    mdi-pencil-circle-outline
                                                </v-icon>
                                            </template>
                                            <span>编辑</span></v-tooltip>
                                    </router-link>
                                </td>
                                <td v-else-if="!instance.is_display_in_list && instance.instance_value==='action_to_display' && item.can_be_edit && can_edit_record && instance.character.character_no!=='individual_level'"
                                    :key="instance.sid">
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </template>
<!--                    <template v-slot:item.individual_level="{ item }">-->
<!--                        <v-chip color="teal" dark v-if="item.individual_level === 'VIP'">V</v-chip>-->
<!--                    </template>-->
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";
  import {isCheckBox, isRadioButton} from "@/utils/enumerations";
  import {clone} from "@/utils/strings";
  import {has_perm} from "@/utils/auth";
  import XLSX from "xlsx";
  import {getCurrentDateDash} from "@/utils/date";

  export default {
    name: "DataTracking",
    data() {
      return {
        select_template: {template_name: "", sid: ""},
        templates: [],
        duration: '1',
        loading: false,
        instance_details: [],
        items: [],
        record_fields: [],
        template: {},
        expanded: [],
        pageItems: [10, 50, 100],
        search: '',
        start_date: '',
        end_date: getCurrentDateDash(),
        select_rule: [v => v.sid.length > 0 || '不能为空'],
        date_rule: [
          v => !!v || '不能为空，格式YYYY-MM-DD',
          v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
        ],
        valid: true,
        level_dialog: false,
        selected_item: {
          individual_level: '',
          individual_id: ''
        },
        level: ''
      }
    },
    computed: {
      headers() {
        let headers = [];
        for (let item of this.record_fields) {
          if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
            headers.push(item);
          }
        }
        return headers;
      },
      can_edit_record() {
        return has_perm('编辑数据')
      },
      can_view_record() {
        return has_perm('查看数据')
      }
    },
    mounted() {
      this.doLoadData()
    },
    methods: {
      async doLoadData() {
        this.templates = await api.get('character_templates/get_tracking_template_info/?', null)

      },
      async doSearch() {
        // if (this.select_template.sid === '') {
        //   nf.showErrorMsg('请选择一个模版')
        //   return
        // }
        if (!this.$refs.formDataTrack.validate()) {
          return
        }
        this.loading = true
        this.items = []
        this.record_fields = []
        this.instance_details = []
        //插入编号
        let head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '编号'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        //插入姓名
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '姓名'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        //插入性别
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '性别'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = ["xs", "sm", "md"]
        this.record_fields.push(head_item)
        //插入出生日期
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '出生日期'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = ["xs", "sm", "md"]
        this.record_fields.push(head_item)
        //插入级别
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '级别'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = ["xs", "sm", "md"]
        this.record_fields.push(head_item)
        //管理机构
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '管理机构'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        //插入健康管理师
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '健康管理师'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = ["xs", "sm", "md"]
        this.record_fields.push(head_item)
        //插入建档日期
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '建档日期'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = ["xs", "sm", "md"]
        this.record_fields.push(head_item)
        //插入发生日期
        head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '发生日期'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        //获取模版信息
        this.template = await api.get("character_templates/" + this.select_template.sid + "/?", null)
        let item_count = 0
        for (let item of this.template.character_templates) {
          // if (!item.is_display_in_list) {
          //   continue
          // }
          item_count += 1
          head_item = {}
          head_item.value = item.character.character_no
          head_item.text = item.character.character_name
          head_item.sortable = false
          head_item.is_display_in_list = true //item.is_display_in_list
          if (item_count > 0) {
            head_item.breakpoints = ["xs", "sm", "md"]
          } else {
            head_item.breakpoints = ["xs", "sm", "md"]
          }
          this.record_fields.push(head_item)
        }
        //插入操作列
        head_item = {}
        head_item.value = "extra_column"
        head_item.text = '操作'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        if (this.search === null) {
          this.search = ""
        }
        this.items = await api.get("ipl_data_tracking/?d=" +
          this.duration +
          "&t=" + this.template.sid +
          "&sd=" + this.start_date +
          "&ed=" + this.end_date +
          "&search=" + this.search, null)
        for (let item of this.items) {
          let index = 0
          for (let instance_detail of item.template_instances) {
            index += 1
            instance_detail.is_key = false
            instance_detail.instance_sid = item.sid
            instance_detail.is_adjust = false
            if (isCheckBox(instance_detail.character.character_input_type)) {
              instance_detail.instance_value = instance_detail.character_detail.detail_name + "," + instance_detail.instance_value
            }

            for (let rf of this.record_fields) {
              if (instance_detail.character.character_no === rf.value) {
                instance_detail.is_display_in_list = rf.is_display_in_list
                if (rf.breakpoints.length > 0) {
                  instance_detail.is_adjust = true
                }
              }
            }
          }
        }
        //组装最后的数据集
        for (let item of this.items) {
          let instance_item = clone(item)
          instance_item.template_instances = []
          //插入编号
          let temp_instance_item = {}
          temp_instance_item.instance_value = item.individual_no
          temp_instance_item.is_key = true
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入姓名
          temp_instance_item = {}
          temp_instance_item.instance_value = item.individual_name
          temp_instance_item.is_key = true
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)

          //插入性别
          temp_instance_item = {}
          temp_instance_item.instance_value = item.individual_gender === 'FEMALE' ? '女' : '男'
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = true
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入生日
          temp_instance_item = {}
          temp_instance_item.instance_value = item.individual_birthday
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = true
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入级别
          temp_instance_item = {}
          temp_instance_item.instance_value = item.individual_level
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = true
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = 'individual_level'
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入所属机构
          temp_instance_item = {}
          temp_instance_item.instance_value = item.org_name
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入健康管理师
          temp_instance_item = {}
          temp_instance_item.instance_value = item.doctor_name
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = true
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入建档日期数据
          temp_instance_item = {}
          temp_instance_item.instance_value = item.creat_date
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = true
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = 'creat_date'
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          //插入发生日期数据
          temp_instance_item = {}
          temp_instance_item.instance_value = item.happen_date
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = 'happen_date'
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          for (let head_item of this.record_fields) {
            if (head_item.value === 'extra_column') {
              continue
            }
            let instance_is_existed = false
            for (let instance_detail of item.template_instances) {
              if (instance_detail.character.character_no === head_item.value) {
                instance_is_existed = true
              }
            }
            if (!instance_is_existed) {
              temp_instance_item = {}
              temp_instance_item.instance_value = ''
              temp_instance_item.is_key = false
              temp_instance_item.instance_sid = item.sid
              temp_instance_item.is_adjust = true
              temp_instance_item.character = {}
              temp_instance_item.character.sid = ''
              temp_instance_item.character.character_no = ''
              temp_instance_item.is_display_in_list = true
              instance_item.template_instances.push(temp_instance_item)
            } else {
              for (let instance_detail of item.template_instances) {
                if (instance_detail.character.character_no !== head_item.value) {
                  continue
                }
                let item_is_existed = false
                for (let template_instance of instance_item.template_instances) {
                  if (template_instance.character.sid === instance_detail.character.sid) {
                    if (isCheckBox(instance_detail.character.character_input_type)) {
                      template_instance.instance_value = instance_detail.instance_value.replace(",", "") + "," + template_instance.character_detail.detail_name
                    }
                    if (isRadioButton(instance_detail.character.character_input_type)) {
                      template_instance.instance_value = instance_detail.character_detail.detail_name
                    }
                    item_is_existed = true
                  }
                }
                if (!item_is_existed) {
                  let instance_item_detail = clone(instance_detail)
                  if (isCheckBox(instance_item_detail.character.character_input_type)) {
                    instance_item_detail.instance_value = instance_item_detail.character_detail.detail_name
                  }
                  if (isRadioButton(instance_detail.character.character_input_type)) {
                    instance_item_detail.instance_value = instance_item_detail.character_detail.detail_name
                  }
                  instance_item.template_instances.push(instance_item_detail)
                }
              }
            }
          }
          temp_instance_item = {}
          temp_instance_item.instance_value = 'action_to_display'
          temp_instance_item.is_key = false
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = ''
          temp_instance_item.is_display_in_list = false
          instance_item.template_instances.push(temp_instance_item)
          this.instance_details.push(instance_item)
        }
        this.loading = false
      },
      async doSearchClear() {
        this.search = ''
        await this.doSearch()
      },
      async doExport() {
        await api.post(
          "template_instances/record_data_export_log/?", {
            template: this.select_template,
            duration: this.duration,
            search: this.search
          })
        let export_data = []
        let header = []
        for (let head_item of this.headers) {
          if (head_item.text !== '操作') {
            header.push(head_item.text)
          }
        }
        for (let item of this.instance_details) {
          let export_item = {}
          export_item['编号'] = item.individual_no;
          export_item['姓名'] = item.individual_name;
          export_item['性别'] = item.individual_gender === "MALE" ? "男" : "女";
          export_item['出生日期'] = item.individual_birthday;
          export_item['级别'] = item.individual_level;
          export_item['管理机构'] = item.org_name;
          export_item['健康管理师'] = item.doctor_name;
          export_item['建档日期'] = item.creat_date;
          export_item['发生日期'] = item.happen_date;
          for (let head_item of this.headers) {
            if (head_item.text !== '操作' && head_item.value !== 'extra_column') {
              export_item[head_item.text] = ''
            }
          }
          for (let instance of item.template_instances) {

            if (export_item.hasOwnProperty(instance.character.character_name)) {
              export_item[instance.character.character_name] = instance.instance_value
            }
          }
          export_data.push(export_item)

        }

        const ws = XLSX.utils.json_to_sheet(export_data, {header: header});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "数据");
        /* generate file and send to client */
        let duration = ''
        if (this.duration === '0') {
          duration = '全部数据'
        } else {
          duration = '近' + this.duration.replace('-', '') + '天数据'
        }
        let file_name = this.select_template.template_name + '_' + duration + '_' + getCurrentDateDash() + '.xlsx'
        XLSX.writeFile(wb, file_name);
      },
      doShowSetLevelDialog(item) {
        this.selected_item = item
        this.level = item.individual_level === null? 'N' : item.individual_level
        this.level_dialog = true
      },
      async doSetLevel() {
        await api.post('individuals/' + this.selected_item.individual_id + '/set_level/?',{level: this.level})
        nf.showSuccessMsg('级别设置成功')
        this.level_dialog = false
        await this.doSearch()
      }
    }
  }
</script>

<style scoped>

</style>