<template>
    <v-container fluid class="pa-0">
        <v-navigation-drawer v-model="drawerRight" app right clipped width="160">
            <v-card flat>
                <v-card-text>当前用户: {{ this.$store.getters.realName }}</v-card-text>
            </v-card>
            <v-divider></v-divider>
            <right-bar></right-bar>
        </v-navigation-drawer>

        <v-app-bar app fixed clipped-left clipped-right color="primary" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            <a :href="org.web" target="_blank">
                <v-avatar size="36px">
<!--                    <img :src="require(`${org.code}`)" :alt="org.name"/>-->
                    <img :src="org.code" :alt="org.name"/>
                </v-avatar>
            </a>
            <v-toolbar-title class="pl-3">{{ org.name }}</v-toolbar-title>
            <v-spacer/>
            <v-btn icon class="white--text" @click.stop="setDark">
                <v-icon v-html="$vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'"></v-icon>
            </v-btn>
            <v-btn icon class="white-text" @click.stop="drawerRight = !drawerRight">
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app clipped fixed width="200" :mini-variant="getMini">
            <left-bar></left-bar>
        </v-navigation-drawer>
        <v-main class="px-4 py-5">
            <breadcrumb :list="list" class="pl-3"></breadcrumb>
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive && isRouterAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
        </v-main>
        <v-footer app inset>
            <v-btn
                    icon
                    @click.stop="setMini"
                    class="hidden-xs-only"
            >
                <v-icon v-html="getMini ? 'chevron_right' : 'chevron_left'"></v-icon>
            </v-btn>
            <span class="caption">&nbsp;FDS &copy; 2023</span>
            <v-spacer></v-spacer>
            <span class="caption">Powered by FDS&nbsp;</span>
        </v-footer>
    </v-container>
</template>

<script>
  import {api} from "@/utils/api";
  import {mapGetters, mapActions} from "vuex";
  import Breadcrumb from "@/components/common/Breadcrumb";
  import RightBar from "@/components/common/RightBar";
  import LeftBar from "@/components/common/LeftBar";

  export default {
    props: {
      source: String
    },
    components: {
      Breadcrumb,
      RightBar,
      LeftBar
    },
    provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
      return {
        reload: this.reload,
        setDrawer: (show) => {
          this.drawer = show
        }
      }
    },
    data: () => ({
      drawer: true,
      drawerRight: false,
      right: false,
      left: false,
      dialog: false,
      valid: true,
      isShow: false,
      menus: [],
      isRouterAlive: true,
      org: {name: 'FDS', code: 'XX', web: ''}
    }),
    computed: {
      list() {
        return this.$route.matched.slice(1, this.$route.matched.length);
      },
      ...mapGetters({
        getDark: "dark",
        getDrawer: "drawer",
        getMini: "mini",
        getRight: "right",
        getLeft: "left",
        getClipped: "clipped",
        getFixed: "fixed"
      })
    },
    async mounted() {
      this.$vuetify.theme.dark = this.$store.getters.dark;
      const { result: org } = await api.get(`users/get_org_info/?`)
      this.org = org;
      this.org.code = "/static/images/logos/" + this.org.code + ".png";
    },
    methods: {
      ...mapActions({
        setDark: "dark",
        setDrawer: "drawer",
        setMini: "mini",
        setRight: "right",
        setLeft: "left",
        setClipped: "clipped",
        setFixed: "fixed"
      }),
      reload () {
        this.isRouterAlive = false;            //先关闭，
        this.$nextTick(function () {
          this.isRouterAlive = true;         //再打开
        })
      }
    }
  };
</script>