<template>
    <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
            <v-subheader>{{ instance.instance_name }}-{{ instance.instance_no | getLastEight }}</v-subheader>
            <v-spacer></v-spacer>
            <!--<v-tooltip bottom v-if="this.$store.getters.showReviewHistory && instance.internal_status != 'INIT'">
                <template v-slot:activator="{ on }">
                    <v-icon color="primary" left v-on="on" @click="doSetReviewHistory">mdi-timetable</v-icon>
                </template>
                <span>隐藏审核历史</span></v-tooltip>
            <v-tooltip bottom v-else-if="instance.internal_status != 'INIT'">
                <template v-slot:activator="{ on }">
                    <v-icon color="grey darken-1" left v-on="on" @click="doSetReviewHistory">mdi-timetable</v-icon>
                </template>
                <span>显示审核历史</span></v-tooltip>-->
        </v-card-title>
        <v-card-text style="padding-top: 0px; padding-bottom: 0px">
            <v-subheader class="caption font-weight-medium" style="padding-top: 0px; padding-bottom: 0px; height: 30px">
                {{ customer.individual_no}}-{{ customer.individual_name }}-{{ customer.organization.org_name }}
            </v-subheader>
        </v-card-text>
        <v-card-text style="padding-top: 8px; padding-bottom: 0px">
            <v-layout row wrap>
                <v-flex d-flex xs12 style="padding: 8px 12px 0px 20px ">
                    <v-text-field label='发生日期' v-model="instance.happen_date" readonly></v-text-field>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-text style="padding-top: 8px; padding-bottom: 0px" v-for="group in template_config.group_templates"
                     :key="group.sid">
            <v-subheader v-if="group.is_default_visible && genderMatch(group.group_crowd)" class="font-weight-bold">
                {{group.group_name}}
            </v-subheader>
            <v-layout row wrap v-if="genderMatch(group.group_crowd)">
                <template v-for="character in group.characters">
                    <v-flex d-flex xs12 md4 style="padding: 8px 12px 0px 40px" :key="character.sid"
                            v-if="textField(character.character_input_type) && genderMatch(character.character_crowd) && character.is_should_visible">
                        <v-text-field :label="character.character_name" v-model="character.instance_value"
                                      :suffix="character.character_unit" v-show="character.is_should_visible" readonly
                                      persistent-hint :hint="character.character_comments"></v-text-field>
                    </v-flex>
                    <v-flex d-flex xs12 style="padding: 0px 12px 0px 48px" :key="character.sid"
                            v-if="longTextField(character.character_input_type) && genderMatch(character.character_crowd)">
                        <v-textarea outline :label="character.character_name" v-model="character.instance_value"
                                    :suffix="character.character_unit" v-show="character.is_should_visible"
                                    :value="character.instance_value" readonly persistent-hint
                                    :hint="character.character_comments"></v-textarea>
                    </v-flex>
                    <v-flex d-flex xs12 style="padding: 0px 12px 0px 48px" :key="character.sid"
                            v-if="checkbox(character.character_input_type) && genderMatch(character.character_crowd)">
                        <v-layout row wrap v-show="character.is_should_visible">
                            <v-flex d-flex xs12>
                                <v-subheader style="padding: 24px 0px 0px 0px" class="text-body-2">
                                    {{character.character_name}}
                                </v-subheader>
                            </v-flex>
                            <v-flex d-flex xs12 sm6 md3 style="padding: 0px 12px 24px 4px; "
                                    v-for="character_detail in character.character_details" :key="character_detail.sid">
                                <v-layout row wrap v-if="genderMatch(character_detail.character_crowd)">
                                    <v-flex d-flex xs12 class="pl-3"  v-if="character_detail.is_other">
                                        <v-text-field :label='character_detail.detail_name'
                                                      v-model="character_detail.instance_value" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex d-flex xs12 class="pl-2" v-else>
                                        <v-checkbox :label="character_detail.detail_name | getShortName"
                                                    v-model="character_detail.instance_value" true-value="true"
                                                    false-value="false" color="primary" readonly></v-checkbox>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex d-flex xs12 style="padding: 0px 12px 0px 48px" :key="character.sid"
                            v-if="radiobutton(character.character_input_type) && genderMatch(character.character_crowd)">
                        <v-layout row wrap v-show="character.is_should_visible">
                            <v-flex d-flex xs12>
                                <v-subheader style="height: 32px; padding: 24px 0px 0px 0px" class="text-body-2">
                                    {{character.character_name}}{{character.character_unit}}
                                </v-subheader>
                            </v-flex>
                            <v-radio-group class="px-auto" v-model="character.instance_value" row
                                           v-if="character.is_should_visible">
                                <v-flex d-flex v-for="character_detail in character.character_details"
                                        :key="character_detail.sid">
                                    <v-radio class="mr-12" :label="character_detail.detail_name | getShortName"
                                             :value="character_detail.detail_value" color="primary" readonly></v-radio>
                                </v-flex>
                            </v-radio-group>
                        </v-layout>
                    </v-flex>
                </template>
            </v-layout>
        </v-card-text>
        <v-card-text>

        </v-card-text>
    </v-card>
</template>
<script>
  import {api} from "@/utils/api";
  import {isTextField, isCheckBox, isRadioButton, isGenderMatch, isLongTextField} from "@/utils/enumerations"
  import nf from "@/utils/notification";

  export default {
    name: "ViewRecordOnePage",
    props: {
      id: {
        type: String
      },
      sid: {
        type: String
      }
    },
    data() {
      return {
        instance: {},
        template_config: {},
        customer: {
          doctor: {},
          organization: {}
        }
      };
    },
    computed: {
      genderMatch() {
        return function (value) {
          return isGenderMatch(value, this.customer.individual_gender)
        }
      },
      textField() {
        return function (value) {
          return isTextField(value)
        }
      },
      longTextField() {
        return function (value) {
          return isLongTextField(value)
        }
      },
      checkbox() {
        return function (value) {
          return isCheckBox(value)
        }
      },
      radiobutton() {
        return function (value) {
          return isRadioButton(value)
        }
      },
      instanceSid() {
        return this.sid;
      }
    },
    watch: {
      instanceSid: {
        handler() {
          this.doLoadData()
        }
      }
    },
    mounted() {
      this.doLoadData()
    },
    methods: {
      async doLoadData() {
        this.customer = await api.get("individuals/" + this.id + "/?", null)

        this.instance = await api.get("template_instances/" + this.sid + "/?", null)
        this.template_config = await api.get("character_templates/" + this.instance.template.sid + "/?", null)
        for (let template of this.template_config.character_templates) {
          template.character.instance_value = ""
          template.character.is_default_visible = template.is_default_visible;
          template.character.is_should_visible = template.is_default_visible;
          for (let instance_detail of this.instance.template_instances) {
            if (instance_detail.character === template.character.sid) {
              template.character.is_should_visible = true
              if (isTextField(template.character.character_input_type)) {
                // template.character.is_should_visible = template.is_default_visible;
                template.character.instance_value = instance_detail.instance_value;
              }
              if (isLongTextField(template.character.character_input_type)) {
                template.character.instance_value = instance_detail.instance_value;
              }
              if (isCheckBox(template.character.character_input_type)) {

                for (let character_detail of template.character.character_details) {
                  if (character_detail.sid === instance_detail.character_detail) {
                    if (character_detail.is_other) {
                      character_detail.instance_value = instance_detail.instance_value + "";
                    } else {
                      character_detail.instance_value = 'true';
                    }
                  }
                }
              }
              if (isRadioButton(template.character.character_input_type)) {
                for (let character_detail of template.character.character_details) {
                  character_detail.instance_value = ""
                  character_detail.detail_value = character_detail.detail_value + ""
                  if (character_detail.sid === instance_detail.character_detail) {
                    template.character.instance_value = instance_detail.detail_value
                  }
                }
              }
            }
          }
        }
        for (let group of this.template_config.group_templates) {
          group.characters = [];
          for (let template of this.template_config.character_templates) {
            if (group.sid === template.template_group) {
              group.characters.push(template.character);
            }
          }
        }

      },
      doSetReviewHistory() {
        this.$store.dispatch("review_history")
      },
      doBack() {
        this.$router.back(-1)
      }
    }
  }
</script>