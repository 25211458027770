<template>
  <v-layout row class="health-evaluate">
    <v-flex xs8 style="padding-right:8px;">
      <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
          <v-subheader style="padding:0">健康评价</v-subheader>
        </v-card-title>
        <v-form style="width: 100%" class="pt-2 pb-4 pl-5 pr-6" ref="form" lazy-validation>
          <v-menu v-model="showDatePicker" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field clearable readonly label="评价日期" v-model="evaluateDate" v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="evaluateDate" scrollable locale="cn" @change="() => { showDatePicker = false }" />
          </v-menu>
          <p style="font-size: 14px;margin:0;">
            本次健康评价将基于最近30天内的健康调查问卷以及最新的检测数据进行。以下是获得的符合条件的健康调查问卷。
          </p>
          <p style="font-size: 14px;">
            点击分析结果按钮查看分析结果。
          </p>
          <v-radio-group v-model="baseQuest">
            <v-radio
              v-for="quest in questList"
              :key="quest.base_follow_action_instance"
              :value="quest.base_follow_action_instance"
              :label="quest.template_name"
            />
          </v-radio-group>
          <v-btn color="primary" @click="handleGetAnalysisResult" :loading="analysisLoading">
            分析结果
          </v-btn>
          <div v-if="analysisResult">
            <p style="text-align:center;margin:16px 0;font-weight:500;font-size:18px;">基线评价结果</p>
            <table border="0" cellspacing="0" cellpadding="0" class="evaltable">
              <tr>
                <td width="200">个人健康指数（PHI）：</td>
                <td width="60">{{analysisResult.person_score.score}}</td>
                <td>{{analysisResult.person_score.desc}}</td>
              </tr>
              <tr>
                <td colspan="3">其中：</td>
              </tr>
              <tr v-for="score in analysisResult.scores" :key="score.check_item_name">
                <td>{{score.check_item_name}}：</td>
                <td>{{score.score}}</td>
                <td>{{score.desc}}</td>
              </tr>
            </table>
            <p>主要健康问题</p>
            <v-checkbox
              v-for="prob in analysisResult.health_problems"
              :key="prob.health_problem_no"
              :label="prob.health_problem_name"
              :value="prob"
              color="primary"
              class="main-quest"
              v-model="oriAnalysisResult.health_problems"
            />
            <template v-if="oriAnalysisResult.people_health_comparison">
              <p>个人健康指数前后比对</p>
              <table border="0" cellspacing="0" cellpadding="0" class="tb">
                <tr>
                  <th>项目</th>
                  <th>管理前</th>
                  <th>管理后</th>
                  <th>趋势</th>
                </tr>
                <tr v-for="(item, i) in oriAnalysisResult.people_health_comparison" :key="i">
                  <td>{{item.items}}</td>
                  <td>{{item.before_manage}}</td>
                  <td>{{item.after_manage}}</td>
                  <td :style="{ color: txtColorMap[item.trend] }">{{item.trend}}</td>
                </tr>
              </table>
            </template>
            <template v-if="oriAnalysisResult.health_problem_comparison">
              <p>主要健康问题前后对比</p>
              <table border="0" cellspacing="0" cellpadding="0" class="tb">
                <tr>
                  <th>项目</th>
                  <th>改善情况</th>
                </tr>
                <tr v-for="(item, i) in oriAnalysisResult.health_problem_comparison" :key="i">
                  <td>{{item.items}}</td>
                  <td :style="{ color: txtColorMap[item.improve_situation] }">{{item.improve_situation}}</td>
                </tr>
              </table>
            </template>
            <template v-if="oriAnalysisResult.physiological_index_comparison">
              <p>生理指标前后比对</p>
              <table border="0" cellspacing="0" cellpadding="0" class="tb">
                <tr>
                  <th>项目</th>
                  <th>管理前</th>
                  <th>管理后</th>
                  <th>改善情况</th>
                </tr>
                <tr v-for="(item, i) in oriAnalysisResult.physiological_index_comparison" :key="i">
                  <td>{{item.items}}</td>
                  <td>{{item.before_manage}}</td>
                  <td>{{item.after_manage}}</td>
                  <td :style="{ color: txtColorMap[item.improve_situation] }">{{item.improve_situation}}</td>
                </tr>
              </table>
            </template>
            <template v-if="oriAnalysisResult.people_health_comparison">
              <p>总结</p>
              <div style="position:relative" @click="handleTextareaFocus0" v-click-outside="handleTextareaBlur">
                <v-textarea
                  outline
                  auto-grow
                  max-length="1000"
                  v-model="oriAnalysisResult.conclusion"
                ></v-textarea>
                <v-card
                  v-show="showLongTextTemplate0"
                  class="longtext-template"
                  tile
                >
                  <v-list-item v-for="item in templateList0" :key="item.sid">
                    <v-list-item-content style="cursor:pointer" @click="(e) => oriAnalysisResult.conclusion=item.longtext">
                      {{item.longtext}}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="() => handleDeleteLongText0(item.sid)">
                        <v-icon color="red">mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
                <v-checkbox v-model="save_template0" class="save_template" label="保存为模板" />
              </div>
            </template>
            <p>下一步建议</p>
            <div style="position:relative" @click="handleTextareaFocus1" v-click-outside="handleTextareaBlur">
              <v-textarea
                outline
                auto-grow
                max-length="1000"
                v-model="oriAnalysisResult.next_proposal"
              ></v-textarea>
              <v-card
                v-show="showLongTextTemplate1"
                class="longtext-template"
                tile
              >
                <v-list-item v-for="item in templateList1" :key="item.sid">
                  <v-list-item-content style="cursor:pointer" @click="(e) => oriAnalysisResult.next_proposal = item.longtext">
                    {{item.longtext}}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="() => handleDeleteLongText1(item.sid)">
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
              <v-checkbox v-model="save_template1" class="save_template" label="保存为模板" />
            </div>
            <p style="text-align:center;padding-bottom:16px;">
              <v-btn color="primary" :loading="analysisLoading" @click="handlePreviewReport">
                生成评价报告
              </v-btn>
              &nbsp;
              <v-btn color="primary" v-if="reportId" :loading="analysisLoading" @click="showDialogPublish = true">
                发布
              </v-btn>
            </p>
          </div>
        </v-form>
      </v-card>
      <v-dialog v-model="showDialogPublish" persistent max-width="320">
        <v-card>
          <v-card-title class="text-subtitle-2">您确认要发布吗?</v-card-title>
          <v-card-text>发布后将无法修改，请您谨慎操作！</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click.native="showDialogPublish = false">取消</v-btn>
            <v-btn color="error" text @click.native="handlePublish">我想好了</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-flex xs4 style="padding-left:16px;">
      <EvaluateReportComp style="margin-bottom:24px;" />
      <MemorandumComp style="margin-bottom:24px;" />
      <ConsultationEvaluationComp style="margin-bottom:24px;" />
      <NutrientIntakeComp />
    </v-flex>
  </v-layout>
</template>
<script>
import EvaluateReportComp from './side-card/EvaluateReport.vue'
import MemorandumComp from './side-card/Memorandum.vue'
import ConsultationEvaluationComp from './side-card/ConsultationEvaluation.vue'
import NutrientIntakeComp from './side-card/NutrientIntake.vue'
import { api } from '@/utils/api'
import nf from "@/utils/notification"
import { formatDate } from "@/utils/date"
export default {
  name: 'ManagePlanAction',
  components: { EvaluateReportComp, MemorandumComp, ConsultationEvaluationComp, NutrientIntakeComp },
  inject: ['individualId', 'instance', 'handleShowDrawer', 'handleDrawerClose'],
  data() {
    const { planId, actionId } = this.$route.params
    return {
      planId,
      actionId,
      customer: {},
      showDatePicker: false,
      evaluateDate: formatDate(new Date()),
      baseQuest: 'c985b083-1c43-4bfc-8439-7ffb8e8755fc',
      questList: [{
        "template_name": "基线评价问卷", 
        "instance_date": "2022-03-29", 
        "base_follow_action_instance":"c985b083-1c43-4bfc-8439-7ffb8e8755fc",
      }],
      reportId: '',
      analysisLoading: false,
      oriAnalysisResult: null,
      analysisResult: null,
      mainQuest: [],
      next_proposal: '',
      showDialogPublish: false,
      txtColorMap: {
        '提高': '#8BC34A',
        '改善': '#8BC34A',

        '降低': '#FF9800',
        '未改善': '#FF9800',

        '部分改善': '#009688',
        '持平': '#009688',

        '新出现': '#E61212'
      },
      save_template0: false,
      showLongTextTemplate0: false,
      templateList0: [],
      loaded0: false,
      save_template1: false,
      showLongTextTemplate1: false,
      templateList1: [],
      loaded1: false
    }
  },
  async mounted() {
    this.analysisLoading = true
    this.customer = this.instance.customer

    const { msg } = await api.get(`health_manage/get_base_question_instance?p=${this.planId}`)
    this.questList = msg
    this.baseQuest = msg[0].base_follow_action_instance

    const rp = await api.get(`health_manage/get_action_evaluation_report_relation?a=${this.actionId}`)
    this.reportId = rp.msg
    if (this.reportId) {
      const report = await api.get(`health_manage/get_evaluation_report_detail?e=${this.reportId}`)
      this.oriAnalysisResult = JSON.parse(JSON.stringify(report.msg))
      this.baseQuest = report.msg.character_instance
      const { msg } = await api.get(`/health_manage/get_health_evaluation_result/?i=${this.individualId}&b=${this.baseQuest}`)
      msg.person_score = msg.scores.splice(0, 1)[0]
      this.analysisResult = msg
    }
    this.analysisLoading = false
  },
  methods: {
    async handleTextareaFocus0() {
      if (this.loaded0) {
        if (this.templateList0.length > 0) {
          this.showLongTextTemplate0 = true
        }
      } else {
        this.loaded0 = true
        const templateList = await api.get(`characters/get_text_template/?cid=f8e6c44f-abfc-4d78-a40a-2afbc30f1db2`)
        if (templateList.length > 0) {
          this.templateList0 = templateList
          this.showLongTextTemplate0 = true
        }
      }
    },
    async handleTextareaFocus1() {
      if (this.loaded1) {
        if (this.templateList1.length > 0) {
          this.showLongTextTemplate1 = true
        }
      } else {
        this.loaded1 = true
        const templateList = await api.get(`characters/get_text_template/?cid=f8e6c44f-abfc-4d78-a40a-2afbc30f1db2`)
        if (templateList.length > 0) {
          this.templateList1 = templateList
          this.showLongTextTemplate1 = true
        }
      }
    },
    handleTextareaBlur() {
      this.showLongTextTemplate0 = false
      this.showLongTextTemplate1 = false
    },
    async handleDeleteLongText0(sid) {
      try {
        await api.post(`characters/delete_text_template/`, { id: sid })
        const index = this.templateList0.findIndex(t => t.sid == sid)
        this.templateList0.splice(index, 1)
      } catch(e) {
        console.error(e)
      }
    },
    async handleDeleteLongText1(sid) {
      try {
        await api.post(`characters/delete_text_template/`, { id: sid })
        const index = this.templateList1.findIndex(t => t.sid == sid)
        this.templateList1.splice(index, 1)
      } catch(e) {
        console.error(e)
      }
    },
    async handleGetAnalysisResult() {
      this.analysisLoading = true
      const { msg } = await api.get(`/health_manage/get_health_evaluation_result/?i=${this.individualId}&b=${this.baseQuest}`)
      this.oriAnalysisResult = JSON.parse(JSON.stringify(msg))
      msg.person_score = msg.scores.splice(0, 1)[0]
      this.analysisResult = msg
      this.analysisLoading = false
    },
    async handlePreviewReport() {
      if (this.oriAnalysisResult.health_problems.length == 0) {
        nf.showWarnMsg(`请选择主要健康问题`)
        return 
      }
      if (this.oriAnalysisResult.people_health_comparison && !this.oriAnalysisResult.conclusion) {
        nf.showWarnMsg(`请输入总结`)
        return 
      }
      if (!this.oriAnalysisResult.next_proposal) {
        nf.showWarnMsg(`请输入下一步建议`)
        return 
      }
      if (this.save_template0) {
        api.post(`characters/add_text_template/`, {
          cid: 'f8e6c44f-abfc-4d78-a40a-2afbc30f1db2',
          text: this.oriAnalysisResult.conclusion
        })
      }
      if (this.save_template1) {
        api.post(`characters/add_text_template/`, {
          cid: 'f8e6c44f-abfc-4d78-a40a-2afbc30f1db2',
          text: this.oriAnalysisResult.next_proposal
        })
      }
      this.analysisLoading = true
      this.handleDrawerClose()
      const postData = {
        report_info: this.oriAnalysisResult,
        individual_id: this.individualId,
        action_id: this.actionId
      }
      if (this.reportId) {
        postData.report_id = this.reportId
      }
      try {
        const { msg } = await api.post(`health_manage/generate_base_follow_evaluation_report/?`, postData)
        this.reportId = msg
        this.handleShowDrawer('健康评价报告', 'CurativeEffectReport', { reportSid: this.reportId })
      } catch(e) {
        nf.showErrorMsg(`生成健康评价报告失败`)
        console.error(e)
      }
      this.analysisLoading = false
    },
    async handlePublish() {
      this.analysisLoading = true
      this.handleDrawerClose()
      try {
        await api.get(`/health_manage/release_evaluation_report/?e=${this.reportId}`)
        nf.showSuccessMsg(`发布成功`)
        this.$router.back(-1)
      } catch(e) {
        nf.showErrorMsg(`发布失败`)
        console.error(e)
      }
      this.analysisLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.health-evaluate {
  margin: 0;
  .evaltable {
    font-size: 14px;
    margin-bottom: 32px;
    tr {
      line-height: 32px;
    }
  }
  .main-quest {
    display:inline-block;
    margin-right: 32px;
    margin-top: 0;
  }
  .longtext-template {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    top: 87%;
    left: 0;
    z-index: 1;
  }
  .save_template {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .tb {
    margin: 16px 0;
    border-bottom: 1px solid #B0B0B0;
    width: 100%;
    th, td {
      font-size: 14px;
    }
    th {
      border-bottom: 2px solid #B0B0B0;
      padding: 8px 0 8px 16px;
      text-align: left;
    }
    td {
      border-bottom: 1px dashed #B0B0B0;
      padding: 8px 0 8px 16px;
    }
    tr:last-child td {
      border: 0;
    }
  }
}
</style>