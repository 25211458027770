<template>
  <v-layout row wrap style="margin:0">
    <v-flex d-flex xs12 sm12 md9 v-if="instance.internal_status !== 'INIT' && this.$store.getters.showReviewHistory">
      <info-report :id="id" :sid="sid"></info-report>
    </v-flex>
    <v-flex d-flex xs12 sm12 md12 v-else>
      <info-report :id="id" :sid="sid"></info-report>
    </v-flex>
    <v-flex d-flex xs12 sm12 md3 v-if="instance.internal_status !== 'INIT' && this.$store.getters.showReviewHistory">
      <info-review-report></info-review-report>
    </v-flex>
  </v-layout>
</template>
<script>
import InfoReport from "@/components/record/ViewReport";
import InfoReviewReport from "@/components/record/ListReviewReport";
import { api } from "@/utils/api";

export default {
  name: "ViewReport",
  props: {
    id: {
      type: String
    },
    sid: {
      type: String
    }
  },
  data() {
    return {
      instance: {}
    };
  },
  components: {
    InfoReport,
    InfoReviewReport
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      this.instance = await api.get("report_instances/" + this.sid + "/?", null)
    }
  }
};
</script>
