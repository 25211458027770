<template>
    <v-container fluid>
        <v-card>
            <v-card-text class="pt-0">
                <v-flex d-flex xs12>
                    <v-radio-group v-model="query_type" row hide-details>
                        <v-radio label="按工作人员" value="1"></v-radio>
                        <v-radio label="按客户编号" value="2"></v-radio>
                        <v-radio label="按工作类型" value="3"></v-radio>
                    </v-radio-group>
                </v-flex>
            </v-card-text>
        </v-card>
        <v-card class="mt-4">
            <v-form ref="formWorkingTrack" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
                <v-card-actions>
                    <span class="subtitle-2 primary--text pl-2">查询条件</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show = !show">
                        <v-icon color="primary">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-slide-y-transition>
                    <v-card-text v-show="show">
                        <v-flex d-flex xs12 v-if="query_type==='1'">
                            <span class="subtitle-2">工作人员</span>
                        </v-flex>
                        <v-flex d-flex xs12 v-if="query_type==='1'">
                            <v-radio-group v-model="select_doctor" row :rules="select_rule"
                                           v-if="doctors.length > 1">
                                <v-radio v-for="doctor in doctors" :key="doctor.sid" :value="doctor.sid"
                                         :label="doctor.doctor_name" color="primary"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex d-flex xs12 v-if="query_type==='2'">
                            <v-text-field
                                    v-model="individual_no"
                                    append-icon="search"
                                    label="客户编号"
                                    :rules="input_rule"
                                    single-line
                                    clearable
                                    required
                            ></v-text-field>
                        </v-flex>
                        <v-flex d-flex xs12 v-if="query_type==='3'">
                            <span class="subtitle-2">工作类型</span>
                        </v-flex>
                        <v-flex d-flex xs12 v-if="query_type==='3'">
                            <v-radio-group v-model="action_type" row :rules="select_rule">
                                <v-radio label="创建数据" value="创建数据"></v-radio>
                                <v-radio label="生成报告" value="生成报告"></v-radio>
                                <v-radio label="客户建档" value="客户建档"></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex d-flex xs12 v-if="query_type==='1' || query_type==='3'">
                            <span class="subtitle-2">时间范围</span>
                        </v-flex>
                        <v-flex d-flex xs12 v-if="query_type==='1' || query_type==='3'">
                            <v-radio-group v-model="duration" row hide-details>
                                <v-radio label="近7天" value="-6"></v-radio>
                                <v-radio label="近30天" value="-29"></v-radio>
                                <v-radio label="近90天" value="-89"></v-radio>
                                <v-radio label="按时间段" value="0"></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex d-flex xs12 md6>
                            <v-text-field label="开始日期"
                                          class="pr-10"
                                          v-model="start_date"
                                          :rules="date_rule"
                                          hint="格式YYYY-MM-DD"
                                          required v-if="duration==='0'"></v-text-field>
                            <v-text-field label="截止日期"
                                          v-model="end_date"
                                          :rules="date_rule"
                                          hint="格式YYYY-MM-DD"
                                          required v-if="duration==='0'"></v-text-field>
                        </v-flex>
                        <v-flex d-flex xs12 class="pt-6">
                            <v-btn color="primary" class="mr-2" :loading="loading" @click.stop="doSearch">
                                查询
                            </v-btn>
                        </v-flex>
                    </v-card-text>
                </v-slide-y-transition>
            </v-form>
        </v-card>
        <v-card class="mt-4 pa-4">
            <v-data-table
                    :headers="getHeaders"
                    :items="items"
                    :options.sync="pagination"
                    :server-items-length="totalItems"
                    :loading="loading"
                    locale="zh-CH"
                    :footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems
              }"
            >
                <template v-slot:item.created_time="{ item }">
                    <span>{{ item.created_time | formatTimeLong }}</span>
                </template>
                <template slot="no-data">
                    <p class="text-xs-center grey--text">对不起，暂时无数据</p>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
  import {api} from "@/utils/api";
  import {getCurrentDateDash} from "@/utils/date";

  export default {
    name: "TrackingIndex",
    data() {
      return {
        query_type: '1',
        doctors: [],
        select_doctor: {},
        duration: '-6',
        start_date: '',
        end_date: getCurrentDateDash(),
        individual_no: '',
        action_type: '',
        loading: false,
        show: true,
        totalItems: 0,
        items: [],
        records: [],
        nextUrl: "",
        totalCount: 0,
        valid: true,
        select_rule: [v => v.length > 0 || '不能为空'],
        input_rule: [v => !!v || '不能为空'],
        date_rule: [
          v => !!v || '不能为空，格式YYYY-MM-DD',
          v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
        ],
        record_fields: [
          {
            value: "individual_no",
            text: "客户编号",
            sortable: true,
            breakpoints: []
          },

          {
            value: "individual_name",
            text: "姓名",
            sortable: true,
            breakpoints: []
          },
          {
            value: "org_name",
            text: "机构",
            sortable: true,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "doctor_name",
            text: "健康管理师",
            sortable: true,
            breakpoints: []
          },
          {
            value: "action",
            text: "操作类型",
            sortable: true,
            breakpoints: []
          },
          {
            value: "real_action_desc",
            text: "操作描述",
            sortable: true,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "created_time",
            text: "操作日期",
            sortable: false,
            breakpoints: ["xs", "sm"]
          }
        ],
        pagination: {},
        pageItems: [10, 20, 50],
      }
    },
    computed: {
      getHeaders() {
        let headers = [];
        //console.log(this.$vuetify.breakpoint.name)
        for (let item of this.record_fields) {
          if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
            headers.push(item);
          }
        }
        return headers;
      }
    },
    watch: {
      pagination: {
        async handler() {
          const {page, itemsPerPage} = this.pagination;
          if (this.nextUrl && this.records.length < page * itemsPerPage) {
            this.loading = true;
            let url = this.nextUrl.split("?");
            try {
              let result = await api.get(
                "ipl_working_tracking/?" + url[url.length - 1],
                this.search
              );
              for (let item of result["results"]) {
                this.records.push(item);
              }
              this.nextUrl = result["next"];
              this.totalCount = result["count"];
              this.updateDataTable(this.records, this.totalCount);
              this.loading = false;
            } catch (error) {
              this.loading = false;
            }
          } else {
            this.updateDataTable(this.records, this.totalCount);
          }
        },
        deep: true
      }
    },
    mounted() {
      this.doLoadData()
    },
    methods: {
      async doLoadData() {
        this.doctors = await api.get('doctors/get_employee_list/?', null)
      },
      async doSearch() {
        if (!this.$refs.formWorkingTrack.validate()) {
          return
        }
        this.loading = true;
        try {
          let result = await api.get(
            "ipl_working_tracking/?" +
            'type=' + this.query_type +
            '&doctor=' + this.select_doctor +
            '&individual=' + this.individual_no +
            '&action=' + this.action_type +
            '&d=' + this.duration +
            '&sd=' + this.start_date +
            '&ed=' + this.end_date, null
          );
          this.loading = false;
          this.records = result["results"];
          this.nextUrl = result["next"];
          this.totalCount = result["count"];
          this.pagination.page = 1
          this.updateDataTable(this.records, this.totalCount);
        } catch (error) {
          this.loading = false;
        }
      },
      updateDataTable(records, count) {
        const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
        let items = records;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }
        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }
        this.items = items;
        this.totalItems = count;
      },
    }
  }
</script>

<style scoped>

</style>