var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vernier-caliper"},[_vm._l((_vm.colorList),function(item,i){return _c('div',{key:i,staticClass:"vernier-caliper-block",style:({
      width: `${item.width * 100}%`,
      background: item.color
    })})}),_c('div',{staticClass:"vernier-caliper-cursor",style:({
      left: `${_vm.current * 100}%`,
      borderTopColor: _vm.currentColor
    })})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }