import {ActionContext} from 'vuex'

export interface State {
  logSearch: string,
  reload_ticket: string,
  refresh_ticked: boolean
}

const state: State = {
  logSearch: '',
  reload_ticket: '',
  refresh_ticked: false
}

const mutations = {
  setLogSearch(state: State, result: any) {
    state.logSearch = result;
  },
  clearLogSearch(state: State) {
    state.logSearch = ''
  },
  setReloadTicket(state: State, result: any) {
    state.reload_ticket = result;
  },
  setRefreshTicket(state: State) {
    state.refresh_ticked = !state.refresh_ticked;
  }
}

const getters = {
  logSearch: (state: State) => state.logSearch,
  reloadTicket: (state: State) => state.reload_ticket,
  refreshTicket: (state: State) => state.refresh_ticked
}

const actions = {
  setLogSearch(context: ActionContext<State, any>, result: any) {
    context.commit('setLogSearch', result)
  },
  clearLogSearch(context: ActionContext<State, any>) {
    context.commit('clearLogSearch')
  },
  setReloadTicket(context: ActionContext<State, any>, result: any) {
    context.commit('setReloadTicket', result)
  },
  setRefreshTicket(context: ActionContext<State, any>) {
    context.commit('setRefreshTicket')
  }
}

const params = {
  state,
  mutations,
  getters,
  actions
}
export default params;

