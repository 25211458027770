<template>
  <v-card class="assessment-dashboard" width="100%" style="padding:0 16px 8px;">
    <v-tabs center-active>
      <v-tab v-for="tab in tabs" :key="tab.key" @click="handleTabClick(tab.key)">
        {{tab.name}}
      </v-tab>
    </v-tabs>
    <v-subheader>总分：{{total.score}}</v-subheader>
    <v-subheader>平均分：{{total.average}}</v-subheader>
    <v-expansion-panels>
      <v-expansion-panel v-for="(panel, i) in panels" :key="i">
        <v-expansion-panel-header>
          【{{panel.name}}】得分：{{panel.score}}
          &nbsp;&nbsp;&nbsp;
          平均分：{{panel.average}}
          &nbsp;&nbsp;&nbsp;
          <template v-if="current == 'TCMG'">
            年龄：{{panel.transform_info}}
          </template>
          <template v-if="current == 'TCTB'">
            严重程度：{{panel.transform_info}}
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-subheader>
            主要危险因素
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item two-line v-for="risk in panel.risk_factor" :key="risk.factor_name">
            <v-list-item-content>
              <v-list-item-title>{{risk.factor_name}}</v-list-item-title>
              <v-list-item-subtitle>
                <p style="width: 1000px;word-break: break-all;white-space: normal;">
                  {{risk.factor_desc}}
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-subheader>
            相关特征
          </v-subheader>
          <v-divider></v-divider>
          <br/>
          <CharacterComp
            v-for="character in panel.character_relation"
            :key="character.sid"
            :c="character"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn @click="refreshPanel" :loading="loading" color="primary" style="margin-top: 16px;">重新生成</v-btn>
  </v-card>
</template>
<script>
  import {api} from "@/utils/api";
  import CharacterComp from '@/components/questionnaire/Character'
  import mixin from '@/components/questionnaire/common_mixin.js'
  import { isTextField, isLongTextField, isCheckBox, isRadioButton } from "@/utils/enumerations";

  export default {
    name: "customers-v2-report-index",
    inject: ['individualId', 'instance', 'handleShowDrawer', 'handleDrawerClose'],
    mixins: [mixin],
    components: { CharacterComp },
  // provide() {
  //   return {
  //     readonly: true
  //   }
  // },
    data() {
      return {
        tabs: [
          { key: 'TCHI', name: '个人健康指数' },
          { key: 'TCMG', name: '身体器官年龄' }, // 乳腺
          { key: 'TCTB', name: '机体内源环境' }  // 乳腺
          // { key: 'TCBO', name: '身体器官年龄' },
          // { key: 'TCFI', name: '机体内源环境' }
        ],
        current: 'TCHI',
        total: {},
        panels: [],
        loading: false
      }
    },
    computed: {
    },
    mounted() {
      this.getPanels()
    },
    methods: {
      handleTabClick(key) {
        this.current = key
        this.getPanels()
      },
      async refreshPanel() {
        this.loading = true
        await api.get(`individuals/${this.individualId}/generate_health_info/?category_no=${this.current}`)
        await this.getPanels()
        this.loading = false
      },
      async getPanels() {
        this.loading = true
        const details = await api.get(`individuals/${this.individualId}/get_health_info/?category_no=${this.current}`)
        const i = details.findIndex(detail => detail.name == '总分')
        const total = details.splice(i, 1)[0]
        this.total = total

        details.map(detail => {
          let risk_factor = detail.risk_factor || '[]'
          risk_factor = risk_factor.replace(/'/g, '"')
          detail.risk_factor = JSON.parse(risk_factor)

          detail.character_relation.map(c => {
            c.selected_values = []
            detail.phi_relation.map(relation => {
              if (c.sid !== relation.character) return
              c.instance = relation.instance
              c.phi = relation.phi

              const detailSid = relation.character_detail
              c.is_should_visible = true
              const { instance_value, detail_value } = relation
              if (isTextField(c.character_input_type)) {
                c.instance_value = instance_value
              } else if (isLongTextField(c.character_input_type)) {
                c.instance_value = instance_value
              } else if (isCheckBox(c.character_input_type)) {
                c.character_details.map(detail => {
                  c.instance_value = instance_value
                  if (detail.sid === detailSid) {
                    c.selected_values.push(Number(detail_value))
                    if (detail.is_other) {
                      detail.instance_value = `${instance_value}`
                    } else if (detail.is_exclusive) {
                      detail.intance_value = 'true'
                      c.character_details.map(item => {
                        if (item.sid !== detail.sid) {
                          item.is_disabled = true
                        }
                      })
                    } else {
                      detail.intance_value = 'true'
                    }
                  }
                })
              } else if (isRadioButton(c.character_input_type)) {
                c.character_details.map(item => {
                  item.instance_value = ''
                  item.detail_value = `${item.detail_value}`
                  if (item.sid === detailSid) {
                    c.instance_value = detail_value
                  }
                })
              }
              return c
            })
          })
        })
        this.panels = details
        this.loading = false
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>