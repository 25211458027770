<template>
    <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
          <v-tabs v-model="tab">
            <v-tab v-show="report_template.report_type != 'V'">生成报告</v-tab>
            <v-tab v-show="report_template.report_type != 'W'">上传报告</v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-title style="padding-bottom: 0px">
            <v-subheader>{{ instance.instance_name }}</v-subheader>
            <span v-show="false">{{ trigger }}</span>
            <v-spacer></v-spacer>
            <v-btn color="info" :disabled="!valid || dialog_save" :loading="dialog_save" @click.stop="showReport"
                  class="mr-2 hidden-sm-and-down">
                看报告
            </v-btn>
            <v-btn color="primary" :disabled="!valid || dialog_save" :loading="dialog_save" @click.stop="submit"
                  class="mr-2 hidden-sm-and-down" v-if="tab == 0 && instance.can_be_edit && !['V', 'W'].includes(report_template.report_type) && !instance.cover_user">
                重新生成报告
            </v-btn>
            <v-dialog v-model="dialog_regenerate" persistent max-width="290" v-if="tab == 0 && instance.can_be_edit && ! ['V', 'W'].includes(report_template.report_type) && instance.cover_user">
                <template v-slot:activator="{on}">
                    <v-btn color="primary" class="mr-2" v-on="on">重新生成报告</v-btn>
                </template>
                <v-card v-if="dialog_regenerate">
                    <v-card-title class="text-subtitle-2">您确认重新生成报告吗?</v-card-title>
                    <v-card-text>该报告已被人为上传覆盖，重新生成将丢失现有版本</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click.native="dialog_regenerate = false">取消</v-btn>
                        <v-btn color="error" text @click.native="submit">确认重新生成报告</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-btn color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                  @click.stop="coverPdf" v-if="tab == 1 && instance.can_be_edit">
                上传报告
            </v-btn>
            <v-btn color="grey" class="mr-2 hidden-sm-and-down" @click="doBackHistory">取消</v-btn>
            <v-tooltip bottom v-if="this.$store.getters.showReviewHistory && instance.internal_status !== 'INIT'">
                <template v-slot:activator="{on}">
                    <v-icon color="primary" left v-on="on" @click="doSetReviewHistory">mdi-timetable</v-icon>
                </template>
                <span>隐藏审核历史</span></v-tooltip>
            <v-tooltip bottom v-else-if="instance.internal_status !== 'INIT'">
                <template v-slot:activator="{on}">
                    <v-icon color="grey darken-1" left v-on="on" @click="doSetReviewHistory">mdi-timetable</v-icon>
                </template>
                <span>显示审核历史</span></v-tooltip>
        </v-card-title>
        <v-card-text style="padding-top: 0px; padding-bottom: 0px">
            <v-subheader class="caption font-weight-medium"
                        style="padding-top: 0px; padding-bottom: 0px; height: 30px">{{ customer.individual_no }}-{{
                customer.individual_name }}-{{ customer.organization.org_name }}
            </v-subheader>
        </v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
              <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
                  <v-card-text style="padding-top: 32px">
                      <v-layout row wrap>
                          <v-flex d-flex xs12 style="padding: 0px 36px ">
                              <v-dialog
                                      ref="dialog"
                                      v-model="modal"
                                      :return-value.sync="instance.report_date"
                                      width="290px"
                              >
                                  <template v-slot:activator="{ on }">
                                      <v-text-field
                                              v-on="on"
                                              v-model="instance.report_date"
                                              label="报告日期"
                                              readonly
                                              style="padding: 0px"
                                              :rules="instance.report_date_rules"
                                      ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="instance.report_date" @input="getAvailableDetails" scrollable
                                                color="primary" :max="current_date">
                                  </v-date-picker>
                              </v-dialog>
                          </v-flex>
                          <v-flex d-flex xs12 style="padding: 0px 36px" v-if="!['V', 'W'].includes(report_template.report_type)">
                            <v-checkbox v-model="instance.is_cover" label="是否生成封面"></v-checkbox>
                          </v-flex>
                      </v-layout>
                      <v-layout row wrap v-if="report_template.report_type==='S'">
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                              <v-autocomplete
                                      v-model="select"
                                      :hint="`送检单位：${select.org_name}`"
                                      :items="org_infos"
                                      item-text="org_name"
                                      item-value="sid"
                                      label="送检单位"
                                      persistent-hint
                                      return-object
                                      single-line
                                      :rules="report_instance_rules.test_company"
                              ></v-autocomplete>
                          </v-flex>
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                              <v-text-field label='样本编号' v-model="instance.sample_no"
                                            :rules="report_instance_rules.sample_no"></v-text-field>
                          </v-flex>
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                              <v-text-field label='样本类型' v-model="instance.sample_type"
                                            :rules="report_instance_rules.sample_type"></v-text-field>
                          </v-flex>
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                              <v-text-field label="采样日期" v-model="instance.sample_date"
                                            :rules="report_instance_rules.sample_date" hint="格式YYYY-MM-DD"
                                            required></v-text-field>
                          </v-flex>
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px">
                              <v-text-field label="检测日期" v-model="instance.test_date" :rules="report_instance_rules.test_date"
                                            hint="格式YYYY-MM-DD" required></v-text-field>
                          </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                          <v-flex d-flex xs12 sm6 md4 style="padding: 0px 36px"
                                  v-for="template_detail in report_template_details" :key="template_detail.sid">
                              <v-layout row wrap>
                                  <v-flex d-flex xs12>
                                      <v-subheader style="height: 32px; padding: 0px 0px" v-if='template_detail.is_mandatory'>
                                          {{template_detail.character_template.template_name}}(必选)
                                      </v-subheader>
                                      <v-subheader style="height: 32px; padding: 0px 0px" v-else>
                                          {{template_detail.character_template.template_name}}(可选)
                                          <v-btn icon x-small fab color="primary" @click="doClearSelect(template_detail)">
                                              <v-icon>mdi-refresh</v-icon>
                                          </v-btn>
                                      </v-subheader>
                                  </v-flex>
                                  <v-radio-group v-model="template_detail.selected_value" row style="padding: 0px 12px"
                                                :rules="template_detail.rules" :readonly="is_readonly"
                                                @change="trigger=!trigger">
                                      <v-flex d-flex xs12 style="padding: 12px 0px; height: 32px"
                                              v-for="instance_detail in template_detail.instance_details"
                                              :key="instance_detail.sid">
                                          <v-radio
                                                  :label="instance_detail.instance_name + '(' + instance_detail.happen_date + ')'"
                                                  :value="instance_detail.sid" color="primary"></v-radio>
                                      </v-flex>
                                  </v-radio-group>
                              </v-layout>
                          </v-flex>
                      </v-layout>
                  </v-card-text>
                  <v-card-title style="padding: 0px">
                      <v-spacer></v-spacer>
                      <v-btn color="info" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                            @click.stop="showReport">
                          看报告
                      </v-btn>
                      <v-btn color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                            @click.stop="submit" v-if="instance.can_be_edit && !['V', 'W'].includes(report_template.report_type) && !instance.cover_user">
                          重新生成报告
                      </v-btn>
                      <v-dialog v-model="dialog_regenerate" persistent max-width="290" v-if="instance.can_be_edit &&  !['V', 'W'].includes(report_template.report_type) && instance.cover_user">
                          <template v-slot:activator="{on}">
                              <v-btn color="primary" class="mr-2" v-on="on">重新生成报告</v-btn>
                          </template>
                          <v-card v-if="dialog_regenerate">
                              <v-card-title class="text-subtitle-2">您确认重新生成报告吗?</v-card-title>
                              <v-card-text>该报告已被人为上传覆盖，重新生成将丢失现有版本</v-card-text>
                              <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="green darken-1" text @click.native="dialog_regenerate = false">取消</v-btn>
                                  <v-btn color="error" text @click.native="submit">确认重新生成报告</v-btn>
                              </v-card-actions>
                          </v-card>
                      </v-dialog>
                      <v-btn color="grey" @click="doBackHistory">取消</v-btn>
                      <v-spacer></v-spacer>
                  </v-card-title>
              </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-flex d-flex xs12 style="padding: 0px 36px">
              <v-file-input
                  v-model="pdfFile"
                  accept=".pdf"
                  label="请选择报告文件（pdf报告）"
                  persistent-hint
              ></v-file-input>
            </v-flex>
            <v-flex d-flex xs12 style="padding: 0px 36px;color:rgb(151 151 151);font-size:14px;">
                * 上传后将会覆盖原有的PDF文件，请谨慎操作
            </v-flex>
            <v-card-title style="padding: 0px">
                <v-spacer></v-spacer>
                <v-btn color="info" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                      @click.stop="showReport">
                    看报告
                </v-btn>
                <v-btn color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save"
                      @click.stop="coverPdf" v-if="instance.can_be_edit">
                    上传报告
                </v-btn>
                <v-btn color="grey" @click="doBackHistory">取消</v-btn>
                <v-spacer></v-spacer>
            </v-card-title>
          </v-tab-item>
        </v-tabs-items>
        <v-card-text>
            <v-layout justify-center align-center=""
                      v-if="instance.internal_status === 'INIT' || instance.internal_status === 'RRDA'">
                <v-dialog v-model="dialog_review" persistent max-width="290" v-if="has_review_right">
                    <template v-slot:activator="{on}">
                        <v-btn color="info" v-on="on">提交审核</v-btn>
                    </template>
                    <v-card v-if="dialog_review">
                        <v-card-title class="text-subtitle-2">您确认提交审核吗?</v-card-title>
                        <v-card-text>一旦提交，记录将无法修改，请仔细核对</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click.native="dialog_review = false">取消</v-btn>
                            <v-btn color="error" text @click.native="sendRequest">确认提交审核</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog_delete" persistent max-width="290" v-if="instance.can_be_edit && has_delete_right">
                    <template v-slot:activator="{on}">
                        <v-btn color="error" v-on="on">删除</v-btn>
                    </template>
                    <v-card v-if="dialog_delete">
                        <v-card-title class="text-subtitle-2">您确认要删除该记录吗?</v-card-title>
                        <v-card-text>删除后该记录将无法还原，请您谨慎操作</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click.native="dialog_delete = false">取消</v-btn>
                            <v-btn color="error" text @click.native="doDelete">我想好了，删</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
            <v-layout justify-end v-if="instance.internal_status === 'INIT' || instance.internal_status === 'DRDA'">
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
  import {api} from "@/utils/api";
  import {getCurrentDateDash} from "@/utils/date";
  import nf from "@/utils/notification";
  import axios from 'axios'

  export default {
    name: "EditReport",
    inject: ['individualId', 'handleShowDrawer', 'handleDrawerClose'],
    props: {
      id: {
        type: String
      },
      sid: {
        type: String
      }
    },
    data() {
      return {
        tab: 0,
        is_readonly: false,
        valid: false,
        modal: false,
        dialog_review: false,
        dialog_delete: false,
        dialog_regenerate: false,
        customer: {
          doctor: {},
          organization: {}
        },
        isShow: false,
        trigger: false,
        instance_details: [],
        report_instance_details: [],
        report_template_details: [],
        instance: {},
        dialog_save: false,
        org_info: {
          sid: '',
          org_name: ''
        },
        select: {org_name: '', sid: ''},
        org_infos: [],
        report_template: {},
        report_instance_rules: {
          sample_no: [v => !!v || "不能为空"],
          sample_type: [v => !!v || "不能为空"],
          test_company: [v => !!v || "不能为空"],
          sample_date: [
            v => !!v || '不能为空，格式YYYY-MM-DD',
            v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
          ],
          test_date: [
            v => !!v || '不能为空，格式YYYY-MM-DD',
            v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
          ]
        },
        is_cover: false,
        showCoverPdf: false,
        pdfFile: null
      }
    },
    computed: {
      current_date() {
        return getCurrentDateDash()
      },
      has_review_right() {
        return this.$store.getters.getPermission('reportinstance', 'review')
      },
      has_delete_right() {
        return this.$store.getters.getPermission('reportinstance', 'delete')
      }
    },
    mounted() {
      this.doLoadData()

    },
    methods: {
      async doLoadData() {
        //获得模版实例
        this.instance = await api.get("report_instances/" + this.sid + "/?", null)
        this.org_infos = await api.get("organizations/?", null)
        for (let org of this.org_infos) {
          if (org.sid === this.instance.test_company) {
            this.select.sid = org.sid
            this.select.org_name = org.org_name
          }
        }
        this.report_template = await api.get("report_templates/" + this.instance.report_template.sid + "/?", null)
        //获取模版实例明细
        this.report_instance_details = await api.get("report_instance_details/?instance=" + this.instance.sid, null)
        this.customer = await api.get("individuals/" + this.id + "/?", null)
        this.instance_details = await api.get("report_templates/" + this.instance.report_template.sid + "/get_available_template_instances/?d=" + this.instance.report_date + "&i=" + this.customer.sid + "&sid=" + this.instance.sid, null)
        this.report_template_details = await api.get("report_template_details/?template=" + this.instance.report_template.sid, null)
        for (let template_detail of this.report_template_details) {
          template_detail.instance_details = []
          if (template_detail.is_mandatory) {
            template_detail.rules = [v => !!v || "不能为空"]
          }

          template_detail.selected_value = ""
          for (let detail of this.instance_details) {
            if (template_detail.character_template.sid === detail.template.sid) {
              for (let instance_detail of this.report_instance_details) {
                if (detail.sid === instance_detail.character_instance.sid) {
                  template_detail.selected_value = instance_detail.character_instance.sid
                }
              }
              template_detail.instance_details.push(detail)
            }
          }
        }
        this.trigger = !this.trigger

        if (this.report_template.report_type == 'V') {
          this.tab = 1
        }
      },
      async submit() {
        if (this.$refs.form.validate()) {
          this.dialog_regenerate = false
          this.dialog_save = true
          //开始组装数据
          let instance_combo = {
            report_date: null,
            instance_details: []
          };
          instance_combo.report_date = this.instance.report_date;
          instance_combo.sample_date = this.instance.sample_date;
          instance_combo.test_date = this.instance.test_date;
          instance_combo.test_company = this.select.sid;
          instance_combo.sample_no = this.instance.sample_no;
          instance_combo.sample_type = this.instance.sample_type;
          instance_combo.is_cover = this.instance.is_cover;
          for (let detail of this.report_template_details) {
            if (detail.instance_details.length <= 0) {
              continue
            }
            let instance_detail = {}
            instance_detail.character_instance = detail.selected_value
            instance_combo.instance_details.push(instance_detail)
          }
          await api.put("report_instances/" + this.instance.sid + "/", instance_combo)
          this.dialog_save = false
          nf.showSuccessMsg("数据保存成功！");
          // await this.$router.replace({
          //   name: "客户信息",
          //   params: {id: this.customer.sid}
          // });
          this.$router.back(-1)
        }
      },
      async getAvailableDetails() {
        this.$refs.dialog.save(this.instance.report_date)
        //获取可用的实例明细
        this.instance_details = await api.get("report_templates/" + this.instance.report_template + "/get_available_template_instances/?d=" + this.instance.report_date + "&i=" + this.customer.sid + "&sid=" + this.instance.sid, null)
        for (let template_detail of this.report_template_details) {
          template_detail.instance_details = []
          if (template_detail.is_mandatory) {
            template_detail.rules = [v => !!v || "不能为空"]
          }
          template_detail.selected_value = ""
          for (let instance_detail of this.instance_details) {
            if (template_detail.character_template.sid === instance_detail.template) {
              for (let detail of this.report_instance_details) {
                if (instance_detail.sid === detail.character_instance.sid) {
                  template_detail.selected_value = detail.character_instance.sid
                }
              }
              if (template_detail.selected_value.length === 0) {
                template_detail.selected_value = instance_detail.sid
              }
              template_detail.instance_details.push(instance_detail)
            }
          }
        }
        this.trigger = !this.trigger
        this.$refs.form.validate()
      },
      doSetReviewHistory() {
        this.$store.dispatch("review_history")
      },
      doBackHistory() {
        // this.$router.replace({
        //   name: "客户信息",
        //   params: {id: this.customer.sid}
        // });
        this.$router.back(-1)
      },
      async doDelete() {
        await api.delete("report_instances/" + this.instance.sid + "/?")
        nf.showSuccessMsg("数据删除成功！");
        this.$router.back(-1)
        // await this.$router.replace({
        //   name: "客户信息",
        //   params: {id: this.customer.sid}
        // });
      },
      async sendRequest() {
        if (this.$refs.form.validate()) {
          await api.put("report_instances/" + this.instance.sid + "/send_to_request/?", null)
          nf.showSuccessMsg("数据提交成功！");
          this.$router.back(-1)
          // await this.$router.replace({
          //   name: "客户信息",
          //   params: {id: this.customer.sid}
          // });
        }
      },
      doClearSelect(value) {
        value.selected_value = ""
        this.trigger = !this.trigger
      },
      async showReport() {
        if (this.report_template.report_type=='W') {
          this.handleDrawerClose()
          await this.$nextTick()
          this.handleShowDrawer(this.instance.instance_name, 'WebReportFrameContain', {
            url: `${location.origin}/#/RPT-MPBH-V1/0/${this.sid}`
          })
          return
        }
        this.dialog_view_report = true
        let data = await api.get("report_instances/" + this.sid + "/get_pdf_report/?", {responseType: 'blob'})

        let binaryData = [];
        binaryData.push(data);

        let filename = this.customer.individual_name + "_" + this.instance.instance_name + "_" + this.customer.individual_no + "_" + this.instance.report_date.replace(/-/g, "")
        let url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename + '.pdf')
        document.body.appendChild(link)
        link.click()
        this.dialog_view_report = false

      },
      async coverPdf() {
        if (!this.pdfFile) {
          return nf.showWarnMsg('请选择报告文件')
        }
        this.dialog_save = true
        const fd = new FormData()
        fd.append('file', this.pdfFile)
        fd.append('ri_sid', this.sid)
        await axios.post('api/report_instances/cover_pdf/', fd, {
          headers: {
            Authorization: 'Martin ' + this.$store.getters.token
          }
        })
        this.dialog_save = false
        nf.showSuccessMsg("上传成功！");
        this.$router.back(-1)
      }
    }
  }
</script>
