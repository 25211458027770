import {ActionContext} from "vuex";

export interface State {
  selectedTab: string,
  selectedTabTemplate: string
}
const state: State = {
  selectedTab: "",
  selectedTabTemplate: "",
};
const getters = {
  getSelectedTab: (state: State) => state.selectedTab,
  getSelectedTabTemplate: (state: State) => state.selectedTabTemplate,
};
const mutations = {
  setSelectedTab(state: State, result: string) {
    state.selectedTab = result
  },
  setSelectedTabTemplate(state: State, result: string) {
    state.selectedTabTemplate = result
  }
};
const actions = {
  setSelectedTab(context: ActionContext<State, any>, result: any) {
    context.commit('setSelectedTab', result)
  },
  setSelectedTabTemplate(context: ActionContext<State, any>, result: any) {
    context.commit('setSelectedTabTemplate', result)
  }
};

const routeParams = {
  state,
  mutations,
  getters,
  actions
};

export default routeParams;