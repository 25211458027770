<template>
  <v-card class="nutrient-intake" v-if="dataList.length > 0">
    <v-subheader>
      营养素服用史
      <v-switch
        style="margin:0 16px"
        v-model="showNotEndData"
        label="仅显示未结束数据"
        hide-details
      />
    </v-subheader>
    <div v-for="(item, i) in tableData" :key="i" class="item">
      <div class="item-row">
        品名：{{item.name}}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="() => handleRowClick(item)" color="primary" style="margin-left:16px;font-size: 18px;">
              mdi-pencil-outline
            </v-icon>
          </template>
          <span>编辑</span>
        </v-tooltip>
      </div>
      <div class="item-row">
        诊断日期：{{item.metering}}
        <v-spacer></v-spacer>
        <div class="item-row-right">
          服用日期：{{item.startDate}}至{{item.endDate || '今'}}
        </div>
      </div>
      <v-divider style="margin-top:16px;"></v-divider>
    </div>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
export default {
  name: 'NutrientIntake',
  inject: ['individualId', 'handleShowDrawer'],
  data() {
    return {
      showNotEndData: false,
      dataList: []
    }
  },
  computed: {
    tableData() {
      return this.dataList.filter(d => this.showNotEndData ? (!d.endDate): true)
    }
  },
  async mounted() {
    const characterCemplates = await api.get("character_templates/get_template_list/?c=" + this.individualId)
    const nutrientTemplates = characterCemplates.filter(cc => cc.display_type == 'M' && cc.template_no == 'CT-SURVEY-D&M-H')

    const template_ids = nutrientTemplates.map(template => template.sid)
    const templateData = await api.post("template_instances/get_template_instances_by_customer/", {
      i: this.individualId,
      template_ids
    })

    let dataList = []
    for(const template of nutrientTemplates) {
      const data = templateData.filter(d => d.template == template.sid)
      data.map(d => {
        const nutrientName = d.template_instances.find(ti => ti.character.character_no == 'C-SQ-B-003')
        const confirmDate = d.template_instances.find(ti => ti.character.character_no == 'C-SQ-B-002')
        const startDate = d.template_instances.find(ti => ti.character.character_no == 'C-SQ-B-005')
        const endDate = d.template_instances.find(ti => ti.character.character_no == 'C-SQ-B-006')
        dataList.push({
          sid: d.sid,
          name: nutrientName.instance_value,
          metering: confirmDate.instance_value,
          startDate: startDate.instance_value,
          endDate: endDate && endDate.instance_value
        })
      })
    }
    dataList = dataList.sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))
    this.dataList = dataList
  },
  methods: {
    handleRowClick(item) {
      this.handleShowDrawer(item.name, 'CharacterEditRecord', { id: this.individualId, sid: item.sid })
    }
  }
}
</script>
<style lang="scss" scoped>
.nutrient-intake {
  width: 100%;
  .item {
    margin: 16px 16px 0;
    font-size: 14px;
    .item-row {
      display: flex;
      .item-row-right {
        width: 230px;
      }
    }
  }
}
</style>