<template>
    <v-list dense>
        <!-- <v-list-item @click.stop="right = !right">
          <v-list-item-action class="mr-2">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Open Temporary Drawer</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click.stop="dialog = true">
            <v-dialog v-model="dialog" persistent max-width="400px">
                <template v-slot:activator="{ on }">
                    <v-list-item-action class="mr-4" v-on="on">
                        <v-icon>mdi-key-change</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-on="on">修改密码</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-form
                        ref="formPassword"
                        v-model="valid"
                        lazy-validation
                >
                    <v-card>
                        <v-card-title>
                            <span class="body-1">修改密码</span>
                            <span v-show="false">{{ isShow }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex d-flex xs12>
                                      <v-text-field
                                        label="手机号码"
                                        :value="$store.getters.userName"
                                        disabled
                                      />
                                    </v-flex>
                                    <v-flex d-flex xs12 class="sms">
                                        <!-- <v-text-field
                                                height="32px"
                                                label="原密码"
                                                v-model="password_info.old_password"
                                                :rules="password_info.old_password_rules"
                                                type="password"
                                        ></v-text-field> -->
                                      <v-text-field
                                        name="password"
                                        label="验证码"
                                        v-model="password_info.code"
                                        :rules="password_info.code_rules"
                                      >
                                      </v-text-field>
                                      <v-btn v-if="sending" class="sms-btn" small disabled>{{cd}}S</v-btn>
                                      <v-btn v-else class="sms-btn" small color="primary" @click="sendSms">{{sended ? '重新发送' : '获取验证码'}}</v-btn>
                                    </v-flex>
                                    <v-flex d-flex xs12>
                                        <v-text-field
                                            height="32px"
                                            label="新密码"
                                            v-model="password_info.new_password"
                                            :rules="password_info.new_password_rules"
                                            type="password"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex d-flex xs12>
                                        <v-text-field
                                                height="32px"
                                                label="确认新密码"
                                                v-model="password_info.confirm_new_password"
                                                :rules="password_info.confirm_new_password_rules"
                                                type="password"
                                                :error="password_info.confirm_new_password_error"
                                                :error-messages="
                            password_info.confirm_new_password_error_msg
                          "
                                        ></v-text-field>
                                    </v-flex>
                                    <!-- <v-flex d-flex xs12>
                        <span class="caption error--text"
                        >*密码修改成功后需要重新登录</span
                        >
                                    </v-flex> -->
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="setPassword"
                            >确认修改
                            </v-btn
                            >
                            <v-btn color="blue darken-1" text @click="dialog = false"
                            >取消
                            </v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-list-item>
        <v-list-item @click.stop="logout">
            <v-list-item-action class="mr-4">
                <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>退出系统</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";

  // 避免手动清除louid 生成随机数
  let lguid = localStorage.getItem('lguid')
  if (!lguid) {
    localStorage.setItem('lguid', Math.random() * 10e17)
    lguid = localStorage.getItem('lguid')
  }

  export default {
    name: "RightBar",
    data() {
      return {
        password_info: {
          lguid,
          code: '',
          // old_password: "",
          new_password: "",
          confirm_new_password: "",
          confirm_new_password_error: false,
          confirm_new_password_error_msg: "",
          // old_password_rules: [v => !!v || "不能为空"],
          code_rules: [v => !!v || "不能为空", v => v.length === 4 || '验证码错误'],
          new_password_rules: [
            v => !!v || "不能为空",
            v => /^[a-zA-Z]/.test(v) || "密码开头字母只能是大/小写字母",
            v =>
              /^[_a-zA-Z0-9!@#$%^&*?]+$/.test(v) ||
              "密码只能包含大小写字母、数字、特殊字符(!@#$%^&*?)及下划线",
            v =>
              /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/.test(v) ||
              "密码长度8-16位，必须包含大小写字母及数字"
          ],
          confirm_new_password_rules: [
            v => !!v || "不能为空",
            v => /^[a-zA-Z]/.test(v) || "密码开头字母只能是大/小写字母",
            v =>
              /^[_a-zA-Z0-9!@#$%^&*?]+$/.test(v) ||
              "密码只能包含大小写字母、数字、特殊字符(!@#$%^&*?)及下划线",
            v =>
              /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/.test(v) ||
              "密码长度8-16位，必须包含大小写字母及数字"
          ]
        },
        dialog: false,
        valid: true,
        isShow: false,
        sended: false,
        sending: false,
        cd: 60,
      }
    },
    methods: {
      cooldown(cd) {
        if (cd < 0) {
          this.sending = false
          return
        }
        this.cd = cd
        cd--
        setTimeout(() => this.cooldown(cd), 1000)
      },
      async sendSms() {
        this.sending = true
        this.cd = 60
        let result = await api.post('sms/login_code/', { phone: this.$store.getters.userName, lguid, type: 'change_psw' })
        if (result === 'True') {
          nf.showSuccessMsg("验证码已发送到您的手机，请注意查收");
          this.sended = true
          this.cooldown(60)
        } else {
          nf.showWarnMsg(result);
          this.sending = false
        }
      },
      logout() {
        this.$store.dispatch("logout");
        this.$router.replace("/login");
      },
      async setPassword() {
        if (!this.sended) {
          nf.showWarnMsg("请先获取验证码");
          return
        }
        if (this.$refs.formPassword.validate()) {
          if (
            this.password_info.confirm_new_password !==
            this.password_info.new_password
          ) {
            this.password_info.confirm_new_password_error = true;
            this.password_info.confirm_new_password_error_msg =
              "两次输入的密码不一致";
            this.isShow = !this.isShow;
          } else
          //  if (
          //   this.password_info.confirm_new_password ===
          //   this.password_info.old_password
          // ) {
          //   this.password_info.confirm_new_password_error = true;
          //   this.password_info.confirm_new_password_error_msg =
          //     "新密码不能与原密码相同";
          //   this.isShow = !this.isShow;
          // } else 
          {
            let result = await api.post(
              "users/change_password/?",
              this.password_info
            );
            if (result.code === 200) {
              // nf.showSuccessMsg("密码修改成功，请重新登录！");
              // await this.$store.dispatch("logout");
              // await this.$router.replace("/login");
              nf.showSuccessMsg("密码修改成功！");
              this.dialog = false
            } else {
              nf.showErrorMsg(result.message);
            }
            this.password_info.confirm_new_password_error = false;
            this.password_info.confirm_new_password_error_msg = "";
            this.isShow = !this.isShow;
          }
        }
      }
    }
  }
</script>

<style scoped>
.sms {
  align-items: center;
}
.sms-btn {
  margin-left: 16px;
}
</style>