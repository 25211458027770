<template>
  <v-layout row style="margin: 0;">
    <v-flex xs8 style="padding-right:8px;">
      <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
          <v-spacer></v-spacer>
          <v-btn v-if="!readonly" color="primary" class="mr-2" @click="handleSubmit">
            保存
          </v-btn>
        </v-card-title>
        <v-form style="width: 100%" class="py-2 pl-6 pr-3" ref="form" lazy-validation v-on:submit.prevent="handleSubmit">
          <v-text-field label="管理计划名称" v-model="plan.name" :rules="requireRules" :readonly="readonly"/>
          <v-menu v-model="showDatePicker" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field clearable readonly label="计划开始日期" v-model="planStartDate" v-bind="attrs" v-on="on" style="width:200px;"/>
            </template>
            <v-date-picker v-model="planStartDate" scrollable :min="today" locale="cn" />
          </v-menu>
        </v-form>
        <QuestionnaireOnePage
          v-if="characterTemplateId && (planId == 0 || plan.instance)"
          :individualId="individualId"
          :characterTemplateId="characterTemplateId"
          :instanceId="plan.instance"
          :readonly="readonly"
          ref="questionnaire"
        />
      </v-card>
    </v-flex>
    <v-flex xs4 style="padding-left:16px;">
      <MemorandumComp style="margin-bottom:24px;" />
      <ConsultationEvaluationComp style="margin-bottom:24px;" />
      <NutrientIntakeComp />
    </v-flex>
  </v-layout>
</template>
<script>
import MemorandumComp from './side-card/Memorandum.vue'
import ConsultationEvaluationComp from './side-card/ConsultationEvaluation.vue'
import NutrientIntakeComp from './side-card/NutrientIntake.vue'
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
import { api } from '@/utils/api'
import { getCurrentDateDash } from "@/utils/date";
import nf from "@/utils/notification";
export default {
  name: 'ManagePlanDetail',
  components: { MemorandumComp, ConsultationEvaluationComp, NutrientIntakeComp, QuestionnaireOnePage },
  inject: ['individualId', 'instance'],
  data() {
    const { planId } = this.$route.params
    return {
      today: getCurrentDateDash(),
      showDatePicker: false,
      planId,
      planStartDate: getCurrentDateDash(),
      plan: {},
      characterTemplateId: null,
      requireRules: [v => !!v || '不能为空']
    }
  },
  computed: {
    readonly() {
      return this.planId == 0 ? false: (this.plan.state !== 'R')
    }
  },
  async mounted() {
    this.plan.name = `${this.instance.customer.individual_name}的健康管理计划`
    try {
      const { sid: template_id } = await api.post(`health_manage/get_template_id/`, { 'template_no': 'CT-HMP-001' })
      this.characterTemplateId = template_id
    } catch(e) {
      console.error(e)
    }
    if (this.planId != 0) {
      try {
        this.plan = await api.get(`health_manage/get_plan_detail?p=${this.planId}`)
      } catch(e) {
        console.error(e)
      }
    }
  },
  methods: {
    async handleSubmit() {
      const questionnaire = this.$refs['questionnaire']
      let valid = true
      if (!this.$refs.form.validate()) {
        valid = false
      }
      if (!questionnaire || !questionnaire.$refs.form.validate()) {
        valid = false
      }
      if (!valid) {
        nf.showWarnMsg(`表单检验失败，请检查必填项`)
        return
      }
      let instanceId = null
      try {
        instanceId = await questionnaire.doPostData()
      } catch(e) {
        console.error(e)
      }
      if (!instanceId) return
      try {
        if (this.planId == 0) {
          const data = { instance_id: instanceId, ...this.plan, plan_start_date: this.planStartDate }
          await api.post(`health_manage/`, data)
        } else {
          await api.put(`health_manage/${this.planId}/`, { instance_id: this.plan.instance, name: this.plan.name, plan_start_date: this.planStartDate })
        }
        nf.showSuccessMsg('保存成功')
        this.$router.back(-1)
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>