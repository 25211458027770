<template>
  <div class="health-evaluate-report">
    <div class="header">
      向内探索，重塑您的生命历程
      <img src="@/assets/favicon-half.png"/>
    </div>
    <div class="main">
      <div class="welcome">
        <h4>尊敬的张先生，您好！</h4>
        <p>您的{{single_score.check_item_name}}为{{single_score.score}}分，{{single_score.desc}}</p>
      </div>
      <div style="height:210px;" v-if="color.length > 0">
        <Echart :options="{
          series: [
            {
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 1,
              radius: '150%',
              center: ['50%', '85%'],
              splitNumber: 10,
              axisLine: {
                lineStyle: {
                  width: 12,
                  color: color
                }
              },
              pointer: {
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '10%',
                width: 10,
                offsetCenter: [0, '-65%'],
                itemStyle: {
                  color: 'auto'
                }
              },
              axisTick: {
                length: 10,
                lineStyle: {
                  color: 'auto',
                  width: 1
                }
              },
              splitLine: {
                length: 13,
                lineStyle: {
                  color: 'auto',
                  width: 3
                }
              },
              axisLabel: {
                show: false
              },
              detail: {
                fontSize: 24,
                offsetCenter: [0, 0],
                valueAnimation: true,
                formatter: function (value) {
                  return Math.round(value * 100) + '分';
                },
                color: 'auto'
              },
              data: [
                {
                  value: single_score.score / 100,
                  name: '个人健康指数\r\nPHI',
                  title: {
                    offsetCenter: [0, '-30%'],
                    width: 10,
                    fontSize: 24
                  }
                }
              ]
            }
          ]
        }" />
      </div>
      <div class="score-row" v-for="(item, i) in scores" :key="i">
        <label>{{item.check_item_name}} {{item.score}}分</label>
        <VernierCaliper class="flex1" :color="item.color" :current="item.score/100" />
      </div>
      <div class="score-legend">
        <div>
          <div class="score-legend-block" :style="{ background: color[0][1] }" />
          低于平均水平
        </div>
        <div>
          <div class="score-legend-block" :style="{ background: color[1][1] }" />
          处于平均水平
        </div>
        <div>
          <div class="score-legend-block" :style="{ background: color[2][1] }" />
          高于平均水平
        </div>
      </div>
      <template v-if="health_problems.length > 0">
        <div class="main-quest-title">主要健康问题</div>
        <div class="main-quest-block" v-for="(item, i) in health_problems" :key="i">
          <p class="main-quest-block-title">{{item.health_problem_name}}</p>
          <div>{{item.health_problem_desc}}</div>
        </div>
      </template>
      <template v-if="people_health_comparison.length > 0">
        <div class="main-quest-title">个人健康指数前后比对</div>
        <table border="0" cellspacing="0" cellpadding="0" class="tb">
          <tr>
            <th>项目</th>
            <th>管理前</th>
            <th>管理后</th>
            <th>趋势</th>
          </tr>
          <tr v-for="(item, i) in people_health_comparison" :key="i">
            <td>{{item.items}}</td>
            <td>{{item.before_manage}}</td>
            <td>{{item.after_manage}}</td>
            <td :style="{ color: txtColorMap[item.trend] }">{{item.trend}}</td>
          </tr>
        </table>
      </template>
      <template v-if="health_problem_comparison.length > 0">
        <div class="main-quest-title">主要健康问题前后比对</div>
        <table border="0" cellspacing="0" cellpadding="0" class="tb">
          <tr>
            <th>项目</th>
            <th>改善情况</th>
          </tr>
          <tr v-for="(item, i) in health_problem_comparison" :key="i">
            <td>{{item.items}}</td>
            <td :style="{ color: txtColorMap[item.improve_situation] }">{{item.improve_situation}}</td>
          </tr>
        </table>
      </template>
      <template v-if="physiological_index_comparison.length > 0">
        <div class="main-quest-title">生理指标前后比对</div>
        <table border="0" cellspacing="0" cellpadding="0" class="tb">
          <tr>
            <th>项目</th>
            <th>管理前</th>
            <th>管理后</th>
            <th>改善情况</th>
          </tr>
          <tr v-for="(item, i) in physiological_index_comparison" :key="i">
            <td>{{item.items}}</td>
            <td>{{item.before_manage}}</td>
            <td>{{item.after_manage}}</td>
            <td :style="{ color: txtColorMap[item.improve_situation] }">{{item.improve_situation}}</td>
          </tr>
        </table>
      </template>
      <template v-if="conclusion">
        <div class="main-quest-title">结论</div>
        <div class="next-suggest" v-html="conclusion"></div>
      </template>
      <template v-if="next_proposal">
        <div class="main-quest-title">下一步建议</div>
        <div class="next-suggest" v-html="next_proposal"></div>
      </template>
      <div class="sign">
        健康管理师：{{health_manager}}
      </div>
      <div class="sign">
        评价日期：{{evaluation_date}}
      </div>
      <div class="tip">
        如有任何疑问，请及时联系您的健康管理师
      </div>
    </div>
    <div class="part2">
      <div class="tit">个人健康指数(PHI)说明</div>
      <div class="desc">个人健康指数(Personal Health Index)，是评价个体健康状况的综合性指标，有五个评价维度，分别是遗传基因、外部环境、生活习惯、生理指标及身体状态。</div>
      <table border="0" cellspacing="0" cellpadding="0" class="tb">
        <tr>
          <th width="120">维度</th>
          <th>说明</th>
        </tr>
        <tr>
          <td>遗传基因</td>
          <td>评价家族史、相关基因检测指标</td>
        </tr>
        <tr>
          <td>身体状态</td>
          <td>评价疾病史、用药史及主观感受等相关因素</td>
        </tr>
        <tr>
          <td>生理指标</td>
          <td>评价体检及深度检测指标</td>
        </tr>
        <tr>
          <td>生活习惯</td>
          <td>评价饮食、运动、睡眠、吸烟及饮酒等相关因素</td>
        </tr>
        <tr>
          <td>外部环境</td>
          <td>评价工作环境及居住环境等相关因素</td>
        </tr>
      </table>
    </div>
    <div class="ending">
      <span>Restart  Retreat  Reward</span>
    </div>
  </div>
</template>
<script>
import Echart from '@/components/echarts'
import VernierCaliper from '@/components/vernier-caliper/vernier-caliper'
import { api } from '@/utils/api'
export default {
  name: 'health-curative-effect-report',
  components: { Echart, VernierCaliper },
  props: {
    reportSid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      txtColorMap: {
        '提高': '#8BC34A',
        '改善': '#8BC34A',

        '降低': '#FF9800',
        '未改善': '#FF9800',

        '部分改善': '#009688',
        '持平': '#009688',

        '新出现': '#E61212'
      },
      color: [],
      single_score: {},
      scores: [],
      health_problems: [],
      people_health_comparison: [],
      health_problem_comparison: [],
      physiological_index_comparison: [],
      conclusion: '',
      next_proposal: '',
      health_manager: '',
      evaluation_date: ''
    }
  },
  async mounted() {
    const rs = await api.get(`health_manage/get_evaluation_report_detail?e=${this.reportSid}`)
    const data = rs.msg
    // const data = {
    //   "scores":[
    //     {"check_item_name":"个人健康指数", "score": 85, "average":80,"threshold":[50,100],"desc": "高于同人群平均水平"},
    //     {"check_item_name":"遗传基因", "score": 80,"average":75, "threshold":[66,77],"desc": "高于同人群平均水平"},
    //     {"check_item_name":"身体状态", "score": 74, "average":62,"threshold":[69,72],"desc": "低于于同人群平均水平"},
    //     {"check_item_name":"生理指标", "score": 84, "average":75,"threshold":[60,70],"desc": "高于同人群平均水平"},
    //     {"check_item_name":"生活习惯", "score": 90, "average":75,"threshold":[64,68],"desc": "高于同人群平均水平"},
    //     {"check_item_name":"外部环境", "score": 88, "average":75,"threshold":[66,74],"desc": "高于同人群平均水平"}
    //   ],
    //  "health_problems": [
    //     {"health_problem_no":"TCHP-001","health_problem_name":"主食摄入过多","health_problem_desc": "主食摄入过多容易肥胖导致疾病"},
    //     {"health_problem_no":"TCHP-002","health_problem_name":"食用油摄入结构不合理","health_problem_desc": "食用油摄入不合理不合理"},
    //     {"health_problem_no":"TCHP-003","health_problem_name":"运动不足","health_problem_desc": "运动不足导致免疫力下降"},
    //     {"health_problem_no":"TCHP-004","health_problem_name":"睡眠不足","health_problem_desc": "睡眠不足导致注意力不集中"},
    //     {"health_problem_no":"TCHP-005","health_problem_name":"体重超标","health_problem_desc": "体重超标导致慢性疾病"},
    //     {"health_problem_no":"TCHP-006","health_problem_name":"血脂异常","health_problem_desc": "血脂异常导致疾病"}
    //   ],
    //  "people_health_comparison": [
    //     {"items":"遗传基因","before_manage": 78, "after_manage": 85, "trend": "提高"},
    //     {"items":"身体状态","before_manage": 78, "after_manage": 74, "trend": "降低"},
    //     {"items":"遗传基因","before_manage": 78, "after_manage": 84, "trend": "提高"},
    //     {"items":"遗传基因","before_manage": 78, "after_manage": 90, "trend": "提高"},
    //     {"items":"遗传基因","before_manage": 78, "after_manage": 88, "trend": "提高"}
    //   ],
    //  "health_problem_comparison": [
    //     {"items":"主食摄入过多","improve_situation":"部分改善"},
    //     {"items":"食用油摄入结构不合理","improve_situation":"未改善"},
    //     {"items":"运动不足","improve_situation":"改善"},
    //     {"items":"睡眠不足","improve_situation":"改善"},
    //     {"items":"体重超标","improve_situation":"部分改善"},
    //     {"items":"血脂异常","improve_situation":"新出现"},
    //   ],
    //   "physiological_index_comparison": [
    //     {"items":"BMI","before_manage": 78, "after_manage": 76, "improve_situation": "改善"},
    //     {"items":"总胆固醇","before_manage": 5.9, "after_manage": 4.7, "improve_situation": "改善"},
    //     {"items":"甘油三酯","before_manage": 2.1, "after_manage": 1.67, "improve_situation": "改善"},
    //     {"items":"空腹血糖","before_manage": 5.6, "after_manage": 5.5, "improve_situation": "持平"},
    //     {"items":"HCY","before_manage": 15.6, "after_manage": 13.2, "improve_situation": "改善"},
    //   ],
    //   "conclusion":"1. 减少精致（精米、精面）碳水，可以用糙米、薯类代替。晚餐尽可能减少主食的摄入。",
    //   "next_proposal":"下一步建议下一步建议下一步建议",
    //   "health_manager":"陈侨君",
    //   "evaluation_date":"2022-03-29"
    // }
    if (data.scores && data.scores.length > 0) {
      this.single_score = data.scores.splice(0, 1)[0]
      this.color = [
        [(this.single_score.average - this.single_score.threshold[0])/100, '#FF9802'],
        [(this.single_score.average + this.single_score.threshold[1])/100, '#029688'],
        [1, '#8BC34B']
      ]
      this.scores = data.scores.map(sc => {
        sc.color = [
          [(this.single_score.average - this.single_score.threshold[0])/100, '#FF9802'],
          [(this.single_score.average + this.single_score.threshold[1])/100, '#029688'],
          [1, '#8BC34B']
        ]
        return sc
      })
    }
    this.health_problems = data.health_problems || []
    this.people_health_comparison = data.people_health_comparison || []
    this.health_problem_comparison = data.health_problem_comparison || []
    this.physiological_index_comparison = data.physiological_index_comparison || []
    this.conclusion = data.conclusion
    this.next_proposal = data.next_proposal
    this.health_manager = data.health_manager
    this.evaluation_date = data.evaluation_date
  }
}
</script>
<style scoped lang="scss">
.health-evaluate-report {
  color: #707070;
  .header {
    background: #00B4ED;
    color: #ffffff;
    height: 60px;
    position: relative;
    display:flex;
    align-items:flex-end;
    padding: 0 0 8px 8px;
    img {
      position: absolute;
      right: 0;
      bottom: 4px;
      transform: translate(0, 50%);
    }
  }
  .main {
    padding: 32px;
    border-bottom: 1px dashed #B0B0B0;
    .welcome {
      font-size: 16px;
      line-height: 40px;
    }
    .score-row {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .flex1 { 
        flex: 1;
        margin-left: 16px;
      }
    }
    .score-legend {
      margin-top: 16px;
      display: flex;
      & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 28px;
        .score-legend-block {
          width: 20px;
          height: 14px;
          display: inline-block;
          margin-right: 4px;
        }
      }
    }
    .main-quest-title {
      margin: 32px 0;
      color: #00B4ED;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
    }
    .main-quest-block {
      margin-bottom: 16px;
      padding-left: 20px;
      line-height: 28px;
      .main-quest-block-title {
        position: relative;
        margin-bottom: 8px;
        &:before {
          content: '';
          position: absolute;
          background: #FF9802;
          top: 8px;
          left: -20px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }
    }
    .next-suggest {
      margin-bottom: 8px;
      line-height: 28px;
    }
    .sign {
      text-align: right;
      margin: 32px 0 16px;
    }
    .tip {
      text-align: center;
      margin: 64px 0 0;
    }
  }
  .part2 {
    padding: 32px;
    .tit {
      margin: 0 0 16px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
    .desc {
      line-height: 28px;
    }
  }
  .tb {
    margin-top: 16px;
    border-bottom: 1px solid #B0B0B0;
    width: 100%;
    th {
      border-bottom: 2px solid #B0B0B0;
      padding: 8px 0 8px 16px;
      text-align: left;
    }
    td {
      border-bottom: 1px dashed #B0B0B0;
      padding: 14px 0 14px 16px;
    }
    tr:last-child td {
      border: 0;
    }
  }
  .ending {
    margin: 32px 0 64px;
    background: #00B4ED;
    color: #00B4ED;
    text-align: center;
    height: 1px;
    span {
      background: #ffffff;
      transform: translate(0, -50%);
      display: inline-block;
      padding: 0 16px;
    }
  }
}
</style>