<template>
  <v-layout row wrap style="margin:0">
    <v-flex d-flex xs12>
      <v-card style="width:100%;margin-bottom:24px;">
        <QuestionnaireOnePage
          v-if="instance.template && instance.template.sid"
          :individualId="id"
          :characterTemplateId="instance.template.sid"
          :instanceId="sid"
          hasTitle
        />
      </v-card>
    </v-flex>
    <v-flex d-flex xs12 v-if="template_config.display_type==='M'">
      <combo-record :template_id="template_config.sid" action_type="edit"></combo-record>
    </v-flex>
  </v-layout>
</template>
<script>
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
import ComboRecord from "@/components/record/ComboRecord";
import {api} from "@/utils/api";

export default {
  name: "EditRecord",
  components: {
    QuestionnaireOnePage,
    ComboRecord
  },
  props: {
    id: {
      type: String
    },
    sid: {
      type: String
    }
  },
  data() {
    return {
      instance: {},
      template_config: {}
    };
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      // 获取实例信息
      this.instance = await api.get("template_instances/" + this.sid + "/?", null)
      //获取模版信息
      this.template_config = await api.get("character_templates/" + this.instance.template.sid + "/get_template_info/?", null)
    }
  }
};
</script>
