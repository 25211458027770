<template>
  <div class="report-card">
    <v-checkbox
      v-if="report_item.instance_count > 0"
      v-model="is_filter"
      @change="doFilter(report_item, is_filter)"
      style="margin:0;padding:0;"
      hide-details
    />
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <label v-on="on">{{report_item.short_name}}</label>
      </template>
      {{report_item.report_name}}
    </v-tooltip>
    <template v-if="report_item.instance_count > 0">[{{ report_item.instance_count }}]</template>
    <v-spacer></v-spacer>
    <router-link v-if="report_item.is_published && has_add_right" v-bind:to="{ name: '新增报告', params: { rid: report_item.sid }}" class="v-breadcrumbs__item" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="primary" v-on="on">mdi-plus-circle</v-icon>
        </template>
        <span>新增报告</span>
      </v-tooltip>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ReportCard",
  data() {
    return {
      is_filter: this.report_item.is_filter
    }
  },
  props: {
    report_item: {
      type: Object,
      required: true,
      default: () => null
    },
    org_info: {
      type: Object,
      required: true,
      default: () => []
    },
    customer_info: {
      type: Object,
      required: true,
      default: () => []
    },
  },
  computed: {
    has_add_right() {
      return this.$store.getters.getPermission('reportinstance', 'add')
    }
  },
  methods: {
    doFilter(value, is_filter) {
      if (is_filter) {
        this.$store.dispatch("addTemplateFilters", value);
      } else {
        this.$store.dispatch("deleteTemplateFilters", value);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.report-card {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #AFAFAF;
  padding: 16px 0;
}
</style>