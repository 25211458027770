<template>
  <v-layout row wrap style="margin:0">
    <v-flex d-flex xs12>
      <!-- <new-record-group-page :id="id" :cid="cid" v-if="template_config.page_navigation_type==='group'"></new-record-group-page>
      <new-record-one-page  :id="id" :cid="cid" v-else-if="template_config.page_navigation_type==='survey'"></new-record-one-page> -->
      <v-card style="width:100%;margin-bottom:24px;">
        <QuestionnaireOnePage
          v-if="template_config.page_navigation_type==='survey'"
          :individualId="id"
          :characterTemplateId="cid"
          hasTitle
        />
        <QuestionnairGroupPage
          v-else-if="template_config.page_navigation_type==='group'"
          :individualId="id"
          :characterTemplateId="cid"
          hasTitle
        />
      </v-card>
    </v-flex>
    <v-flex d-flex xs12 v-if="template_config.display_type==='M'">
      <combo-record :template_id="template_config.sid" action_type="add"></combo-record>
    </v-flex>
  </v-layout>
</template>
<script>

import NewRecordGroupPage from "@/components/record/NewRecordGroupPage";
import NewRecordOnePage from "@/components/record/NewRecordOnePage";
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
import QuestionnairGroupPage from '@/components/questionnaire/GroupPage.vue'
import ComboRecord from "@/components/record/ComboRecord";
import { api } from "@/utils/api";

export default {
  name: "NewRecord",
  components: {
    NewRecordGroupPage,
    NewRecordOnePage,
    ComboRecord,
    QuestionnaireOnePage,
    QuestionnairGroupPage
  },
  props: {
    id: {
      type: String
    },
    cid: {
      type: String
    }
  },
  data() {
    return {
      template_config: {},
    };
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      //获取模版信息
      this.template_config = await api.get("character_templates/" + this.cid + "/get_template_info/?", null)
    }
  }
}
</script>