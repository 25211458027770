var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"health-evaluate-report"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"welcome"},[_c('h4',[_vm._v("尊敬的张先生，您好！")]),_c('p',[_vm._v("您的健康管理已经进行了"+_vm._s(_vm.manage_plan_progress.complete_days)+"天，占总体进程约"+_vm._s(_vm.manage_plan_progress.percent_complete)+"，健康管理进程还剩"+_vm._s(_vm.manage_plan_progress.surplus_days)+"天，占总体进程约"+_vm._s(_vm.manage_plan_progress.percent_surplus))])]),_c('div',{staticClass:"process"},[_c('div',{staticClass:"used",style:({ width: _vm.manage_plan_progress.percent_complete })},[_vm._v(" "+_vm._s(_vm.manage_plan_progress.percent_complete)+" ")]),_c('div',{staticClass:"unused",style:({ width: _vm.manage_plan_progress.percent_surplus })},[_vm._v(" "+_vm._s(_vm.manage_plan_progress.percent_surplus)+" ")])]),_c('div',{staticClass:"main-quest-title"},[_vm._v("健康行动表现")]),_c('div',{staticClass:"next-suggest"},[_vm._v(" 基于您近期在健康保卫行动中的表现，总体评价： ")]),_c('div',{staticClass:"what-the-radios"},_vm._l((_vm.overall),function(item){return _c('div',{key:item.value},[_c('div',{class:`wtr-radio ${item.value == _vm.overall_performance?'checked':''}`}),_vm._v(_vm._s(item.label)+" ")])}),0),_c('div',{staticClass:"grid",attrs:{"border":"0","cellspacing":"0","cellpadding":"0"}},_vm._l((_vm.health_action_performance),function(item){return _c('tr',{key:item.items},[_c('td',[_vm._v("【"+_vm._s(item.items)+"】")]),_c('td',{staticStyle:{"width":"120px","flex":"unset"}},[_vm._v(_vm._s(item.situation))]),_c('td',[_vm._v(_vm._s(item.compared_with_last_time))])])}),0),(_vm.index_change.length > 0)?[_c('div',{staticClass:"main-quest-title"},[_vm._v("指标变化")]),_vm._l((_vm.index_change),function(item){return _c('div',{key:item.index_name,staticStyle:{"height":"200px"}},[_c('Echart',{attrs:{"options":{
          title: {
            left: 'center',
            text: item.index_name,
            textStyle: {
              color: '#707070'
            }
          },
          grid: { top: 32 },
          xAxis: {
            type: 'category',
            data: item.measure_date,
            axisTick: { show: false }
          },
          yAxis: {
            type: 'value',
            axisLine: { show: true },
            splitLine: { show: false },
            axisLabel: { show: false },
            max (value) { return value.max * 1.05 },
            min (value) { return value.min * 0.95 }
          },
          series: [
            {
              data: item.index_list,
              type: 'line',
              label: { show: true },
              symbol: 'circle',
              symbolSize: 7,
              lineStyle: { type: 'dashed', width: 1, color: 'black', opacity: 0.3 },
              itemStyle: { color: '#EF6C00' }
            }
          ]
        }}})],1)})]:_vm._e(),(_vm.Worthy_of_praise)?[_c('div',{staticClass:"main-quest-title t_green"},[_vm._v("您在以下方面做的较好，请继续保持")]),_c('div',{staticClass:"next-suggest",domProps:{"innerHTML":_vm._s(_vm.Worthy_of_praise)}})]:_vm._e(),(_vm.to_be_improved)?[_c('div',{staticClass:"main-quest-title t_orange"},[_vm._v("您在以下方面有待改进，请持续关注")]),_c('div',{staticClass:"next-suggest",domProps:{"innerHTML":_vm._s(_vm.to_be_improved)}})]:_vm._e(),(_vm.next_proposal)?[_c('div',{staticClass:"main-quest-title"},[_vm._v("下一步建议")]),_c('div',{staticClass:"next-suggest",domProps:{"innerHTML":_vm._s(_vm.next_proposal)}})]:_vm._e(),_c('div',{staticClass:"sign"},[_vm._v(" 健康管理师："+_vm._s(_vm.health_manager)+" ")]),_c('div',{staticClass:"sign"},[_vm._v(" 评价日期："+_vm._s(_vm.evaluation_date)+" ")]),_c('div',{staticClass:"tip"},[_vm._v(" 如有任何疑问，请及时联系您的健康管理师 ")])],2),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_vm._v(" 向内探索，重塑您的生命历程 "),_c('img',{attrs:{"src":require("@/assets/favicon-half.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ending"},[_c('span',[_vm._v("Restart Retreat Reward")])])
}]

export { render, staticRenderFns }