import request from "@/utils/request";

declare module 'vue/types/vue' {
    interface Vue {
        [key: string]: any,
    }
}

import Vue from 'vue'
import vuetify from "./plugins/vuetify";
import App from './App.vue'
// import './registerServiceWorker'
import router, {dynamic_menu, fixed_menu} from './router'
import store from './store'
import "./plugins/notifications";
import * as myFilters from "@/utils/filters";
import * as echarts from 'echarts';
import nf from "@/utils/notification";

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts

Object.keys(myFilters).forEach(key => {
    Vue.filter(key, myFilters[key]);
});


router.beforeEach((to, from, next) => {
    store.commit('stopAudio')
    if (!store.getters.token) {
        if (to.meta.free) {
            next()
        } else {
            next({path: '/login'})
        }
    } else {
        if (store.getters.permissionList.length === 0) {
            request.get('users/get_via_atlas_menu_resources/').then(result => {
                result[0].children['individual.view_individualbaseinfo'].push({
                    icon: '', name: '客户信息', order_no: 30, path: ':id'
                })

                try {
                    let index_child_mr = 0
                    for (let key in result) {
                        let child_mrs = result[index_child_mr]['children']
                        let child_dms = dynamic_menu[result[index_child_mr].path]['children']
                        let keep_child_dm = []
                        for (let child_mr_key in child_mrs) {
                            if (child_mrs.hasOwnProperty(child_mr_key)) {
                                for (let child_mr_item of child_mrs[child_mr_key]) {
                                    let index_dms_to_be_removed = 0
                                    let child_dm = {}
                                    for (child_dm of child_dms) {
                                        if (child_dm['path'] === child_mr_item['path']) {
                                            keep_child_dm.push(child_dm)
                                            break
                                        }
                                        index_dms_to_be_removed += 1
                                    }
                                }
                            }
                        }
                        dynamic_menu[result[index_child_mr].path]['children'] = keep_child_dm
                        fixed_menu[0].children.push(dynamic_menu[result[index_child_mr].path])
                        index_child_mr += 1
                    }
                    store.dispatch('setPermissionList', result).then(r=>{
                        // router.addRoutes(fixed_menu)
                        for (let element of fixed_menu) {
                            router.addRoute(element)
                        }
                    })
                } catch (e) {
                    console.error(e)
                }

            }).catch(err => {
                console.error(err)
                store.dispatch("logout");
                router.replace("/login");
            })
        }

        if (to.path !== '/login') {
            next()
        } else {
            next()
        }
    }
})

export default new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')


