<template>
  <iframe v-if="url" style="border:0;width:100%;height:99%;" :src="url"></iframe>
</template>
<script>
export default {
  name: 'WebReportFrameContain',
  props: {
    url: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>