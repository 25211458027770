<template>
  <v-layout row style="margin: 0;">
    <v-flex xs8 style="padding-right:8px;">
      <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
          <v-subheader style="padding:0">{{action.name}}</v-subheader>
        </v-card-title>
        <v-form style="width: 100%" class="pt-2 pl-6 pr-6" ref="form" lazy-validation v-on:submit.prevent="handleSubmit" v-if="['R', 'O'].includes(action.state)">
          <v-menu v-model="showDatePicker" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field clearable readonly label="记录日期" v-model="action.action_end_date" v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="action.action_end_date" scrollable locale="cn" @change="() => { showDatePicker = false }" />
          </v-menu>
        </v-form>
        <v-layout row wrap class="pl-8 pr-8" v-else>
          <v-text-field
            v-model="action.action_end_date"
            label="记录日期"
            readonly
          />
        </v-layout>
        <div v-for="detail in actionDetailList" :key="detail.sid">
          <div style="margin: 22px;padding: 8px;background:rgb(84 84 84 / 8%);border-radius: 3px;">
            {{detail.title}}
          </div>
          <QuestionnaireOnePage
            :individualId="individualId"
            :characterTemplateId="detail.templateId"
            :instanceId="detail.instance"
            :readonly="['T', 'L'].includes(action.state)"
            ref="questionnaire"
          />
        </div>
        <p style="text-align:center;padding-bottom:16px;">
          <v-btn color="primary" :loading="loading" @click="handleGenerateReport" v-if="action.type == 'E' && !['T', 'L'].includes(action.state)">
            生成评价报告
          </v-btn>
          &nbsp;
          <v-btn color="primary" :loading="loading" @click="showDialogPublish = true" v-if="action.type == 'E' && !['T', 'L'].includes(action.state) && reportId">
            发布
          </v-btn>
          &nbsp;
          <v-btn color="primary" :loading="loading" @click="() => handleSubmit()" v-if="action.type != 'E' && !['T', 'L'].includes(action.state)">
            保存
          </v-btn>
          <v-dialog v-model="showDialogPublish" persistent max-width="320">
            <v-card>
              <v-card-title class="text-subtitle-2">您确认要发布吗?</v-card-title>
              <v-card-text>发布后将无法修改，请您谨慎操作！</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click.native="showDialogPublish = false">取消</v-btn>
                <v-btn color="error" text @click.native="handlePublish">我想好了</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </p>
      </v-card>
    </v-flex>
    <v-flex xs4 style="padding-left:16px;">
      <EvaluateReportComp style="margin-bottom:24px;" />
      <MemorandumComp style="margin-bottom:24px;" />
      <ConsultationEvaluationComp style="margin-bottom:32px;" />
      <NutrientIntakeComp style="margin-bottom:32px;" />
      <ManagePlanOverviewnComp :planId="action.plan" v-if="action.type == 'B' && action.plan" />
    </v-flex>
  </v-layout>
</template>
<script>
import EvaluateReportComp from './side-card/EvaluateReport.vue'
import ManagePlanOverviewnComp from './side-card/ManagePlanOverview.vue'
import LastTypeActionComp from './side-card/LastTypeAction.vue'
import ConsultationEvaluationComp from './side-card/ConsultationEvaluation.vue'
import NutrientIntakeComp from './side-card/NutrientIntake.vue'
import MemorandumComp from './side-card/Memorandum.vue'
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
import { api } from '@/utils/api'
import nf from "@/utils/notification"
import { formatDate } from "@/utils/date"
export default {
  name: 'ManagePlanAction',
  components: { EvaluateReportComp, MemorandumComp, ManagePlanOverviewnComp, LastTypeActionComp, ConsultationEvaluationComp, NutrientIntakeComp, QuestionnaireOnePage },
  inject: ['individualId', 'instance', 'handleShowDrawer', 'handleDrawerClose'],
  data() {
    const { actionId } = this.$route.params
    return {
      actionId,
      customer: {},
      action: {},
      actionDetailList: [],
      relationIdList: [],
      characterTemplateId: null,
      requireRules: [v => !!v || '不能为空'],
      showDatePicker: false,
      loading: false,
      reportId: '',
      showDialogPublish: false
    }
  },
  async mounted() {
    this.customer = this.instance.customer
    try {
      const actionDetailList = await api.get(`health_manage/get_action_template/?a=${this.actionId}`)
      for(const detail of actionDetailList) {
        this.action = detail.action
        this.relationIdList.push(detail.sid)
        try {
          const { sid: template_id, short_name } = await api.post(`health_manage/get_template_id/`, { 'template_no': detail.template_no })
          detail.templateId = template_id
          detail.title = short_name
        } catch(e) {
          console.error(e)
          nf.showErrorMsg(`获取特征模板id失败`)
        }
      }
      this.action.action_end_date = formatDate(new Date())
      this.actionDetailList = actionDetailList

      if ('E' == this.action.type) {
        const rp = await api.get(`health_manage/get_action_evaluation_report_relation?a=${this.actionId}`)
        this.reportId = rp.msg
      }
    } catch(e) {
      console.error(e)
    }
  },
  methods: {
    async handleGenerateReport() {
      const instance_id_list = await this.handleSubmit(true)
      if (!instance_id_list) {
        return
      }
      this.loading = true
      this.handleDrawerClose()
      const params = [`s=${instance_id_list[0]}`]
      if (this.reportId) {
        params.push(`r=${this.reportId}`)
      }
      try {
        const { msg } = await api.get(`health_manage/generate_stage_evaluation_report/?${params.join('&')}`)
        this.reportId = msg
        this.handleShowDrawer('阶段评价报告', 'StageEvaluationReport', { reportSid: this.reportId })
      } catch(e) {
        nf.showErrorMsg(`生成阶段评价报告失败`)
        console.error(e)
      }
      this.loading = false
    },
    async handlePublish() {
      this.loading = true
      this.handleDrawerClose()
      try {
        await api.get(`/health_manage/release_evaluation_report/?e=${this.reportId}`)
        nf.showSuccessMsg(`发布成功`)
        this.$router.back(-1)
      } catch(e) {
        nf.showErrorMsg(`发布失败`)
        console.error(e)
      }
      this.loading = false
    },
    async handleSubmit(rt) {
      const form = this.$refs.form
      const questionnaire = this.$refs['questionnaire']
      let valid = true
      if (form && !form.validate()) {
        valid = false
      }
      for (const item of questionnaire) {
        if (!item.$refs.form.validate()) {
          valid = false
        }
      }
      if (!valid) {
        nf.showWarnMsg(`表单检验失败，请检查必填项`)
        return
      }
      this.loading = true
      const instance_id_list = []
      try {
        for (const item of questionnaire) {
          const instanceId = await item.doPostData()
          instance_id_list.push(instanceId)
        }
      } catch(e) {
        console.error(e)
        this.loading = false
        return
      }
      if (!['O', 'R'].includes(this.action.state)) {
        if (rt) {
          this.loading = false
          return instance_id_list
        } else {
          nf.showSuccessMsg(`保存成功`)
          this.$router.back(-1)
          return
        }
      }
      try {
        const data = { action_id: this.actionId, instance_id_list, relation_id_list: this.relationIdList }
        await api.post(`health_manage/complete_action/`, data)
        if (rt) {
          this.loading = false
          return instance_id_list
        } else {
          nf.showSuccessMsg(`保存活动数据成功`)
          this.$router.back(-1)
        }
      } catch(e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>