<template>
  <v-flex d-flex xs12 :md6="isText" :md4="isText" class="py-2 pl-8 pr-8">
    <v-layout row wrap v-if="isText">
      <v-text-field
        v-model="c.instance_value"
        :label="c.character_name"
        :suffix="`${range? range:''} ${c.character_unit || ''}`"
        :rules="c.rules"
        persistent-hint
        :hint="c.character_comments"
        :readonly="readonly"
      />
    </v-layout>
    <v-layout row wrap v-if="isLongText" style="position:relative;" @click="handleTextareaFocus" v-click-outside="handleTextareaBlur">
      <v-textarea
        outline
        auto-grow
        max-length="1000"
        :label="c.character_name"
        v-model="c.instance_value"
        :suffix="`${range? range:''} ${c.character_unit || ''}`"
        :rules="c.rules"
        persistent-hint
        :hint="c.character_comments"
        :readonly="readonly"
        rows="3"
      />
      <v-card
        v-if="!readonly"
        v-show="showLongTextTemplate"
        class="longtext-template"
        tile
      >
        <v-list-item v-for="item in templateList" :key="item.sid">
          <v-list-item-content style="cursor:pointer" @click="(e) => handleSetValue(e, item.longtext)">
            {{item.longtext}}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="() => handleDeleteLongText(item.sid)">
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
      <v-checkbox v-if="!readonly" class="save_template" v-model="c.save_template" label="保存为模板" />
    </v-layout>
    <v-layout row wrap v-if="isCheck">
      <v-flex d-flex xs12>
        <v-input :rules="c.rules" v-model="c.selected_values">
          <v-subheader style="padding: 24px 0px 0px 0px" class="text-body-2">
            {{c.character_name}}  {{range || ''}}
          </v-subheader>
        </v-input>
      </v-flex>
      <template v-for="character_detail in c.character_details" >
        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 12px 24px 10px; height: 48px" :key="character_detail.sid" v-if="!character_detail.is_disabled &&  (readonly ? c.selected_values.includes(character_detail.detail_value): true)">
          <v-layout row wrap>
            <template v-if="character_detail.is_other" >
              <v-flex d-flex xs12>
                <v-text-field
                  :label="character_detail.detail_name"
                  v-model="character_detail.instance_value"
                  :clearable="!readonly"
                  @change="setSelectValues(c, character_detail.detail_value)"
                  @click:clear="deleteSelectValues(c, character_detail.detail_value)"
                  :readonly="readonly"
                />
              </v-flex>
            </template>
            <template v-else-if="character_detail.is_exclusive">
              <v-flex d-flex xs12>
                <v-checkbox
                  v-model="c.selected_values"
                  :label="character_detail.detail_name"
                  :value="character_detail.detail_value"
                  color="primary"
                  @change="() => {
                    setExclusiveStatus(c, character_detail);
                    $forceUpdate();
                  }"
                  :readonly="readonly"
                />
              </v-flex>
            </template>
            <template v-else-if="!character_detail.is_disabled">
              <v-flex d-flex xs12>
                <v-checkbox
                  v-model="c.selected_values"
                  :value="character_detail.detail_value"
                  color="primary"
                  @change="() => {
                    isBundleChangeEvent(character_detail) && setVisibleTrigger(c, character_detail);
                    $forceUpdate();
                  }"
                  :readonly="readonly"
                >
                  <template slot="label">
                    {{character_detail.detail_name | getShortName}}
                    <v-tooltip
                      v-if="character_detail.tooltip"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" style="margin-left:4px;" small>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{character_detail.tooltip}}</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-layout row wrap v-if="isRadio">
      <v-flex d-flex xs12>
        <v-subheader style="padding: 24px 0px 0px 0px" class="text-body-2">
          {{c.character_name}}  {{range || ''}}
        </v-subheader>
      </v-flex>
      <v-radio-group v-model="c.instance_value" row style="padding: 0 0 0 0px" :rules="c.rules" :readonly="readonly">
        <template v-for="character_detail in c.character_details.filter(cd => readonly ? (c.instance_value == cd.detail_value): true)">
          <v-radio
            :key="character_detail.sid"
            class="mr-12"
            :value="character_detail.detail_value"
            color="primary"
            @change="() => (c.is_should_visible && isBundleChangeEvent(character_detail)) && setVisibleTrigger(c, character_detail)"
          >
            <template slot="label">
              {{character_detail.detail_name | getShortName}}
              <v-tooltip
                v-if="character_detail.tooltip"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" style="margin-left:4px;" small>
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{character_detail.tooltip}}</span>
              </v-tooltip>
            </template>
          </v-radio>
        </template>
      </v-radio-group>
    </v-layout>
  </v-flex>
</template>
<script>
import { isTextField, isCheckBox, isRadioButton, isLongTextField } from "@/utils/enumerations";
import { api } from '@/utils/api'
export default {
  name: "questionnaire-character",
  props: {
    c: {
      type: Object,
      default: () => ({})
    }
  },
  // inject from src\components\questionnaire\common_mixin.js:17
  inject: ['getControl', 'setVisibleTrigger', 'setExclusiveStatus', 'setSelectValues', 'deleteSelectValues', 'readonly'],
  data() {
    return {
      showLongTextTemplate: false,
      templateList: [],
      loaded: false
    }
  },
  computed: {
    range() {
      if (this.c.range_relation) {
        const relation =this.c.range_relation[0]
        if (relation) {
          return `${relation.range_min}-${relation.range_max}`
        }
      }
    },
    control() {
      return this.getControl ? this.getControl(): []
    },
    isText() {
      return isTextField(this.c.character_input_type)
    },
    isLongText() {
      return isLongTextField(this.c.character_input_type)
    },
    isCheck() {
      return isCheckBox(this.c.character_input_type)
    },
    isRadio() {
      return isRadioButton(this.c.character_input_type)
    },
  },
  methods: {
    isBundleChangeEvent(in_character_detail) {
      for (let rule of this.control) {
        if (rule.character_detail === in_character_detail.sid) {
          return true;
        }
      }
      return false;
    },
    async handleTextareaFocus() {
      if (this.loaded) {
        if (this.templateList.length > 0) {
          this.showLongTextTemplate = true
        }
      } else {
        this.loaded = true
        const templateList = await api.get(`characters/get_text_template/?cid=${this.c.sid}`)
        if (templateList.length > 0) {
          this.templateList = templateList
          this.showLongTextTemplate = true
        }
      }
    },
    handleTextareaBlur() {
      this.showLongTextTemplate = false
    },
    handleSetValue(e, value) {
      e.stopPropagation()
      this.$set(this.c, 'instance_value', value)
      this.handleTextareaBlur()
      this.$forceUpdate()
    },
    async handleDeleteLongText(sid) {
      try {
        await api.post(`characters/delete_text_template/`, { id: sid })
        const index = this.templateList.findIndex(t => t.sid == sid)
        this.templateList.splice(index, 1)
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.longtext-template {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  top: 87%;
  left: 0;
  z-index: 1;
}
.save_template {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>