<template>
  <v-layout row wrap style="margin:0">
    <v-flex d-flex xs12 sm12 md12 v-if="instance.internal_status === 'INIT'">
      <modify-report :id="id" :sid="sid"></modify-report>
    </v-flex>
    <v-flex d-flex xs12 sm12 md12 v-if="instance.internal_status === 'RRDA' && !this.$store.getters.showReviewHistory">
      <modify-report :id="id" :sid="sid"></modify-report>
    </v-flex>
    <v-flex d-flex xs12 sm12 md9 v-if="instance.internal_status === 'RRDA' && this.$store.getters.showReviewHistory">
      <modify-report :id="id" :sid="sid"></modify-report>
    </v-flex>
    <v-flex d-flex xs12 sm12 md3 v-if="instance.internal_status === 'RRDA' && this.$store.getters.showReviewHistory">
      <info-review-report></info-review-report>
    </v-flex>
  </v-layout>
</template>
<script>
import ModifyReport from "@/components/record/EditReport";
import InfoReviewReport from "@/components/record/ListReviewReport";
import { api } from "@/utils/api";

export default {
  name: "EditReport",
  props: {
    id: {
      type: String
    },
    sid: {
      type: String
    }
  },
  data() {
    return {
      instance: {}
    };
  },
  components: {
    ModifyReport,
    InfoReviewReport
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      this.instance = await api.get("report_instances/" + this.sid + "/?", null)
    }
  }
};
</script>
