<template>
  <v-card class="customers-v2-health-tag">
    <v-card-title class="card-title dflex">
      <div class="flex1">健康状态</div>
      <v-btn color="primary" fab text small @click="refreshHealthStatus" :loading="refreshHealthStatusLoading">
        <v-icon color="primary">mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-chip class="ml-4 mb-1" small color="orange" text-color="white" v-for="item in healthStatus" :key="item.tagName" @click="() => handleOpenHealthStatusDetail(item)">
      {{item.tagName}}
    </v-chip>
    <v-dialog v-model="showHealthStatusDetail" width="800">
      <v-card>
        <v-card-title class="text-h5">{{healthStatusDetail.tagName}}</v-card-title>
        <v-card-text v-if="healthStatusDetail.isRisk">
          <v-chip class="mr-4 mt-4" color="red" outlined v-for="item in healthStatusDetail.list" :key="item.sid">
            {{item.instance_value}}
          </v-chip>
        </v-card-text>
        <v-card-text v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">指标</th>
                  <th class="text-center">结果</th>
                  <th class="text-center">正常参考值</th>
                  <th class="text-center">检测时间</th>
                </tr>
              </thead>
              <tbody>
                <tr
                    v-for="item in healthStatusDetail.list"
                    :key="item.sid"
                >
                  <td class="text-center">{{ item.character.character_name }}</td>
                  <td class="text-center">{{ item.instance_value }}<label v-html="item.trend"></label></td>
                  <td class="text-center">{{ item.normal_range }}</td>
                  <td class="text-center">
                    <label>{{ item.happen_date }}</label>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { api } from "@/utils/api"
import nf from "@/utils/notification"
import { getHealthStatusData } from '@/components/customer/data.js'
export default {
  name: 'customers-v2-health-tag',
  data() {
    return {
      healthStatus: [],
      showHealthStatusDetail: false,
      healthStatusDetail: {},
      refreshHealthStatusLoading: false
    }
  },
  mounted() {
    this.getHealthStatus()
  },
  methods: {
    async getHealthStatus() {
      this.healthStatus = await getHealthStatusData(this.$route.params.id)
    },
    async refreshHealthStatus() {
      this.refreshHealthStatusLoading = true
      try {
        await api.get(`individuals/${this.$route.params.id}/generate_health_status/?`)
        this.getHealthStatus()
      } catch(e) {
        console.error(e)
        nf.showErrorMsg("生成客户健康状态失败！")
      }
      this.refreshHealthStatusLoading = false
    },
    handleOpenHealthStatusDetail(item) {
      this.healthStatusDetail = item
      this.showHealthStatusDetail = true
      this.$forceUpdate()
    },
    handleToDataRecord(instanceId) {
      this.$router.push({ path: `/customer/${this.$route.params.id}/${instanceId}/viewrecord` })
    }
  }
}
</script>