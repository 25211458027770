<template>
  <router-view />
</template>
<script>
export default {
  name: "customers-v2-health-router-home",
  data() {
    return {}
  }
}
</script>