import {ActionContext} from "vuex";

export interface State {
  template_filters: any[],
  resetFilterFlag: boolean
}

const state: State = {
  template_filters: [],
  resetFilterFlag: false
}

const getters = {
  getTemplateFilters: (state: State) => state.template_filters,
  getResetFilterFlag: (state: State) => state.resetFilterFlag
};

const mutations = {
  addTemplateFilters(state: State, value: any) {
    state.template_filters.push(value)
  },
  deleteTemplateFilters(state: State, value: any) {
    let items = []
    let index = 0
    for (let item of state.template_filters) {
      if (item.sid != value.sid) {
        items.push(item)
      }
    }
    state.template_filters = items
  },
  clearTemplateFilters(state: State) {
    state.template_filters = []
  },
  setResetFilterFlag(state: State) {
    state.resetFilterFlag = !state.resetFilterFlag
  }
};

const actions = {
  addTemplateFilters(context: ActionContext<State, any>, result: any) {
    context.commit('addTemplateFilters', result)
  },
  deleteTemplateFilters(context: ActionContext<State, any>, result: any) {
    context.commit('deleteTemplateFilters', result)
  },
  clearTemplateFilters(context: ActionContext<State, any>, result: any) {
    context.commit('clearTemplateFilters', result)
  },
  setResetFilterFlag(context: ActionContext<State, any>, result: any) {
    context.commit('setResetFilterFlag', result)
  },
};

const filter = {
  state,
  mutations,
  getters,
  actions
};
export default filter;