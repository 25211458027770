import request from "@/utils/request";
import nf from "@/utils/notification"

const api = {
  async get(url: string, options: any) {
    try {
      let res = await request.get(url, options);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  },
  async post(url: string, data: any): Promise<any> {
    try {
      let res = await request.post(url, data);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      // nf.showErrorMsg(err);
      // console.log(err);
      nf.showErrorMsg(err);
    }
  },

  async put(url: string, data: any) {
    try {
      let res = await request.put(url, data);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  },

  async delete(url: string) {
    try {
      let res = await request.delete(url);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  }
};
export { api };