<template>
    <v-card>
      <v-card-title class="body-2 font-weight-medium" style="padding-bottom: 0px" v-if="get_perm"> 
        <v-subheader>审核</v-subheader>
        <v-spacer></v-spacer>
        <v-tooltip bottom><template v-slot:activator="{ on }"><v-icon color="primary" v-on="on" @click.stop="doSubmit">mdi-content-save</v-icon></template><span>提交</span></v-tooltip>
      </v-card-title>
      <v-card-text style="padding: 0px 32px" v-if="get_perm">
        <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
          <v-radio-group v-model="review_content.reviewed_opinion" row :rules="opinion_rules" style="height: 48px">
            <v-radio label="同意" value="A" color="primary" style="height: 32px"></v-radio>
            <v-radio label="不同意" value="DA" color="primary" style="height: 32px"></v-radio>
          </v-radio-group>
          <v-textarea
            v-model="review_content.reviewed_comments"
            label="描述"
            rows="1"
            auto-grow
            clearable
            :rules="getCommentsRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-text>
        <v-card-title  class="body-2 font-weight-medium" style="padding: 0px;"><v-subheader>审核历史</v-subheader></v-card-title>
        <v-divider></v-divider>
        <v-data-iterator
          :items="items"
          hide-default-footer
          row
          wrap
        >
          <v-flex xs12 slot="item" slot-scope="props">
          <v-card flat>
            <v-card-text>
              <v-layout row wrap>
                <v-flex d-flex xs3 sm2 md4>
                  <span class="text-caption">审核时间: </span>
                </v-flex>
                <v-flex d-flex xs9 sm4 md8>
                  <span class="text-caption">{{ props.item.created_time | formatTimeShort}}</span>
                </v-flex>
                <v-flex d-flex xs3 sm2 md4>
                  <span class="text-caption">审核意见: </span>
                </v-flex>
                <v-flex d-flex xs9 sm4 md8>
                  <span class="text-caption">{{ props.item.reviewed_opinion | getDescForOpinion}}</span>
                </v-flex>
                <v-flex d-flex xs3 sm2 md4>
                  <span class="text-caption">审核人: </span>
                </v-flex>
                <v-flex d-flex xs9 sm4 md8>
                  <span class="text-caption">{{ props.item.created_user.first_name }}</span>
                </v-flex>
                <v-flex d-flex xs3 sm2 md4>
                  <span class="text-caption">审核备注: </span>
                </v-flex>
                <v-flex d-flex xs9 sm4 md8>
                  <v-dialog v-model="props.item.dialog" scrollable max-width="500px" align-center>
                    <template v-slot:activator="{ on }">
                    <span class="text-caption" v-on="on">{{ props.item.reviewed_comments | getLongWordComments}}</span></template>
                    <v-card>
                      <v-card-title style="padding: 0 0px">
                        <v-subheader>审核备注</v-subheader>
                      </v-card-title>
                      <v-divider light></v-divider>
                      <v-card-text>
                        {{ props.item.reviewed_comments }}
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-2" flat @click.native="props.item.dialog = false">关闭</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          </v-flex>
          <template slot="no-data">
            <p class="text-xs-center grey--text"></p>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "ReportReviewChainLists",
  data() {
    return {
      items: [],
      dialog: false,
      dialog_review: false,
      valid: false,
      loading: false,
      records: [],
      nextUrl: "",
      totalCount: 0,
      instance: {},
      opinion_rules: [v => !!v || "不能为空"],
      record_fields: [
        {
          value: "created_time",
          text: "审核时间",
          sortable: true,
          breakpoints: []
        },
        {
          value: "reviewed_opinion",
          text: "意见",
          sortable: true,
          breakpoints: []
        },
        {
          value: "created_user",
          text: "审核人",
          sortable: true,
          breakpoints: []
        },
        {
          value: "reviewed_comments",
          text: "描述",
          sortable: false,
          breakpoints: []
        }
      ],
      review_content: {
        reviewed_opinion: "",
        reviewed_comments: ""
      }
    };
  },
  computed: {
    getFields() {
      let headers = [];
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    },
    get_perm() {
      return this.instance.internal_status === 'RRR';

    },
    getCommentsRules() {
      if (this.review_content.reviewed_opinion === 'DA') {
          return [v => !!v || "不能为空"]
        } else {
          return []
        }
    },
    getStatusDesc() {
      return function(value) {
        if (value === "INIT") {
          return "已录入";
        } else if (value === "DRR") {
          return "审核中";
        } else if (value === "DRA") {
          return "审核通过";
        } else if (value === "DRDA") {
          return "审核不通过";
        } else {
          return "状态未知";
        }
      }
    }
  },
  mounted() {
    this.doLoadData()
    
  },
  methods: {
    async doLoadData() {
      // 获取实例信息
      this.loading = true;
      this.instance = await api.get("report_instances/" + this.$route.params.sid + "/?", null)
      this.items = await api.get("report_instance_review_chains/?instance=" + this.instance.sid, null)
      for (let item of this.items) {
        item.dialog = false
      }
      this.loading = false;
    },
    async doSubmit() {
      if (this.$refs.form.validate()) {
        this.review_content.instance = this.$route.params.sid
        await api.post("report_instance_review_chains/", this.review_content)
        nf.showSuccessMsg("数据保存成功！");
        this.$router.back(-1)
      }
    }
  }
};
</script>
