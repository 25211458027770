import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Customer from "@/views/customers/Customer.vue"
import NewCustomer from "@/views/customers/NewCustomer.vue"
import EditCustomer from "@/views/customers/EditCustomer.vue"
import NewReport from "@/views/reports/NewReport.vue"
import ViewReport from "@/views/reports/ViewReport.vue"
import EditReport from "@/views/reports/EditReport.vue"
import ManagePlanList from "@/views/health/manage-plan/ManagePlanList.vue"
import ManagePlanDetail from "@/views/health/manage-plan/ManagePlanDetail.vue"
import ManagePlanActionList from "@/views/health/manage-plan/ActionList.vue"
import ManagePlanActionDetail from "@/views/health/manage-plan/ActionDetail.vue"
import ManagePlanHealthEvaluate from "@/views/health/manage-plan/HealthEvaluate.vue"

import NewRecord from "@/views/records/NewRecord.vue"
import ViewRecord from "@/views/records/ViewRecord.vue"
import EditRecord from "@/views/records/EditRecord.vue"
import Container from '@/views/Container.vue'

import WorkTracking from '@/views/management/tracking/WorkTracking.vue'
import DataTracking from '@/views/management/tracking/DataTracking.vue'

import CustomerDashboardV2 from "@/views/customers-v2/customers-v2.vue"
import CustomerV2Character from "@/views/customers-v2/character/router-home.vue"
import CustomerV2CharacterIndex from "@/views/customers-v2/character/index.vue"
import CustomerV2Report from "@/views/customers-v2/report/router-home.vue"
import CustomerV2ReportIndex from "@/views/customers-v2/report/index.vue"
import CustomerV2Health from "@/views/customers-v2/health/router-home.vue"
import CustomerV2HealthIndex from "@/views/customers-v2/health/index.vue"
import CustomerV2Suggest from "@/views/customers-v2/suggest/router-home.vue"
import CustomerV2SuggestIndex from "@/views/customers-v2/suggest/index.vue"
import CustomerV2Assessment from "@/views/customers-v2/assessment/router-home.vue"
import CustomerV2AssessmentIndex from "@/views/customers-v2/assessment/index.vue"

import RPTMPBHV1 from "@/views/web-report/RPT-MPBH-V1.vue"
import auth from "@/utils/auth";

function requireAuth(to: any, from: any, next: any) {
    // next();
    try {
        let result = auth.loggedIn()

        if (!result) {
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } catch (error) {
        console.warn('warning: ')
        console.warn(error)
    }
}

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "Login",
        meta: { free: true },
        component: Login
    }, {
        path: "/RPT-MPBH-V1/:token/:instanceid",
        props: true,
        name: "RPT-MPBH-V1",
        meta: { free: true },
        component: RPTMPBHV1
    }
];

export const fixed_menu = [{
    path: "/",
    name: "container",
    component: Container,
    redirect: "/customer",
    children: [
        {
            path: "",
            name: "首页",
            component: Home,
            meta: {keepAlive: true},
            beforeEnter: requireAuth
        },
        // {
        //     path: "/about",
        //     name: "关于",
        //     component: () =>
        //         import("../views/About.vue")
        // },
        // {
        //     path: "/echarts",
        //     name: "图表",
        //     component: () =>
        //       import("../views/charts/Echarts.vue")
        // },
        // {
        //     path: "/sunburst",
        //     name: "心脑风险筛查关系图",
        //     component: () =>
        //       import("../views/charts/Sunburst.vue")
        // },
        // {
        //     path: "/sankey",
        //     name: "桑基图",
        //     component: () =>
        //       import("../views/charts/Sankey.vue")
        // },
        // {
        //     path: "/dmap",
        //     name: "血糖动态关系图",
        //     component: () =>
        //       import("../views/charts/Dmap.vue")
        // },
        // {
        //     path: "/ridaus",
        //     name: "心脑健康地图",
        //     component: () =>
        //       import("../views/charts/Ridaus.vue")
        // }
    ]
}]
export const dynamic_menu = {
    '/customer':{
        path: '/customer',
        name: '客户管理',
        redirect: '/customer/list',
        component: {
            render(c: any) {
                return c('router-view')
            }
        },
        children: [{
            path: 'list',
            name: '客户列表',
            component: Customer,
            meta: {keepAlive: true},
            beforeEnter: requireAuth
        }, {
            path: 'newcustomer',
            name: '新增客户信息',
            component: NewCustomer,
            beforeEnter: requireAuth
        }, {
            path: ':id/editcustomer',
            props: true,
            name: '编辑客户信息',
            component: EditCustomer,
            beforeEnter: requireAuth
        }, {
            path: ':id',
            props: true,
            name: '客户信息',
            component: CustomerDashboardV2,
            beforeEnter: requireAuth,
            redirect: { name: '指标管理' },
            children: [{
                path: 'character',
                props: true,
                name: '指标管理',
                component: CustomerV2Character,
                beforeEnter: requireAuth,
                redirect: { name: '指标数据' },
                children: [{
                    path: 'home',
                    props: true,
                    name: '指标数据',
                    component: CustomerV2CharacterIndex,
                    beforeEnter: requireAuth
                }, {
                    path: ':cid/new',
                    props: true,
                    name: '新增数据',
                    component: NewRecord,
                    beforeEnter: requireAuth
                }, {
                    path: ':sid/view',
                    props: true,
                    name: '查看数据',
                    component: ViewRecord,
                    beforeEnter: requireAuth
                }, {
                    path: ':sid/edit',
                    props: true,
                    name: '编辑数据',
                    component: EditRecord,
                    beforeEnter: requireAuth
                }]
            }, {
                path: 'report',
                props: true,
                name: '报告管理',
                component: CustomerV2Report,
                beforeEnter: requireAuth,
                redirect: { name: '报告数据' },
                children: [{
                    path: 'index',
                    props: true,
                    name: '报告数据',
                    component: CustomerV2ReportIndex,
                    beforeEnter: requireAuth
                }, {
                    path: ':rid/new',
                    props: true,
                    name: '新增报告',
                    component: NewReport,
                    beforeEnter: requireAuth
                }, {
                    path: ':sid/view',
                    props: true,
                    name: '查看报告',
                    component: ViewReport,
                    beforeEnter: requireAuth
                }, {
                    path: ':sid/edit',
                    props: true,
                    name: '编辑报告',
                    component: EditReport,
                    beforeEnter: requireAuth
                }]
            }, {
                path: 'health',
                props: true,
                name: '健康管理',
                component: CustomerV2Health,
                beforeEnter: requireAuth,
                redirect: { name: '管理日历' },
                children: [{
                    path: 'index',
                    props: true,
                    name: '管理日历',
                    component: CustomerV2HealthIndex,
                    beforeEnter: requireAuth
                }, {
                    path: 'health_manage_plan',
                    props: true,
                    name: '管理计划',
                    component: ManagePlanList,
                    beforeEnter: requireAuth
                }, {
                    path: ':planId/health_manage_plan_detail',
                    props: true,
                    name: '管理计划详情',
                    component: ManagePlanDetail,
                    beforeEnter: requireAuth
                }, {
                    path: ':planId/action_list',
                    props: true,
                    name: '管理计划活动列表',
                    component: ManagePlanActionList,
                    beforeEnter: requireAuth
                }, {
                    path: ':actionId/health_manage_plan_action_detail',
                    props: true,
                    name: '活动填写详情',
                    component: ManagePlanActionDetail,
                    beforeEnter: requireAuth
                }, {
                    path: ':planId/:actionId/health_evaluate',
                    props: true,
                    name: '健康评价',
                    component: ManagePlanHealthEvaluate,
                    beforeEnter: requireAuth
                }]
            }, {
                path: 'suggest',
                props: true,
                name: '干预建议',
                component: CustomerV2Suggest,
                beforeEnter: requireAuth,
                redirect: { name: '饮食建议' },
                children: [{
                    path: 'index',
                    props: true,
                    name: '饮食建议',
                    component: CustomerV2SuggestIndex,
                    beforeEnter: requireAuth
                }]
            }, {
                path: 'assessment',
                props: true,
                name: '健康评估',
                component: CustomerV2Assessment,
                beforeEnter: requireAuth,
                redirect: { name: '健康评估详情' },
                children: [{
                    path: 'index',
                    props: true,
                    name: '健康评估详情',
                    component: CustomerV2AssessmentIndex,
                    beforeEnter: requireAuth
                }]
            }]
        }]
    },
    '/work_track':{
        path: '/work_track',
        props: true,
        name: '工作跟踪',
        component: WorkTracking,
        meta: {keepAlive: true},
        beforeEnter: requireAuth
    },
    '/data_track':{
        path: '/data_track',
        props: true,
        name: '数据跟踪',
        component: DataTracking,
        meta: {keepAlive: true},
        beforeEnter: requireAuth
    }

}

const router = new VueRouter({
    mode: 'hash',
    linkActiveClass: 'open active',
    routes
})

router.beforeEach((to, from, next) => {
    try {
        document.getElementsByTagName('html')[0].scrollTop = 0
    } catch(e) {
        console.error(e)
    }
    next()
})

export default router
