<template>
  <add-report :id="id" :rid="rid"></add-report>
</template>
<script>
import AddReport from "@/components/record/AddReport";

export default {
  name: "NewReport",
  props: {
    id: {
      type: String
    },
    rid: {
      type: String
    }
  },
  data() {
    return {};
  },
  components: {
    AddReport
  }
};
</script>
