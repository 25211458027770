<template>
  <v-card class="action-list">
    <v-btn v-if="has_action_add_right" fab text small color="grey darken-2" class="newbtn" @click="handleShowCreateAction">
      <v-icon color="primary">mdi-plus-circle</v-icon>
    </v-btn>
    <v-card-text>
      <v-data-table
        :headers="[
          { text: '活动名称', value: 'name' },
          { text: '活动开始日期', value: 'start_date', align: 'center' },
          { text: '活动结束日期', value: 'end_date', align: 'center' },
          { text: '客户方便时间', value: 'time_range', align: 'center' },
          { text: '活动类型', value: 'type', align: 'center' },
          { text: '状态', value: 'state', align: 'center' },
          { text: '', value: 'k', align: 'center' }
        ]"
        :items="data"
        :items-per-page="10"
        :footer-props="{ itemsPerPageText : '每页行数：', itemsPerPageOptions: [5, 10, 20, 40]}"
        disable-sort
      >
        <template v-slot:item.name="{ item }">
          <a @click="() => handleToActionDetail(item)">{{item.name}}</a>
        </template>
        <template v-slot:item.type="{ item }">
          {{ACTION_TYPE_NAME_MAP[item.type]}}
        </template>
        <template v-slot:item.state="{ item }">
          {{STATE_MAP[item.state]}}
        </template>
        <template v-slot:item.k="{ item }">
          <v-btn :disabled="item.disabled" class="ma-2" color="primary" v-if="['C'].includes(item.state) && ['B', 'I'].includes(item.type) && has_action_change_right" @click="() => handleFillEvaluate(item)">健康评价</v-btn>
          <v-btn class="ma-2" color="error" v-if="['R', 'O'].includes(item.state) && has_action_delete_right" @click="() => handleShowDeleteWarn(item)">删除</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="showCreateAction" width="600">
      <CreateActionView
        v-if="showCreateAction"
        :defaultData="tempAction"
        @on-close="load"
      />
    </v-dialog>
    <v-dialog v-model="showDialogDelete" persistent max-width="320">
      <v-card>
        <v-card-title class="text-subtitle-2">您确认要删除该记录吗?</v-card-title>
        <v-card-text>删除后该记录将无法还原，请您谨慎操作！</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="showDialogDelete = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleDeleteAction">我想好了，删</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
import nf from "@/utils/notification";
import { formatDate } from "@/utils/date"
import CreateActionView from '@/views/health/manage-plan/CreateAction.vue'
const ACTION_TYPE_NAME_MAP = {
  B: '基线评价',
  F: '阶段随访',
  E: '阶段评价',
  I: '最终评价',
  H: '健康宣教',
  O: '其他活动'
}
const STATE_MAP = {
  R: '未开始',
  E: '执行中',
  C: '完成',
  O: '超时',
  T: '终止',
  L: '已发布'
}
export default {
  name: 'ManagePlanList',
  components: { CreateActionView },
  inject: ['individualId', 'instance'],
  data() {
    const { id, planId } = this.$route.params
    return {
      ACTION_TYPE_NAME_MAP,
      STATE_MAP,
      formatDate,
      planId,
      data: [],
      showCreateAction: false,
      showDialogDelete: false,
    }
  },
  computed: {
    has_action_add_right() {
      return this.$store.getters.getPermission('manageplanaction', 'add')
    },
    has_action_change_right() {
      return this.$store.getters.getPermission('manageplanaction', 'change')
    },
    has_action_delete_right() {
      return this.$store.getters.getPermission('manageplanaction', 'delete')
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.showCreateAction = false
      try {
        const data = await api.post(`health_manage/get_action_list/`, { individual_id: this.individualId, plan_id: this.planId })
        const now = Date.parse(formatDate(Date.now()))
        data.map(d => {
          const start = Date.parse(d.start_date)
          if (start > now) {
            d.disabled = true
          }
        })
        this.data = data.sort((a, b) => Date.parse(a.start_date) - Date.parse(b.start_date))
      } catch(e) {
        console.error(e)
        nf.showErrorMsg(`获取活动列表失败`)
      }
    },
    handleFillEvaluate(action) {
      this.$router.push({ name: '健康评价', params: { id: this.individualId, planId: this.planId, actionId: action.sid }})
    },
    handleToActionDetail(action) {
      if (action.disabled) {
        nf.showWarnMsg(`未到活动开始日期`)
        return
      }
      this.$router.push({ name: '活动填写详情', params: { id: this.individualId, actionId: action.sid }})
    },
    handleShowCreateAction() {
      this.tempAction = {
        plan_id: this.planId
      }
      this.showCreateAction = true
    },
    handleShowDeleteWarn(action) {
      this.tempAction = action
      this.showDialogDelete = true
    },
    async handleDeleteAction() {
      const { tempAction } = this
      try {
        await api.post(`health_manage/delete_action/`, { action_id: tempAction.sid })
        nf.showSuccessMsg(`删除活动成功`)
        this.load()
        this.showDialogDelete = false
      } catch(e) {
        nf.showErrorMsg(`删除活动失败`)
        console.error(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.action-list {
  position: relative;
  .newbtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>