<template>
  <v-card style="width: 100%">
    <v-subheader>
      管理计划
    </v-subheader>
    <v-layout row wrap class="pl-8 pr-8">
      <v-text-field
        v-model="plan.plan_start_date"
        label="计划开始日期"
        readonly
      />
      <v-spacer />
      <v-text-field
        v-model="plan.plan_end_date"
        label="计划结束日期"
        readonly
      />
    </v-layout>
    <CharacterComp
      v-for="c in characters"
      :key="c.sid"
      :c="c"
    />
  </v-card>
</template>
<script>
import CharacterComp from '@/components/questionnaire/Character'
import { api } from "@/utils/api"
const DISPLAY_NO = ['C-HM-P-013', 'C-HM-P-001', '']
export default {
  name: "ManagePlanOverview",
  components: { CharacterComp },
  props: {
    planId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      plan: {},
      characters: []
    }
  },
  provide() {
    return {
      getControl: () => {},
      setVisibleTrigger: () => {},
      setExclusiveStatus: () => {},
      setSelectValues: () => {},
      deleteSelectValues: () => {},
      readonly: true
    }
  },
  async mounted() {
    const { sid: template_id } = await api.post(`health_manage/get_template_id/`, { 'template_no': 'CT-HMP-001' })
    const template_config = await api.get(`character_templates/${template_id}/?`)
    const plan = await api.get(`health_manage/get_plan_detail?p=${this.planId}`)
    const instance = await api.get(`template_instances/${plan.instance}/?`)
    const characters = template_config.character_templates.filter(c => DISPLAY_NO.includes(c.character.character_no)).map(c => c.character)
    characters.map(c => {
      c.selected_values = []
      const instance_list = instance.template_instances.filter(i => c.sid == i.character)
      instance_list.map(instance => {
        c.instance_value = instance.instance_value
        if (instance.detail_value) {
          c.selected_values.push(Number(instance.detail_value) || instance.detail_value)
        }
      })
    })
    this.plan = plan
    this.characters = characters
  }
}
</script>