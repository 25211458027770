<template>
  <v-card style="width: 100%">
    <v-subheader>
      健康备忘录
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon color="primary" v-on="on" @click="handleSave">
            mdi-content-save-outline
          </v-icon>
        </template>
        <span>保存</span>
      </v-tooltip>
    </v-subheader>
    <QuestionnaireOnePage
      :individualId="individualId"
      :characterTemplateId="templateId"
      ref="questionnaire"
    />
  </v-card>
</template>
<script>
import nf from "@/utils/notification"
import { api } from "@/utils/api"
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
export default {
  name: "Memorandum",
  components: { QuestionnaireOnePage },
  inject: ['individualId', 'instance'],
  data() {
    return {
      templateId: ''
    }
  },
  async mounted() {
    const { sid } = await api.post(`health_manage/get_template_id/`, { 'template_no': 'CT-HEA-001' })
    this.templateId = sid
  },
  methods: {
    async handleSave() {
      const questionnaire = this.$refs['questionnaire']
      if (!questionnaire || !questionnaire.$refs.form.validate()) {
        return
      }
      try {
        await questionnaire.doPostData()
        nf.showSuccessMsg('保存成功')
      } catch(e) {
        console.error(e)
      }
    }
  }
}
</script>