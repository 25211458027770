<template>
  <v-card style="width: 100%">
    <v-subheader>
      问诊随访数据
    </v-subheader>
    <v-radio-group v-model="datatype" row style="margin:0 14px">
      <v-radio label="问诊评估" value="0" />
      <v-radio label="随访追踪" value="1" />
    </v-radio-group>
    <v-data-table
      :headers="[
        { text: '模板名称', value: 'instance_name' },
        { text: '发生日期', value: 'happen_date', align: 'center' },
        { text: '录入人', value: 'doctor_name', align: 'center' }
      ]"
      :items="tableData"
      hide-default-footer
    >
      <template v-slot:item.instance_name="{ item }">
        <!-- <router-link v-bind:to="{ name: '查看数据', params: { id: individualId, sid: item.sid }}" class="v-breadcrumbs__item" v-if="item.can_view_detail">
          {{ item.instance_name }}
        </router-link>
        <span v-else>{{ item.instance_name }}</span> -->
        <a @click="() => handleRowClick(item)">{{ item.instance_name }}</a>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
export default {
  name: 'ConsultationEvaluation',
  inject: ['individualId', 'instance', 'handleShowDrawer', 'handleDrawerClose'],
  data() {
    return {
      datatype: '0',
      dataList: []
    }
  },
  computed: {
    tableData() {
      return this.dataList.filter(d => d.datatype == this.datatype)
    }
  },
  async mounted() {
    const characterCemplates = await api.get("character_templates/get_template_list/?c=" + this.individualId)
    const evalutionTemplates = characterCemplates.filter(cc => cc.display_type == 'S' && cc.template_tags.some(tt => tt.tag_no.indexOf('TCIT01') > -1))
    const followupTemplates = characterCemplates.filter(cc => cc.display_type == 'S' && cc.template_tags.some(tt => tt.tag_no.indexOf('TCIT05') > -1))
    
    const template_ids = [...evalutionTemplates, ...followupTemplates].map(template => template.sid)
    const templateData = await api.post("template_instances/get_template_instances_by_customer/", {
      i: this.individualId,
      template_ids
    })

    const dataList = []
    for(const template of evalutionTemplates) {
      const data = templateData.filter(d => d.template == template.sid)
      data.map(d => d.datatype = '0')
      dataList.push(...data)
    }
    for(const template of followupTemplates) {
      const data = templateData.filter(d => d.template == template.sid)
      data.map(d => d.datatype = '1')
      dataList.push(...data)
    }
    this.dataList = dataList
  },
  methods: {
    async handleRowClick(item) {
      this.handleDrawerClose()
      await this.$nextTick()
      this.handleShowDrawer(item.instance_name, 'CharacterViewRecord', { id: this.individualId, sid: item.sid })
    }
  }
}
</script>