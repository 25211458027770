<template>
  <div class="health-evaluate-report">
    <div class="header">
      向内探索，重塑您的生命历程
      <img src="@/assets/favicon-half.png"/>
    </div>
    <div class="main">
      <div class="welcome">
        <h4>尊敬的张先生，您好！</h4>
        <p>您的健康管理已经进行了{{manage_plan_progress.complete_days}}天，占总体进程约{{manage_plan_progress.percent_complete}}，健康管理进程还剩{{manage_plan_progress.surplus_days}}天，占总体进程约{{manage_plan_progress.percent_surplus}}</p>
      </div>
      <div class="process">
        <div class="used" :style="{ width: manage_plan_progress.percent_complete }">
          {{manage_plan_progress.percent_complete}}
        </div>
        <div class="unused" :style="{ width: manage_plan_progress.percent_surplus }">
          {{manage_plan_progress.percent_surplus}}
        </div>
      </div>
      <div class="main-quest-title">健康行动表现</div>
      <div class="next-suggest">
        基于您近期在健康保卫行动中的表现，总体评价：
      </div>
      <div class="what-the-radios">
        <div v-for="item in overall" :key="item.value">
          <div :class="`wtr-radio ${item.value == overall_performance?'checked':''}`" />{{item.label}}
        </div>
      </div>
      <div border="0" cellspacing="0" cellpadding="0" class="grid">
        <tr v-for="item in health_action_performance" :key="item.items">
          <td>【{{item.items}}】</td>
          <td style="width: 120px;flex: unset;">{{item.situation}}</td>
          <td>{{item.compared_with_last_time}}</td>
        </tr>
      </div>
      <template v-if="index_change.length > 0">
        <div class="main-quest-title">指标变化</div>
        <div style="height:200px;" v-for="item in index_change" :key="item.index_name">
          <Echart :options="{
            title: {
              left: 'center',
              text: item.index_name,
              textStyle: {
                color: '#707070'
              }
            },
            grid: { top: 32 },
            xAxis: {
              type: 'category',
              data: item.measure_date,
              axisTick: { show: false }
            },
            yAxis: {
              type: 'value',
              axisLine: { show: true },
              splitLine: { show: false },
              axisLabel: { show: false },
              max (value) { return value.max * 1.05 },
              min (value) { return value.min * 0.95 }
            },
            series: [
              {
                data: item.index_list,
                type: 'line',
                label: { show: true },
                symbol: 'circle',
                symbolSize: 7,
                lineStyle: { type: 'dashed', width: 1, color: 'black', opacity: 0.3 },
                itemStyle: { color: '#EF6C00' }
              }
            ]
          }" />
        </div>
      </template>
       <template v-if="Worthy_of_praise">
        <div class="main-quest-title t_green">您在以下方面做的较好，请继续保持</div>
        <div class="next-suggest" v-html="Worthy_of_praise">
        </div>
      </template>
      <template v-if="to_be_improved">
        <div class="main-quest-title t_orange">您在以下方面有待改进，请持续关注</div>
        <div class="next-suggest" v-html="to_be_improved">
        </div>
      </template>
      <template v-if="next_proposal">
        <div class="main-quest-title">下一步建议</div>
        <div class="next-suggest" v-html="next_proposal">
        </div>
      </template>
      <div class="sign">
        健康管理师：{{health_manager}}
      </div>
      <div class="sign">
        评价日期：{{evaluation_date}}
      </div>
      <div class="tip">
        如有任何疑问，请及时联系您的健康管理师
      </div>
    </div>
    <div class="ending">
      <span>Restart  Retreat  Reward</span>
    </div>
  </div>
</template>
<script>
import Echart from '@/components/echarts'
import VernierCaliper from '@/components/vernier-caliper/vernier-caliper'
import { api } from '@/utils/api'
export default {
  name: 'health-curative-effect-report',
  components: { Echart, VernierCaliper },
  props: {
    reportSid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      overall: [
        { label: '优秀', value: 5 },
        { label: '良好', value: 4 },
        { label: '一般', value: 3 },
        { label: '较差', value: 2 },
        { label: '很差', value: 1 }
      ],
      manage_plan_progress: {},
      overall_performance: 0,
      health_action_performance: [],
      index_change: [],
      to_be_improved: '',
      Worthy_of_praise: '',
      next_proposal: '',
      health_manager: '',
      evaluation_date: ''
    }
  },
  async mounted() {
    const rs = await api.get(`health_manage/get_evaluation_report_detail?e=${this.reportSid}`)
    const data = rs.msg
    // const data = {
    //   "manage_plan_progress": [
    //     {
    //       "complete_days": 12,
    //       "percent_complete":"10%",
    //       "surplus_days": 108,
    //       "percent_surplus":"90%"
    //     }
    //   ],
    //   // 1:很差 2:较差 3:一般 4:良好 5:优秀
    //   "overall_performance":1,
    //   "health_action_performance": [
    //     {"items":"饮食","situation":"较好","compared_with_last_time":"跟上次相比有改进"},
    //     {"items":"运动","situation":"较差","compared_with_last_time":"跟上次相比有退步"},
    //     {"items":"其他","situation":"一般","compared_with_last_time":"跟上次相比无明显变化"},
    //   ],
    //   "index_change": [
    //     {"index_name":"体重","measure_date":["2021-09-20","2021-10-10","2021-11-10"],"index_list":[78,77,75]},
    //     {"index_name":"腰围","measure_date":["2021-09-20","2021-10-10","2021-11-10"],"index_list":[92,91,90]},
    //     {"index_name":"空腹血糖","measure_date":["2021-09-20","2021-10-10","2021-11-10"],"index_list":[5.6,5.4,5.5]}
    //   ],
    //   "to_be_improved":"精致碳水摄入过多，需要适当减少，特别是晚餐，需要适当减少，特别是晚餐，需要适当减少，特别是晚餐",
    //   "Worthy_of_praise":"您每日的水果和坚果摄入比较合理,请继续保持",
    //   "next_proposal":"减少精致碳水,晚餐尽可能减少主食的摄入",
    //   "health_manager":"陈侨君",
    //   "evaluation_date":"2022-03-29"
    // }
    data.index_change.map(ic => {
      for (let i=0;i<ic.index_list.length;i++) {
        ic.index_list[i] = Number(ic.index_list[i])
        if (ic.index_list[i] == 0) {
          ic.index_list.splice(i, 1)
          ic.measure_date.splice(i, 1)
          i--
        }
      }
    })
    this.manage_plan_progress = data.manage_plan_progress[0]
    this.overall_performance = data.overall_performance
    this.health_action_performance = data.health_action_performance
    this.index_change = data.index_change.filter(ic => ic.index_list.length > 1)
    this.to_be_improved = data.to_be_improved
    this.Worthy_of_praise = data.Worthy_of_praise
    this.next_proposal = data.next_proposal
    this.health_manager = data.health_manager
    this.evaluation_date = data.evaluation_date
  }
}
</script>
<style scoped lang="scss">
.health-evaluate-report {
  color: #707070;
  .header {
    background: #00B4ED;
    color: #ffffff;
    height: 60px;
    position: relative;
    display:flex;
    align-items:flex-end;
    padding: 0 0 8px 8px;
    img {
      position: absolute;
      right: 0;
      bottom: 4px;
      transform: translate(0, 50%);
    }
  }
  .main {
    padding: 32px;
    .welcome {
      font-size: 16px;
      line-height: 40px;
    }
    .process {
      margin-top: 16px;
      position: relative;
      border-radius: 20px;
      height: 28px;
      line-height: 28px;
      overflow: hidden;
      font-size: 16px;
      div {
        height: 100%;
        position: absolute;
        top: 0;
        text-align: center;
        color: #ffffff;
      }
      .used {
        left: 0;
        background: #999999;
      }
      .unused {
        right: 0;
        background: #8BC34A;
      }
    }
    .main-quest-title {
      margin: 32px 0;
      color: #00B4ED;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
    }
    .main-quest-title.t_orange {
      color: #EF6C00;
    }
    .main-quest-title.t_green {
      color: #7CB342;
    }
    .what-the-radios {
      margin-top: 32px;
      display: flex;
      &>div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .wtr-radio {
          width: 19px;
          height: 19px;
          border-radius: 100%;
          overflow: hidden;
          padding: 2px;
          display: inline-block;
          border: 2px solid grey;
          margin-right: 4px;
        }
        .wtr-radio.checked::before {
          content: '';
          display: block;
          height: 100%;
          background: green;
          border-radius: 100%;
        }
      }
      div:nth-child(1) .wtr-radio.checked {
        border-color: #7CB342;
        &::before {
          background: #7CB342;
        }
      }
      div:nth-child(2) .wtr-radio.checked {
        border-color: #D4E157;
        &::before {
          background: #D4E157;
        }
      }
      div:nth-child(3) .wtr-radio.checked {
        border-color: #009688;
        &::before {
          background: #009688;
        }
      }
      div:nth-child(4) .wtr-radio.checked {
        border-color: #EF6C00;
        &::before {
          background: #EF6C00;
        }
      }
      div:nth-child(5) .wtr-radio.checked {
        border-color: #EF0000;
        &::before {
          background: #EF0000;
        }
      }
    }
    .grid {
      width: 100%;
      margin-top: 16px;
      tr {
        line-height: 40px;
        display: flex;
        td {
          flex: 1;
        }
      }
    }
    .next-suggest {
      margin-bottom: 8px;
      line-height: 28px;
    }
    .sign {
      text-align: right;
      margin: 32px 0 16px;
    }
    .tip {
      text-align: center;
      margin: 64px 0 0;
    }
  }
  .ending {
    margin: 32px 0 64px;
    background: #00B4ED;
    color: #00B4ED;
    text-align: center;
    height: 1px;
    span {
      background: #ffffff;
      transform: translate(0, -50%);
      display: inline-block;
      padding: 0 16px;
    }
  }
}
</style>