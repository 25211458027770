
import { api } from "@/utils/api";
import { getCurrentDateDash } from "@/utils/date";
import { isTextField, isLongTextField, isCheckBox, isRadioButton, isGenderMatch } from "@/utils/enumerations";
import nf from "@/utils/notification";
export default {
  props: {
    individualId: {
      type: String,
      default: ''
    },
    characterTemplateId: {
      type: String,
      default: ''
    },
    instanceId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      getControl: () => this.template_control_rules,
      setVisibleTrigger: this.setVisibleTrigger,
      setExclusiveStatus: this.setExclusiveStatus,
      setSelectValues: this.setSelectValues,
      deleteSelectValues: this.deleteSelectValues,
      readonly: this.readonly
    }
  },
  data() {
    return {
      isShow: false,
      dialog_save: false,
      template_config: {},
      customer: {
        doctor: {},
        organization: {}
      },
      instance: {
        instance_name: "",
        template: "",
        individual: "",
        organization: "",
        doctor: null,
        happen_date: getCurrentDateDash(),
        external_status: "INIT",
        internal_status: "INIT",
        happen_date_rules: [v => !!v || "不能为空"]
      },
      template_control_rules: []
    };
  },
  methods: {
    async loadBaseData() {
      // 获取客户信息
      this.customer = await api.get(`individuals/${this.individualId}/?`, null)
      // 获得模版控制规则
      this.template_control_rules = await api.get(`character_template_control_rules/?template=${this.characterTemplateId}`, null)
      // 获取模版信息
      const template_config = await api.get(`character_templates/${this.characterTemplateId}/?`, null)
      template_config.character_templates.map(template => {
        template.character.character_details.map(detail => {
          if (isCheckBox(template.character.character_input_type) || isRadioButton(template.character.character_input_type)) {
            const dname = detail.detail_name
            if (dname.indexOf('（') > -1) {
              const [detail_name, tooltip] = dname.split('（')
              detail.detail_name = detail_name
              detail.tooltip = tooltip.replace('）', '').replace(')', '')
            }
            if (dname.indexOf('(') > -1) {
              const [detail_name, tooltip] = dname.split('(')
              detail.detail_name = detail_name
              detail.tooltip = tooltip.replace('）', '').replace(')', '')
            }
          }
        })
      })
      this.template_config = template_config
    },
    async loadInstanceData() {
      this.instance = await api.get("template_instances/" + this.instanceId + "/?", null)
      this.initCharacterValue(this.instance)
    },
    async loadPreviousData() {
      // 获得以前的数据
      // this.previous_data = await api.get(`template_instances/get_last_instances_by_customer/?t=${this.characterTemplateId}&i=${this.individualId}`, null)
      // if (!this.previous_data) return
      // nf.showSuccessMsg("系统已经为您预先载入了最近一次的数据！")
      // this.initCharacterValue(this.previous_data)

      // 修改为获取最新的特征数据
      const no_list = []
      this.template_config.character_templates.map(template => {
        no_list.push(template.character.character_no)
      })
      if (no_list.length == 0) return
      const data = await api.post(`individuals/${this.individualId}/get_newest_instance_value_by_no/`, { no_list })
      console.log('载入了最新数据')
      this.template_config.character_templates.map(template => {
        const c = template.character
        c.instance_value = ""
        c.selected_values = []
        c.is_default_visible = template.is_default_visible
        c.is_should_visible = template.is_default_visible

        const template_instances = data[c.character_no]
        if (!template_instances || template_instances.length == 0) return
        c.is_should_visible = true
        template_instances.map(instance_detail => {
          const characterSid = instance_detail.character_id
          const detailSid = instance_detail.character_detail_id
          if (characterSid != c.sid) return


          const { instance_value, detail_value } = instance_detail
          if (isTextField(c.character_input_type)) {
            c.instance_value = instance_value
          } else if (isLongTextField(c.character_input_type)) {
            c.instance_value = instance_value
          } else if (isCheckBox(c.character_input_type)) {
            c.character_details.map(detail => {
              c.instance_value = instance_value
              if (detail.sid === detailSid) {
                c.selected_values.push(Number(detail_value))
                if (detail.is_other) {
                  detail.instance_value = `${instance_value}`
                } else if (detail.is_exclusive) {
                  detail.intance_value = 'true'
                  c.character_details.map(item => {
                    if (item.sid !== detail.sid) {
                      item.is_disabled = true
                    }
                  })
                } else {
                  detail.intance_value = 'true'
                }
              }
            })
          } else if (isRadioButton(c.character_input_type)) {
            c.character_details.map(item => {
              item.instance_value = ''
              item.detail_value = `${item.detail_value}`
              if (item.sid === detailSid) {
                c.instance_value = detail_value
              }
            })
          }
        })
      })
    },
    initCharacterValue(oldData) {
      this.template_config.character_templates.map(template => {
        const c = template.character
        c.instance_value = ""
        c.selected_values = []
        c.is_default_visible = template.is_default_visible;
        c.is_should_visible = template.is_default_visible;

        oldData.template_instances.map(instance_detail => {
          const characterSid = instance_detail.character?.sid || instance_detail.character
          const detailSid = instance_detail.character_detail?.sid || instance_detail.character_detail
          if (characterSid !== c.sid) return
          c.is_should_visible = true
          const { instance_value, detail_value } = instance_detail
          if (isTextField(c.character_input_type)) {
            c.instance_value = instance_value
          } else if (isLongTextField(c.character_input_type)) {
            c.instance_value = instance_value
          } else if (isCheckBox(c.character_input_type)) {
            c.character_details.map(detail => {
              c.instance_value = instance_value
              if (detail.sid === detailSid) {
                c.selected_values.push(Number(detail_value))
                if (detail.is_other) {
                  detail.instance_value = `${instance_value}`
                } else if (detail.is_exclusive) {
                  detail.intance_value = 'true'
                  c.character_details.map(item => {
                    if (item.sid !== detail.sid) {
                      item.is_disabled = true
                    }
                  })
                } else {
                  detail.intance_value = 'true'
                }
              }
            })
          } else if (isRadioButton(c.character_input_type)) {
            c.character_details.map(item => {
              item.instance_value = ''
              item.detail_value = `${item.detail_value}`
              if (item.sid === detailSid) {
                c.instance_value = detail_value
              }
            })
          }
        })
      })
    },
    async constructData() {
      const { msg: detailControl } = await api.get(`character_detail_control_config/get_character_detail_control_rule/?i=${this.individualId}&t=${this.characterTemplateId}`)
      this.template_config.character_templates.map(template => {
        const c = template.character
        const control = detailControl.filter(dc => dc.character == c.sid)
        if (control.length > 0) {
          for(let i=0;i<c.character_details.length;i++) {
            const detail = c.character_details[i]
            if (control.some(ctr => ctr.character_detail == detail.sid)) {
              c.character_details.splice(i, 1)
              i--
            }
          }
        }
      })

      this.instance.individual = this.customer.sid;
      this.instance.doctor = this.customer.doctor.sid;
      this.instance.organization = this.customer.organization.sid;
      this.instance.template = this.template_config.sid;
      this.instance.instance_name = this.template_config.template_name;
      this.template_config.group_templates.map(group => {
        group.characters = [];
        this.template_config.character_templates.map(template => {
          if (group.sid !== template.template_group) return
          template.character.rules = [];
          if (template.character.character_type === "INPUT") {
            if (template.required_option_count > 0) {
              template.character.rules.push(v => !!v || "不能为空");
            }
            switch(template.character.character_input_type) {
              case 'float':
                template.character.rules.push(v => /(^$)|^\d+(\.\d+)?$/.test(v) || "数据格式错误，必须为整数或小数");
                break;
              case 'integer':
                template.character.rules.push(v => /(^$)|^[0-9]\d*$/.test(v) || "数据格式错误，必须为整数");
                break;
              case 'date':
                template.character.rules.push(v => /(^$)|^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误, 格式YYYY-MM-DD");
                break;
              case 'checkbox':
                template.character.rules.push(v => {
                  return v.length >= template.required_option_count  || "至少选择" + template.required_option_count + "项"
                });
                break;
            }
          }
          group.characters.push(template.character)
        })
      })
      this.isShow = !this.isShow
    },
    genderMatch(value) {
      return isGenderMatch(value, this.customer.individual_gender)
    },
    async doSubmit() {
      if (this.steps == undefined) {
        if (!this.$refs.form.validate()) return
      } else {
        if (!this.$refs.form[this.steps - 1].validate()) return
      }
      this.dialog_save = true

      try {
        await this.doPostData()
        nf.showSuccessMsg("数据保存成功！");
        this.$router.back(-1)
      } catch(e) {
        nf.showErrorMsg("服务器出现错误")
      }
      this.dialog_save = false
    },
    // 外部组件调用此方法
    async doPostData() {
      const instance_details = []
      this.template_config.group_templates.map(group => {
        if (!this.genderMatch(group.group_crowd)) return
        group.characters.map(character => {
          if (character.character_type === "COMPUTE") return
          if (!character.is_should_visible) return
          if (character.character_details.length == 0) {
            instance_details.push({
              character: character.sid,
              character_detail: null,
              detail_value: null,
              instance_value: character.instance_value
            });
            if (character.save_template) {
              api.post(`characters/add_text_template/`, {
                cid: character.sid,
                text: character.instance_value
              })
            }
            return
          }

          character.character_details.map(detail => {
            const instance_detail = {
              character: character.sid,
              character_detail: detail.sid,
              detail_value: detail.detail_value,
              instance_value: ""
            };
            if (character.character_input_type === "checkbox") {
              if (detail.is_other) {
                if (detail.instance_value && detail.instance_value.trim().length > 0) {
                  instance_detail.character_detail = detail.sid
                  instance_detail.detail_value = detail.detail_value
                  instance_detail.instance_value = detail.instance_value;
                  instance_details.push(instance_detail);
                }
              } else if (character.selected_values.includes(detail.detail_value)) {
                instance_detail.character_detail = detail.sid
                instance_detail.detail_value = detail.detail_value
                instance_details.push(instance_detail);
              }
            } else if (isRadioButton(character.character_input_type)) {
              if (character.instance_value === detail.detail_value) {
                instance_details.push(instance_detail);
              }
            }
          })
        })
      })
      const i = this.instance
      if (this.instanceId) {
        return await api.put(`template_instances/${this.instanceId}/`, {
          happen_date: i.happen_date,
          instance_details
        })
      } else {
        return await api.post("template_instances/", {
          instance_name: i.instance_name,
          template: i.template,
          individual: i.individual,
          organization: i.organization,
          doctor: i.doctor,
          happen_date: i.happen_date,
          external_status: i.external_status,
          internal_status: i.internal_status,
          instance_details
        })
      }
    },
    setVisibleTrigger(in_character, in_character_detail) {
      this.template_control_rules.map(rule => {
        // 找到对应的规则
        if (rule.character_detail !== in_character_detail.sid) return
        // 去找被控制的特征
        this.template_config.group_templates.map(group => {
          group.characters.map(character => {
            if (rule.character !== character.sid) return

            // 如果是默认显示的直接跳过
            if (character.is_default_visible) return
            // 如果是计算类特征直接跳过
            if (character.character_type === "COMPUTE") return

            // 如果控制源是单选框
            if (isRadioButton(in_character.character_input_type)) {
              // 需要判断单选框是否被选中
              if (rule.control_condition === String(in_character_detail.detail_value)) {
                // 如果选中，设置控制特征的显示属性
                character.is_should_visible = rule.control_action === "show";
                if (!character.is_should_visible) {
                  if (isCheckBox(character.character_input_type) || isRadioButton(character.character_input_type)) {
                    character.instance_value = ""
                    character.selected_values = []
                    for (let detail of character.character_details) {
                      detail.instance_value = "";
                    }
                  }
                }
              }
            } else if (isCheckBox(in_character.character_input_type)) {
              character.is_should_visible =
                  rule.control_action === "show" && in_character.selected_values.includes(in_character_detail.detail_value)
              if (!character.is_should_visible) {
                if (isCheckBox(character.character_input_type) || isRadioButton(character.character_input_type)) {
                  character.selected_values = []
                  for (let detail of character.character_details) {
                    detail.instance_value = "";
                  }
                }
              }
            }
          })
        })
      })
      this.isShow = !this.isShow;
    },
    setExclusiveStatus(in_character, in_character_detail) {
      this.template_config.group_templates.map(group => {
        group.characters.map(character => {
          if (!isCheckBox(character.character_input_type)) return
          if (character.sid !== in_character.sid) return
          if (character.selected_values.includes(in_character_detail.detail_value)) {
            character.selected_values = [in_character_detail.detail_value]
            character.instance_value = "";
          } else {
            character.selected_values = []
          }
          for (let character_detail of character.character_details) {
            if (in_character_detail.sid !== character_detail.sid) {
              character_detail.is_disabled = character.selected_values.includes(in_character_detail.detail_value);
            }
          }
        })
      })
      this.setVisibleTrigger(in_character, in_character_detail)
      this.isShow = !this.isShow;
    },
    setSelectValues(character, detail_value) {
      if (character.instance_value == null) {
        character.instance_value = ""
      }
      if (character.instance_value.trim().length > 0) {
        if (!character.selected_values.includes(detail_value)) {
          character.selected_values.push(detail_value)
        }
      } else {
        character.selected_values.forEach(function(item, index, arr) {
          if(item === detail_value) {
            arr.splice(index, 1);
          }
        })
      }
      this.isShow = !this.isShow
    },
    deleteSelectValues(character, detail_value) {
      character.selected_values.forEach(function(item, index, arr) {
        if(item === detail_value) {
          arr.splice(index, 1);
        }
      })
      this.isShow = !this.isShow
    }
  }
};