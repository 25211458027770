<template>
  <v-card>
    <v-card-title style="padding-bottom: 0px">
      <v-subheader>{{ this.template_config.template_name }}</v-subheader>
      <span v-show="false">{{ isShow }}</span>
      <v-spacer></v-spacer>
      <v-btn color="grey" class="hidden-sm-and-down" @click="doBack">
        <v-icon dark left>remove_circle</v-icon>取消
      </v-btn>
    </v-card-title>
    <v-card-text style="padding-top: 0px; padding-bottom: 0px">
      <v-subheader
        class="caption font-weight-medium"
        style="padding-top: 0px; padding-bottom: 0px; height: 30px"
      >{{ customer.individual_no}}-{{ customer.individual_name }}-{{ customer.organization.org_name }}</v-subheader>
    </v-card-text>
    <v-card-text style="padding-top: 8px; padding-bottom: 0px">
      <v-layout row wrap>
        <v-flex d-flex xs12 style="padding: 20px 16px 0px 28px">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="instance.happen_date"
            width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              v-model="instance.happen_date"
              label="记录日期"
              readonly
              style="padding: 0px"
              :rules="instance.happen_date_rules"
            ></v-text-field></template>
            <v-date-picker
              v-model="instance.happen_date"
              @input="$refs.dialog.save(instance.happen_date)"
              scrollable
              color="primary"
              :max="current_date"
            ></v-date-picker>
          
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text style="padding: 0 8px 0 8px">
      <v-stepper v-model="group_step" vertical v-if="isRendered" style="webkit-box-shadow: 0 0 0 0; box-shadow: 0 0 0 0; padding-left: 0px">
        <template v-for="group in template_config.group_templates">
          <v-stepper-step :key="`${group.sid}-step`" :complete="group_step > group.step" :step="group.step" v-if="genderMatch(group.group_crowd)" edit-icon="fa-check">
            {{group.group_name}}
          </v-stepper-step>
          <v-stepper-content :key="`${group.sid}-content`" :step="group.step" v-if="genderMatch(group.group_crowd)">
            <v-form ref="form" v-model="group.valid" lazy-validation v-on:submit.prevent="onSubmit">
            <v-layout row wrap>
              <template v-for="character in group.characters">
                <v-flex d-flex xs12 sm6 md4 class="py-2 pl-8 pr-3"  :key="character.sid" v-if="textField(character.character_input_type) && genderMatch(character.character_crowd)">
                  <v-text-field :label="character.character_name" v-model="character.instance_value" :suffix="character.character_unit" :rules="character.rules" v-if="character.is_should_visible" persistent-hint :hint="character.character_comments"></v-text-field>
                </v-flex>
                <v-flex d-flex xs12 class="py-2 pl-8 pr-3"  :key="character.sid" v-if="longTextField(character.character_input_type) && genderMatch(character.character_crowd)">
                  <v-textarea outline auto-grow max-length="1000" :label="character.character_name" v-model="character.instance_value" :suffix="character.character_unit" :rules="character.rules" v-if="character.is_should_visible" persistent-hint :hint="character.character_comments"></v-textarea>
                </v-flex>
                <v-flex d-flex xs12 class="py-2 pl-8 pr-3" :key="character.sid" v-if="checkbox(character.character_input_type) && genderMatch(character.character_crowd) && character.is_should_visible" >
                    <v-layout row wrap>
                      <v-flex d-flex xs12>
                        <v-input :rules="character.rules" v-model="character.selected_values"><v-subheader style="padding: 24px 0px 0px 0px" class="text-body-2">{{character.character_name}}</v-subheader></v-input>
                      </v-flex>
                      <template v-for="character_detail in character.character_details" >
                        <v-flex d-flex xs12 sm6 md4 style="padding: 0px 12px 24px 4px; height: 48px" :key="character_detail.sid" v-if="!character_detail.is_disabled">
                          <v-layout row wrap v-if="genderMatch(character_detail.character_crowd)">
                            <v-flex d-flex xs12 v-if="character_detail.is_other" >
                              <v-text-field :label='character_detail.detail_name' v-model="character.instance_value" v-if="character_detail.is_other && !character_detail.is_disabled" clearable @change="setSelectValues(character, character_detail.detail_value)" @click:clear="deleteSelectValues(character, character_detail.detail_value)"></v-text-field>
                            </v-flex>
                            <v-flex d-flex xs12  v-else-if="character_detail.is_exclusive">
                              <v-checkbox :label="character_detail.detail_name" :value="character_detail.detail_value" v-model="character.selected_values"  color="primary" @change="setExclusiveStatus(character, character_detail)"></v-checkbox>
                            </v-flex>
                            <v-flex d-flex xs12 v-else-if="!character_detail.is_disabled">
                              <v-checkbox :label="character_detail.detail_name | getShortName" :value="character_detail.detail_value" v-model="character.selected_values" color="primary" @change="setVisibleTrigger(character, character_detail)" v-if="isBundleChangeEvent(character_detail)"></v-checkbox>
                              <v-checkbox :label="character_detail.detail_name | getShortName" :value="character_detail.detail_value" v-model="character.selected_values" color="primary" v-else @change="isShow=!isShow"></v-checkbox>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </v-layout>
                </v-flex>
                <v-flex d-flex xs12 class="py-2 pl-8 pr-3"   :key="character.sid" v-if="radiobutton(character.character_input_type) && genderMatch(character.character_crowd) && character.is_should_visible">
                  <v-layout row wrap>
                    <v-flex d-flex xs12>
                      <v-subheader style="padding: 24px 0px 0px 0px" class="text-body-2">{{character.character_name}}</v-subheader>
                    </v-flex>
                    <v-radio-group v-model="character.instance_value" row style="padding: 0 0 0 0px" :rules="character.rules" v-if="character.is_should_visible" >
                      <v-flex d-flex v-for="character_detail in character.character_details" :key="character_detail.sid">
                        <v-radio class="mr-12" :label="character_detail.detail_name | getShortName" :value="character_detail.detail_value" color="primary" @change="setVisibleTrigger(character, character_detail)" v-if="isBundleChangeEvent(character_detail)"></v-radio>
                        <v-radio class="mr-12" :label="character_detail.detail_name | getShortName" :value="character_detail.detail_value" color="primary" v-else></v-radio>
                      </v-flex>
                    </v-radio-group>
                    <v-radio-group v-model="character.instance_value" row style="padding: 0px 0px 0px 12px" v-else>
                      <v-flex d-flex v-for="character_detail in character.character_details" :key="character_detail.sid">
                        <v-radio class="mr-12" :label="character_detail.detail_name | getShortName" :value="character_detail.detail_value" color="primary"></v-radio>
                      </v-flex>
                    </v-radio-group>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
              <v-layout>
                <v-flex d-flex xs12 class="pt-12">
            <v-btn color="teal" class="mr-2" @click.stop="group_step -= 1" v-if="group.step > 1"><span class="white--text">上一步</span></v-btn>
            <v-btn color="teal" class="mr-2" @click.stop="doCheckStep(group.step)" v-if="group.step < steps" ><span class="white--text">下一步</span></v-btn>
            <v-btn color="primary" class="mr-2" @click.stop="doSubmit" :disabled="!group.valid || dialog_save" :loading="dialog_save" v-if="group.step === steps">保存</v-btn>
            <v-btn color="grey" @click.stop="doBack">取消</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-card-text>
    <v-card-text>
    </v-card-text>
  </v-card>
</template>
<script>
import { api } from "@/utils/api";
import { getCurrentDateDash } from "@/utils/date";
import { isTextField, isCheckBox, isRadioButton, isGenderMatch, isLongTextField } from "@/utils/enumerations";
import nf from "@/utils/notification";

export default {
  name: "NewRecordGroupPage",
  props: {
    id: {
      type: String
    },
    cid: {
      type: String
    }
  },
  data() {
    return {
      valid: true,
      isShow: false,
      modal: false,
      dialog_save: false,
      template_config: {},
      e6: 1,
      e1:1,
      steps: 100,
      group_step: 1,
      customer: {
        doctor: {},
        organization: {}
      },
      instance: {
        instance_name: "",
        template: "",
        individual: "",
        organization: "",
        doctor: null,
        happen_date: null,
        external_status: "INIT",
        internal_status: "INIT",
        happen_date_rules: [v => !!v || "不能为空"]
      },
      template_control_rules: [],
      previous_data: null
    };
  },
  mounted() {
    this.instance.happen_date = getCurrentDateDash();
    this.doLoadData()
  },
  computed: {
    genderMatch() {
      return function(value) {
        if (value === "COMMON" || value === "ADULT" || value === "CHILD") {
          return true;
        } else {
          return value === this.customer.individual_gender;
        }
      };
    },
    textField() {
      return function(value) {
        return isTextField(value);
      };
    },
    longTextField() {
      return function(value) {
        return isLongTextField(value);
      };
    },
    checkbox() {
      return function(value) {
        return isCheckBox(value);
      };
    },
    radiobutton() {
      return function(value) {
        return isRadioButton(value);
      };
    },
    isBundleChangeEvent() {
      return function(in_character_detail) {
        for (let rule of this.template_control_rules) {
          if (rule.character_detail === in_character_detail.sid) {
            return true;
          }
        }
        return false;
      };
    },
    isRendered() {
      return this.template_config.hasOwnProperty('group_templates')
    },
    current_date() {
      return getCurrentDateDash()
    }
  },
  methods: {
    async doLoadData() {
      //获取客户信息
      this.customer = await api.get("individuals/" + this.id + "/?", null)
      this.instance.individual = this.customer.sid;
      this.instance.doctor = this.customer.doctor.sid;
      this.instance.organization = this.customer.organization.sid;
      //获取模版信息
      this.template_config = await api.get("character_templates/" + this.cid + "/?", null)
      this.instance.template = this.template_config.sid;
      this.instance.instance_name = this.template_config.template_name;
      let stepper = 0;
      for (let group of this.template_config.group_templates) {
        group.step = 0;
        if (isGenderMatch(group.group_crowd, this.customer.individual_gender)) {
          stepper += 1;
          group.valid = true;
          group.step = stepper;
        }
        group.characters = [];
      }
      this.steps = stepper
      if (this.template_config.is_load) {
        //获得以前的数据
        this.previous_data = await api.get("template_instances/get_last_instances_by_customer/?t=" + this.cid + "&i=" + this.id, null)
        if (this.previous_data !== "") {
          for (let template of this.template_config.character_templates) {
            template.character.instance_value = ""
            template.character.selected_values = []
            template.character.is_default_visible = template.is_default_visible;
            template.character.is_should_visible = template.is_default_visible;
            if (!template.is_load) { continue }
            for (let instance_detail of this.previous_data.template_instances) {
              if (instance_detail.character.sid === template.character.sid) {
                template.character.is_should_visible = true
                if (isTextField(template.character.character_input_type)) {

                  template.character.instance_value = instance_detail.instance_value;
                }
                if (isCheckBox(template.character.character_input_type)) {
                  for (let character_detail of template.character.character_details) {
                    template.character.instance_value = instance_detail.instance_value;
                    if (character_detail.sid === instance_detail.character_detail.sid) {
                      template.character.selected_values.push(parseInt(instance_detail.detail_value))
                      if (character_detail.is_other) {
                        character_detail.instance_value = instance_detail.instance_value + "";
                      }
                      else if (character_detail.is_exclusive) {
                        for (let item of template.character.character_details) {
                          if (item.sid !== character_detail.sid) {
                            item.is_disabled = true;
                          }
                        }
                      }
                    }
                  }
                }
                if (isRadioButton(template.character.character_input_type)) {
                  for (let character_detail of template.character.character_details) {
                    character_detail.detail_value = character_detail.detail_value + ""
                    if (character_detail.sid === instance_detail.character_detail.sid) {
                      template.character.instance_value = instance_detail.detail_value
                    }
                  }
                }
              }
            }
          }

        }
        for (let group of this.template_config.group_templates) {
          for (let template of this.template_config.character_templates) {
            if (group.sid === template.template_group) {
              if (this.previous_data === "") {
                template.character.instance_value = "";
                template.character.selected_values = []
                template.character.is_default_visible = template.is_default_visible;
                template.character.is_should_visible = template.is_default_visible;
              }
              template.character.rules = [];
              if ( template.character.character_type === "INPUT") {
                if (template.required_option_count > 0) {
                  template.character.rules.push(v => !!v || "不能为空");
                }
                if (template.character.character_input_type === "float") {
                  template.character.rules.push(
                          v =>
                                  /(^$)|^\d+(\.\d+)?$/.test(v) || "数据格式错误，必须为整数或小数"
                  );
                }
                if (template.character.character_input_type === "integer") {
                  template.character.rules.push(
                          v => /(^$)|^[0-9]\d*$/.test(v) || "数据格式错误，必须为整数"
                  );
                }
                if (template.character.character_input_type === "date") {
                  template.character.rules.push(
                          v =>
                                  /(^$)|^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
                                          v
                                  ) || "日期格式错误, 格式YYYY-MM-DD"
                  );
                }
                if (template.character.character_input_type === "checkbox") {
                  template.character.rules.push(v => v.length >= template.required_option_count  || "至少选择" + template.required_option_count + "项");
                }

              }
              for (let detail of template.character.character_details) {
                detail.instance_value = "";
                detail.is_disabled = false;
              }
              group.characters.push(template.character);
            }
          }
        }
        this.isShow = !this.isShow

        if (this.previous_data !== "") {
          nf.showSuccessMsg("系统已经为您预先载入了最近一次的数据！");
        }
        this.template_control_rules = await api.get("character_template_control_rules/?template=" + this.cid, null)

      }
      else {
        for (let group of this.template_config.group_templates) {
          for (let template of this.template_config.character_templates) {
            if (group.sid === template.template_group) {
              template.character.instance_value = "";
              template.character.selected_values = []
              template.character.is_default_visible = template.is_default_visible;
              template.character.is_should_visible = template.is_default_visible;
              template.character.rules = [];
              if ( template.character.character_type === "INPUT") {
                if (template.required_option_count > 0) {
                  template.character.rules.push(v => !!v || "不能为空");
                }
                if (template.character.character_input_type === "float") {
                  template.character.rules.push(
                          v =>
                                  /(^$)|^\d+(\.\d+)?$/.test(v) || "数据格式错误，必须为整数或小数"
                  );
                }
                if (template.character.character_input_type === "integer") {
                  template.character.rules.push(
                          v => /(^$)|^[0-9]\d*$/.test(v) || "数据格式错误，必须为整数"
                  );
                }
                if (template.character.character_input_type === "date") {
                  template.character.rules.push(
                          v =>
                                  /(^$)|^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
                                          v
                                  ) || "日期格式错误, 格式YYYY-MM-DD"
                  );
                }
                if (template.character.character_input_type === "checkbox") {
                  template.character.rules.push(v => v.length >= template.required_option_count  || "至少选择" + template.required_option_count + "项");
                }

              }
              for (let detail of template.character.character_details) {
                detail.instance_value = "";
                detail.is_disabled = false;
              }
              group.characters.push(template.character);
            }
          }
        }
        this.isShow = !this.isShow
        //获得模版控制规则
        this.template_control_rules = await api.get("character_template_control_rules/?template=" + this.cid, null)
      }
    },
    doCheckStep(groupStep) {
      if (this.$refs.form[groupStep - 1].validate()) {
        if (this.group_step < this.steps) {
          this.group_step += 1
        }
        
      }
    },
    doBack() {
      this.$router.replace({
        name: "客户信息",
        params: {}
      });
    },
    async doSubmit() {
      if (this.$refs.form[this.steps - 1].validate()) {
        this.dialog_save = true
        //开始组装数据
        let instance_combo = {
          instance_name: "",
          template: "",
          individual: "",
          organization: "",
          doctor: null,
          happen_date: null,
          external_status: "INIT",
          internal_status: "INIT",
          instance_details: []
        };
        instance_combo.instance_name = this.instance.instance_name;
        instance_combo.template = this.instance.template;
        instance_combo.individual = this.instance.individual;
        instance_combo.organization = this.instance.organization;
        instance_combo.doctor = this.instance.doctor;
        instance_combo.happen_date = this.instance.happen_date;
        instance_combo.external_status = this.instance.external_status;
        instance_combo.internal_status = this.instance.internal_status;
       
        for (let group of this.template_config.group_templates) {
          if (!isGenderMatch(group.group_crowd, this.customer.individual_gender)) {
            continue
          }
          for (let character of group.characters) {
            if (character.character_type === "COMPUTE") {
              continue;
            }
            if (!character.is_should_visible) {
              continue;
            }
            if (character.character_details.length > 0) {
              for (let detail of character.character_details) {
                let instance_detail = {};
                instance_detail.character = character.sid;
                instance_detail.character_detail = null;
                instance_detail.detail_value = null;
                instance_detail.instance_value = "";
                if (character.character_input_type === "checkbox") {
                  if (
                    detail.is_other &&
                    character.instance_value.trim().length > 0
                  ) {
                    instance_detail.character_detail = detail.sid;
                    instance_detail.detail_value = detail.detail_value;
                    instance_detail.instance_value = character.instance_value;
                    instance_combo.instance_details.push(instance_detail);
                  } else if (character.selected_values.includes(detail.detail_value)) {
                    instance_detail.character_detail = detail.sid;
                    instance_detail.detail_value = detail.detail_value;
                    instance_combo.instance_details.push(instance_detail);
                  }
                } else if (isRadioButton(character.character_input_type)) {

                  if (character.instance_value === detail.detail_value) {
                    instance_detail.character_detail = detail.sid;
                    instance_detail.detail_value = detail.detail_value;
                    instance_combo.instance_details.push(instance_detail);
                  }
                }
              }
            } else {
              let instance_detail = {};
              instance_detail.character = character.sid;
              instance_detail.character_detail = null;
              instance_detail.detail_value = null;
              instance_detail.instance_value = "";
              instance_detail.instance_value = character.instance_value;
              instance_combo.instance_details.push(instance_detail);
            }
          }
        }
        await api.post("template_instances/", instance_combo).then(data => {
           this.dialog_save = false
          nf.showSuccessMsg("数据保存成功！");
          this.$router.back(-1)
          // this.$router.replace({
          //   name: "客户信息",
          //   params: { id: this.customer.sid }
          // });
        }).catch(error => {
              nf.showErrorMsg("服务器出现错误")
              this.dialog_save = false
            });
      }
    },
    setVisibleTrigger: function(in_character, in_character_detail) {
      for (let rule of this.template_control_rules) {
        //找到对应的规则
        if (rule.character_detail === in_character_detail.sid) {
          //去找被控制的特征
          for (let group of this.template_config.group_templates) {
            for (let character of group.characters) {
              //如果是默认显示的直接跳过
              if (character.is_default_visible) {
                continue;
              }
              //如果是计算类特征直接跳过
              if (character.character_type === "COMPUTE") {
                continue;
              }
              //找到了被控制的特征
              if (rule.character === character.sid) {
                //如果控制源是单选框
                if (isRadioButton(in_character.character_input_type)) {
                  //需要判断单选框是否被选中
                  if (
                          rule.control_condition === String(in_character_detail.detail_value)
                  ) {
                    
                    //如果选中，设置控制特征的显示属性
                    character.is_should_visible = rule.control_action === "show";
                    if (!character.is_should_visible) {
                      if (
                        isCheckBox(character.character_input_type) ||
                        isRadioButton(character.character_input_type)
                      ) {
                        character.instance_value = ""
                        character.selected_values = []
                        for (let detail of character.character_details) {
                          detail.instance_value = "";
                        }
                      }
                    }
                    // continue;
                  }
                } else if (isCheckBox(in_character.character_input_type)) {
                  character.is_should_visible =
                     rule.control_action === "show" && in_character.selected_values.includes(in_character_detail.detail_value)
                  if (!character.is_should_visible) {
                    if (
                      isCheckBox(character.character_input_type) ||
                      isRadioButton(character.character_input_type)
                    ) {
                      character.selected_values = []
                      for (let detail of character.character_details) {
                        detail.instance_value = "";
                      }
                    }
                  }
                } 
              }
            }
          }
        }
      }
      this.isShow = !this.isShow;
    },
    setExclusiveStatus: function(in_character, in_character_detail) {
      for (let group of this.template_config.group_templates) {
        for (let character of group.characters) {
          if (isCheckBox(character.character_input_type)) {
            if (character.sid === in_character.sid) {
              if (character.selected_values.includes(in_character_detail.detail_value)) {
                character.selected_values = []
                character.selected_values.push(in_character_detail.detail_value)
                character.instance_value = "";
              } else {
                character.selected_values = []
              }
              for (let character_detail of character.character_details) {
                if (in_character_detail.sid !== character_detail.sid) {
                  character_detail.is_disabled = character.selected_values.includes(in_character_detail.detail_value);
                }
              }
            }
          }
        }
      }
      this.setVisibleTrigger(in_character, in_character_detail)
      this.isShow = !this.isShow;
    },
    setSelectValues: function(character, detail_value) {
      if (character.instance_value == null) {
        character.instance_value = ""
      }
      if (character.instance_value.trim().length > 0) {
        if (!character.selected_values.includes(detail_value)) {
          character.selected_values.push(detail_value)
        }
      }
      else {
        character.selected_values.forEach(function(item, index, arr) {
          if(item === detail_value) {
            arr.splice(index, 1);
          }
        })
      }
      this.isShow = !this.isShow
    },
    deleteSelectValues: function(character, detail_value) {
      character.selected_values.forEach(function(item, index, arr) {
        if(item === detail_value) {
          arr.splice(index, 1);
        }
      })
      this.isShow = !this.isShow
    }
  }
};
</script>