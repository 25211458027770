<template>
  <v-card class="calender-manage">
    <h4>
      健康管理计划{{plan?` - ${plan.name}`: ''}}
      <v-spacer></v-spacer>
      <v-btn fab text small color="grey darken-2 newbtn" @click="$router.push({ name: '管理计划', params: { individualId: individualId }})">
        <v-icon color="primary">mdi-clipboard-list-outline</v-icon>
      </v-btn>
    </h4>
    <div class="calendar-tool">
      <!-- <v-btn small color="primary" @click="handleToNow">今天</v-btn> -->
      <v-btn fab text small color="grey darken-2" @click="handlePrev">
        <v-icon color="primary">mdi-chevron-left</v-icon>
      </v-btn>
      <label v-if="$refs.calendar">{{ $refs.calendar.title }}</label>
      <v-btn fab text small color="grey darken-2" @click="handleNext">
        <v-icon color="primary">mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer />
      <v-radio-group row style="margin: 0" hide-details v-model="type">
        <v-radio label="按月" value="month" />
        <v-radio label="按周" value="week" />
        <v-radio label="按天" value="day" />
      </v-radio-group>
    </div>
    <div class="calendar-action">
      <div class="action-now">
        <div class="action-title">
          需要立刻开始的活动
          <label style="color: #737373;" v-if="todayActions.length > 3">
            ({{todayActions.length}})
          </label>
        </div>
        <li class="action-item" v-for="(item, i) in todayActions.slice(0, 3)" :key="i">
          <a @click="$router.push({ name: '活动填写详情', params: { individualId: individualId, actionId: item.sid }})">{{ item.name }}</a>
        </li>
        <li class="action-item" v-if="todayActions.length == 0">暂无</li>
      </div>
      <div class="action-soon">
        <div class="action-title">
          即将开始的活动
          <label v-if="nearlyActions.length > 3">
            {{nearlyActions.length}}
          </label>
        </div>
        <li class="action-item" v-for="(item, i) in nearlyActions.slice(0, 3)" :key="i">
          {{ item.name }} - {{ item.after }}天后
        </li>
        <li class="action-item" v-if="nearlyActions.length == 0">暂无</li>
      </div>
      <!-- <div v-if="!plan" class="calendar-no-plan">
        <v-btn color="primary" @click="$router.push({ name: '管理计划详情', params: { individualId: individualId, planId: 0 }})">创建管理计划</v-btn>
      </div> -->
    </div>
    <v-calendar
      class="calendar"
      ref="calendar"
      locale="zh-cn"
      color="primary"
      v-model="focus"
      :type="type"
      @click:date="handleClickDate"
      @click:event="handleClickEvent"
      @click:more="handleClickMore"
      :events="events"
    ></v-calendar>
    <v-menu
      v-model="showEventACtion"
      :close-on-content-click="false"
      :activator="eventEl"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar :color="clickEvent.color" dark>
          <v-toolbar-title v-html="clickEvent.name"></v-toolbar-title>
          <v-spacer/>
          <div v-if="'C' === clickEvent.state">已完成</div>
          <div v-if="'L' === clickEvent.state">已发布</div>
        </v-toolbar>
        <v-card-text>
          <p>{{clickEvent.remarks}}</p>
          <v-btn v-show="!clickEvent.disabled && has_action_change_right" class="ma-2" color="primary" v-if="'C' === clickEvent.state && ['B', 'I'].includes(clickEvent.type) && has_action_change_right" @click="handleFillEvaluate">健康评价</v-btn>
          <v-btn v-show="!clickEvent.disabled" class="ma-2" color="primary" v-if="has_action_change_right" @click="handleToActionDetail">查看</v-btn>
          <v-btn v-show="!clickEvent.disabled" class="ma-2" color="info" v-if="clickEvent.openReport && has_action_change_right" @click="clickEvent.openReport">评价报告</v-btn>
          <v-btn class="ma-2" color="error" v-if="'R' === clickEvent.state && has_action_delete_right" @click="showDialogDelete = true">删除</v-btn>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog v-model="showCreateAction" width="600">
      <CreateActionView
        v-if="showCreateAction"
        :defaultData="action"
        @on-close="refreshActionData"
      />
    </v-dialog>
    <v-dialog v-model="showDialogDelete" persistent max-width="320">
      <v-card>
        <v-card-title class="text-subtitle-2">您确认要删除该记录吗?</v-card-title>
        <v-card-text>删除后该记录将无法还原，请您谨慎操作！</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="showDialogDelete = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleDeleteAction">我想好了，删</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
import { formatDate } from "@/utils/date"
import nf from "@/utils/notification"
import CreateActionView from '@/views/health/manage-plan/CreateAction.vue'
const dateTimestamp = 24 * 60 * 60 * 1000;
const ACTION_TYPE_COLOR_MAP = {
  B: "#673AB7", // 基线评价
  F: "#00BCD4", // 阶段随访
  E: "#8BC34A", // 阶段评价
  I: "#8BC34A", // 最终评价
  H: "#9C27B0", // 健康宣教
  O: "#E91E63", // 其他活动
}
export default {
  components: { CreateActionView },
  inject: ['individualId', 'handleShowDrawer', 'handleDrawerClose'],
  data() {
    return {
      type: undefined,
      focus: new Date(),
      today: Date.parse(formatDate(new Date())),
      plan: null,
      actionData: [],
      showCreateAction: false,
      action: {},
      eventEl: undefined,
      showEventACtion: false,
      clickEvent: {},
      showDialogDelete: false
    };
  },
  computed: {
    events() {
      return this.actionData.map((p) => {
        const [startTime, endTime] = p.time_range?.split("-");
        return {
          sid: p.sid,
          name: p.name,
          start: `${p.start_date} ${startTime}`,
          end: `${p.end_date} ${endTime}`,
          color: ['C', 'L'].includes(p.state) ? ACTION_TYPE_COLOR_MAP[p.type]: 'grey',
          type: p.type,
          state: p.state,
          remarks: p.remarks,
          openReport: p.openReport,
          disabled: p.disabled
        };
      });
    },
    todayActions() {
      return this.actionData.filter((p) => {
        return (Date.parse(p.start_date) <= this.today) && ['O', 'R'].includes(p.state);
      });
    },
    nearlyActions() {
      const now = this.today;
      const nearlyData = this.actionData.filter((p) => {
        const after = Math.ceil(
          (Date.parse(p.start_date) - now) / dateTimestamp
        );
        p.after = after;
        return (after >= 1) && (after <= 7) && ['O', 'R'].includes(p.state);
      }).sort((a, b) => a.after - b.after)
      return nearlyData.slice(0, 3)
    },
    has_action_view_right() {
      return this.$store.getters.getPermission('manageplanaction', 'view')
    },
    has_action_add_right() {
      return this.$store.getters.getPermission('manageplanaction', 'add')
    },
    has_action_change_right() {
      return this.$store.getters.getPermission('manageplanaction', 'change')
    },
    has_action_delete_right() {
      return this.$store.getters.getPermission('manageplanaction', 'delete')
    }
  },
  async mounted() {
    this.type = "month"
    try {
      this.plan = await api.get(`health_manage/get_in_execution_plan/?i=${this.individualId}`)
    } catch(e) {
      console.error(e)
      nf.showErrorMsg(`获取执行中的管理计划失败`)
    }
    if (this.plan) {
      this.refreshActionData()
    }
  },
  methods: {
    handlePrev() {
      this.$refs.calendar.prev();
    },
    handleNext() {
      this.$refs.calendar.next();
    },
    handleFillEvaluate() {
      const item = this.clickEvent
      this.$router.push({ name: '健康评价', params: { id: this.$route.params.id, planId: this.plan.sid, actionId: item.sid }})
    },
    handleClickEvent({ nativeEvent, event }) {
      if (!this.has_action_view_right) return
      const open = () => {
        this.clickEvent = event
        this.eventEl = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.showEventACtion = true))
      }

      if (this.showEventACtion) {
        this.showEventACtion = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    handleClickDate(e) {
      if (!this.has_action_add_right) return
      const { plan } = this
      if (!plan) {
        nf.showInfoMsg(`该客户没有执行中的管理计划，请前往创建`)
        return
      }
      const click = Date.parse(e.date);
      const now = this.today;
      const end = Date.parse(plan.plan_end_date);
      if (click < now || click > end) {
        return;
      }
      this.action = {
        plan_id: this.plan.sid,
        start_date: e.date,
        end_date: e.date
      }
      this.showCreateAction = true;
    },
    handleClickMore(e) {
      this.focus = e.date
      this.type = 'week'
    },
    handleToActionDetail() {
      const { clickEvent } = this
      this.$router.push({ name: '活动填写详情', params: { individualId: this.individualId, actionId: clickEvent.sid }})
    },
    async refreshActionData() {
      this.showCreateAction = false;
      const actionData = await api.post(`health_manage/get_action_list/`, { individual_id: this.individualId, plan_id: this.plan.sid })
      const now = Date.parse(formatDate(Date.now()))
      for(let action of actionData) {
        const start = Date.parse(action.start_date)
        if (start > now) {
          action.disabled = true
        }
        if (['C', 'L'].includes(action.state) && ['E', 'B', 'I'].includes(action.type)) {
          const rp = await api.get(`health_manage/get_action_evaluation_report_relation?a=${action.sid}`)
          if (rp.msg) {
            action.openReport = async () => {
              this.handleDrawerClose()
              await this.$nextTick()
              switch(action.type) {
                case 'E':
                  this.handleShowDrawer(action.name, 'StageEvaluationReport', { reportSid: rp.msg })
                  break
                default:
                  this.handleShowDrawer(action.name, 'CurativeEffectReport', { reportSid: rp.msg })
              }
            }
          }
        }
      }
      this.actionData = actionData
    },
    async handleDeleteAction() {
      const { clickEvent } = this
      try {
        await api.post(`health_manage/delete_action/`, { action_id: clickEvent.sid })
        const index = this.actionData.findIndex(ad => ad.sid === clickEvent.sid)
        this.actionData.splice(index, 1)
        this.$forceUpdate()
        nf.showSuccessMsg(`删除活动成功`)
        this.showDialogDelete = false
      } catch(e) {
        nf.showErrorMsg(`删除活动失败`)
        console.error(e)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calender-manage {
  width: 100%;
  height: 851px;
  display: flex;
  flex-direction: column;
  .newbtn {
    position: absolute;
    top: 0;
    right: 0;
  }
  & > h4 {
    padding: 16px 16px 0;
    display: flex;
  }
}
.calendar-tool {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  & > span {
    border: 1px solid #1976d2;
    border-radius: 100%;
    margin: 0 16px;
  }
}
.calendar-action {
  width: 100%;
  display: flex;
  font-size: 14px;
  position: relative;
  & > div {
    min-height: 100px;
    flex: 1;
    border: 1px solid #c4c4c4;
    margin: 0 16px;
    padding: 8px;
    border-radius: 5px;
    .action-title {
      font-weight: bold;
    }
    .action-item {
      line-height: 32px;
      color: #737373;
    }
  }
  .action-now .action-title {
    color: #f44336;
  }
  .action-soon .action-title {
    color: #4caf50;
  }
  .calendar-no-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, .7);
  }
}
.calendar {
  height: auto;
  flex: 1;
  margin: 16px;
  width: auto;
}
</style>