<template>
  <v-card class="customers-v2-base">
    <v-card-title class="card-title dflex">
      <div class="flex1">{{customer.individual_name}} 【{{customer.individual_gender === 'MALE'? '男': '女'}} {{customer.age}}岁】</div>
      <v-dialog v-model="dialog_delete" persistent max-width="320" v-if="canDelete && has_delete_customer_right">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" fab text small>
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <v-card v-if="dialog_delete">
          <v-card-title class="text-subtitle-2">您确认要删除该记录吗?</v-card-title>
          <v-card-text>删除后该记录将无法还原，请您谨慎操作！</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click.native="dialog_delete = false">取消</v-btn>
            <v-btn color="error" text @click.native="doDelete">我想好了，删</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn color="primary" fab text small @click="showDetail = !showDetail">
        <v-icon color="primary">mdi-dots-horizontal-circle-outline</v-icon>
      </v-btn>
      <router-link v-bind:to="{ name: '编辑客户信息', params: { id: this.individualId }}" class="v-breadcrumbs__item" v-if="canEdit && has_edit_customer_right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on" fab text small>
              <v-icon color="primary">mdi-pencil-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>编辑</span>
        </v-tooltip>
      </router-link>
    </v-card-title>
    <template v-for="(tag, i) in customizeTag">
      <v-chip class="ml-4 mb-1" color="primary" small close :key="tag.sid" @click:close="() => handleDeleteCustomizeTag(i)" v-if="tag.sid">
        {{tag.tag_name}}
      </v-chip>
      <v-chip class="ml-4" small close close-icon="mdi-check" close-label="保存" :key="tag.sid" @click:close="() => handleSaveCustomizeTag(i)" v-else>
        <input class="add-tag-input" v-model="tag.tag_name" style="width:80px;"/>
      </v-chip>
    </template>
    <v-icon class="ml-4" color="primary" @click="handleAddCustomizeTag" v-if="customizeTag.length < 5">mdi-plus-circle</v-icon>
    <v-overlay :value="showDetail" @click.native="showDetail = false"></v-overlay>
    <v-navigation-drawer fixed permanent right :width="showDetail?480:0">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ customer.individual_name }}</v-toolbar-title>
      </v-toolbar>
      <v-list class="customer-baseinfo-detail">
        <v-list-item>
          客户编号：{{ customer.individual_no }}
        </v-list-item>
        <v-list-item>
          年龄：{{ customer.age }}
        </v-list-item>
        <v-list-item>
          出生日期：{{ customer.individual_birthday | formatDateDash }}
        </v-list-item>
        <v-list-item>
          建档日期：{{ customer.created_time | formatDateDash }}
        </v-list-item>
        <v-list-item>
          建档人：{{ customer.doctor.doctor_name }}
        </v-list-item>
        <v-list-item>
          建档机构：{{ customer.organization.org_name }}
        </v-list-item>
        <v-list-item>
          识别号：{{ customer.individual_additional_no }}
        </v-list-item>
        <v-list-item>
          民族：{{ customer.folk }}
        </v-list-item>
        <v-list-item>
          婚姻状况：{{ customer.marital_status }}
        </v-list-item>
        <v-list-item>
          学历：{{ customer.education }}
        </v-list-item>
        <v-list-item>
          手机号码：{{ customer.phone_number }}
        </v-list-item>
        <v-list-item>
          亲属电话：{{ customer.second_phone_number }}
        </v-list-item>
        <v-list-item>
          家庭住址：{{ customer.address }}
        </v-list-item>
        <v-list-item>
          已选产品：
        </v-list-item>
        <v-list-item>
          <Product />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { api } from "@/utils/api"
import nf from "@/utils/notification"
import Product from './product.vue'
export default {
  name: 'customers-v2-base',
  components: { Product },
  inject: ['individualId', 'instance'],
  data() {
    return {
      dialog_delete: false,
      showDetail: false,
      canDelete: false,
      canEdit: false,
      customer: { doctor: {}, organization: {} },
      customizeTag: []
    }
  },
  computed: {
    has_edit_customer_right() {
      return this.$store.getters.getPermission('individualbaseinfo', 'change')
    },
    has_delete_customer_right() {
      return this.$store.getters.getPermission('individualbaseinfo', 'add')
    },
  },
  async mounted() {
    this.customer = this.instance.customer

    const { result: canDelete } = await api.get(`individuals/${this.individualId}/can_be_deleted/`)
    this.canDelete = canDelete

    const { result: canEdit } = await api.get(`individuals/${this.individualId}/can_be_edited/`)
    this.canEdit = canEdit

    this.customizeTag = await api.get(`individuals/${this.individualId}/get_customized_tag/`)
  },
  methods: {
    async doDelete() {
      await api.delete(`individuals/${this.individualId}/`)
      nf.showSuccessMsg("数据删除成功！")
      this.$router.push({ name: '客户列表' })
    },
    async handleSaveCustomizeTag(i) {
      const tag = this.customizeTag[i]
      tag.tag_name = tag.tag_name?.replace(/^\s+|\s+$/gm, '')
      if (!tag.tag_name) {
        this.customizeTag.splice(i, 1)
        return
      }
      if (this.customizeTag.filter(ct => ct.tag_name === tag.tag_name).length > 1) {
        nf.showWarnMsg("已存在相同的标签！")
        return
      }
      try {
        const tagSid = await api.post(`individuals/${this.individualId}/add_customized_tag/`, { tagName: tag.tag_name })
        this.$set(tag, 'sid', tagSid)
      } catch(e) {
        console.error(e)
        nf.showErrorMsg("添加客户标签失败！")
      }
    },
    async handleDeleteCustomizeTag(i) {
      try {
        const tag = this.customizeTag[i]
        await api.post(`individuals/${this.individualId}/delete_customized_tag/`, { tagSid: tag.sid })
        this.customizeTag.splice(i, 1)
      } catch(e) {
        console.error(e)
        nf.showErrorMsg("删除客户标签失败！")
      }
    },
    handleAddCustomizeTag() {
      this.customizeTag.push({})
    }
  }
}
</script>