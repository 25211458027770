<template>
  <v-card style="width: 100%" v-if="action">
    <v-subheader>
      【{{actionType == 'B'?'': '上次'}}{{actionName}}
    </v-subheader>
    <v-layout row wrap class="pl-8 pr-8">
      <v-text-field
        v-model="action.action_end_date"
        label="记录日期"
        readonly
      />
    </v-layout>
    <v-expansion-panels flat>
      <v-expansion-panel v-for="detail in actionDetailList" :key="detail.sid">
        <v-expansion-panel-header>
          {{detail.title}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <QuestionnaireOnePage
            :individualId="individualId"
            :characterTemplateId="detail.templateId"
            :instanceId="detail.instance"
            readonly
            ref="questionnaire"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import nf from "@/utils/notification"
import { api } from "@/utils/api"
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
const ACTION_TYPE_Name_MAP = {
  B: '基线评价',
  F: '阶段随访',
  E: '阶段评价',
  I: '最终评价',
  H: '健康宣教'
}
export default {
  name: "LastTypeAction",
  components: { QuestionnaireOnePage },
  inject: ['individualId', 'instance'],
  props: {
    actionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    actionName() {
      return ACTION_TYPE_Name_MAP[this.actionType] || ''
    }
  },
  data() {
    return {
      action: null,
      actionDetailList: []
    }
  },
  async mounted() {
    const action = await api.get(`health_manage/get_latest_action_info/?i=${this.individualId}&a=${this.actionType}`)
    this.action = action
    if (action) {
      const actionDetailList = await api.get(`health_manage/get_action_template/?a=${action.sid}`)
      for(const detail of actionDetailList) {
        this.action = detail.action
        try {
          const { sid: template_id, short_name } = await api.post(`health_manage/get_template_id/`, { 'template_no': detail.template_no })
          detail.templateId = template_id
          detail.title = short_name
        } catch(e) {
          console.error(e)
          nf.showErrorMsg(`获取特征模板id失败`)
        }
      }
      this.actionDetailList = actionDetailList
    }
  }
}
</script>