var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"sm":"9"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"valueitem"},[_c('div',{staticClass:"valuehead"},[_c('v-icon',{staticClass:"valueicon"},[_vm._v("mdi-account-supervisor")]),_c('p',[_vm._v("客户总数量")]),_c('h1',[_vm._v(_vm._s(_vm.customerCount))])],1),_c('div',{staticClass:"valuechart"},[_c('Echart',{attrs:{"options":{
              grid: {
                right: '8px',
                bottom: '8px',
                top: '16px'
              },
              tooltip: {
                trigger: 'item',
                position: 'bottom',
                formatter: obj => {
                  const pl = 'style=\'padding-left:16px\''
                  const d = obj.data;
                  return `<table>
                    <tr><td colspan='2'><strong>${obj.name}</strong></td></tr>
                    <tr>
                      <td>总数量</td>
                      <td ${pl}>${d.allCount}</td>
                    </tr>
                    <tr>
                      <td>当月新增</td>
                      <td ${pl}>${d.value}</td>
                    </tr>
                  </table>`
                }
              },
              xAxis: {
                type: 'category',
                show: false,
                data: _vm.customerTrendX
              },
              yAxis: {
                type: 'value'
              },
              onClick(params) {
                _vm.$router.push({ path: `/customer/list?yearMonth=${params.name}` })
              },
              series: _vm.customerSeries
            }}})],1)])]),_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"valueitem"},[_c('div',{staticClass:"valuehead"},[_c('v-icon',{staticClass:"valueicon"},[_vm._v("mdi-file-pdf-box")]),_c('p',[_vm._v("报告总数量")]),_c('h1',[_vm._v(_vm._s(_vm.reportCount))])],1),_c('div',{staticClass:"valuechart"},[_c('Echart',{attrs:{"options":{
              grid: {
                right: '8px',
                bottom: '8px',
                top: '16px'
              },
              tooltip: {
                trigger: 'item',
                position: 'bottom',
                formatter: obj => {
                  const pl = 'style=\'padding-left:16px\'';
                  const d = obj.data;
                  const str = [
                    `<tr><td colspan='2'><strong>${obj.name}</strong></td></tr>`,
                    `<tr><td>总数量</td><td ${pl}><strong>${d.allCount}</strong></td></tr>`,
                    `<tr><td>当月新增</td><td ${pl}><strong>${d.value}</strong></td></tr>`
                  ]
                  if (d.typeMap) {
                    str.push(`<tr><td colspan='2'><div style='background:grey;height:1px;margin:8px 0;'></div></td></tr>`)
                    Object.keys(d.typeMap).map(n => {
                      str.push(`<tr><td>${n}</td><td ${pl}><strong>${d.typeMap[n]}</strong></td></tr>`)
                    })
                  }
                  return `<table>${str.join('')}</table>`
                }
              },
              xAxis: {
                type: 'category',
                show: false,
                data: _vm.reportTrendX
              },
              yAxis: {
                type: 'value'
              },
              series: _vm.reportSeries
            }}})],1)])]),_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"valueitem"},[_c('div',{staticClass:"valuehead"},[_c('v-icon',{staticClass:"valueicon"},[_vm._v("mdi-calendar-text")]),_c('p',[_vm._v("管理计划总数量")]),_c('h1',[_vm._v(_vm._s(_vm.planCount))])],1),_c('div',{staticClass:"valuechart"},[_c('Echart',{attrs:{"options":{
              series: [
                {
                  type: 'treemap',
                  top: 1,
                  left: 1,
                  bottom: 1,
                  right: 2,
                  breadcrumb: {show:false},
                  nodeClick: false,
                  roam: false,
                  label: {
                    formatter: obj => `${obj.data.name}\r\n${obj.data.value}`
                  },
                  data: _vm.planStatisticsData
                }
              ]
            }}})],1)])]),_c('v-col',{attrs:{"sm":"4"}},[_c('v-card',{style:(_vm.cardStyle)},[_c('Echart',{attrs:{"options":{
            title: {
              text: '客户健康状态词云'
            },
            onClick(params) {
              _vm.$router.push({ path: `/customer/list?tagName=${params.name}` })
            },
            series:[{
              type: 'wordCloud',
              shape: 'circle',
              gridSize: 2,
              sizeRange: [12, 38],
              rotationRange: [0, 0],
              width: '100%',
              height: '100%',
              top: 16,
              drawOutOfBound: true,
              keepAspect: true,
              textStyle: {
                color: () => `rgb(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              },
              data: _vm.couldWordsData
            }]
          }}})],1)],1),_c('v-col',{attrs:{"sm":"8"}},[_c('v-card',{style:(_vm.cardStyle)},[_c('Echart',{attrs:{"options":{
            title: {
              text: '客户性别年龄分布'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: ['男', '女']
            },
            grid: {
              top: 60, left: 46, bottom: 32, right: 32
            },
            xAxis: {
              type: 'category',
              data: _vm.ageStatisticsX,
              name: '岁'
            },
            yAxis: {
              type: 'value',
              name: '人'
            },
            onClick: _vm.handleAgeGenderClick,
            series: _vm.ageStatisticsSeries
          }}})],1)],1),_c('v-col',[_c('CommonTools',{staticStyle:{"margin-top":"0"}})],1)],1)],1),_c('v-col',{attrs:{"sm":"3"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"padding":"8px 16px 0","font-weight":"bold"}},[_vm._v(" 待办事项 ")]),_c('v-list',{staticStyle:{"height":"729px","overflow":"auto"}},[_vm._l((_vm.todoData),function(todo,i){return [_c('v-divider',{key:`${i}-${todo.name}`}),_c('v-list-item',{key:i},[_c('v-list-item-content',[_vm._v(" "+_vm._s(todo.name)+" "),_c('v-list-item-subtitle',{style:({color: todo.color})},[_vm._v(_vm._s(todo.desc))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":() => _vm.handleTodo(todo)}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }