<template>
    <div class="v-application" v-transfer-dom :data-transfer="transfer">
        <transition name="fade">
            <div :class="maskClasses" :style="maskStyle" v-show="visible" v-if="mask" @click="handleMask"></div>
        </transition>
        <div :class="wrapClasses" @click="handleWrapClick">
            <transition :name="transitionName">
                <div :class="classes" :style="mainStyles" v-show="visible">
                    <div :class="contentClasses" ref="content">
                        <a class="ivu-drawer-close" v-if="closable" @click="close">
                            <slot name="close">
                                <v-icon style="color: #ffffff;font-size:32px;">mdi-close</v-icon>
                            </slot>
                        </a>
                        <div :class="[prefixCls + '-header']" v-if="showHead"><slot name="header"><div :class="[prefixCls + '-header-inner']">{{ title }}</div></slot></div>
                        <div :class="[prefixCls + '-body']" :style="styles"><slot></slot></div>
                    </div>
                    <div class="ivu-drawer-drag" :class="'ivu-drawer-drag-' + placement" v-if="draggable && (placement === 'left' || placement === 'right')" @mousedown="handleTriggerMousedown" @mouseleave="handleTriggerMouseleave">
                        <slot name="trigger">
                            <div class="ivu-drawer-drag-move-trigger">
                                <div class="ivu-drawer-drag-move-trigger-point">
                                    <i></i><i></i><i></i><i></i><i></i>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    import { oneOf, findBrothersComponents, findComponentsUpward } from './assist';
    import TransferDom from './transfer-dom';
    import Emitter from './emitter';
    import ScrollbarMixins from './mixins-scrollbar';

    import { on, off } from './dom';

    const prefixCls = 'ivu-drawer';

    export default {
        name: 'Drawer',
        mixins: [ Emitter, ScrollbarMixins ],
        directives: { TransferDom },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            title: {
                type: String
            },
            width: {
                type: [Number, String],
                default: 256
            },
            // 4.6.0
            height: {
                type: [Number, String],
                default: 256
            },
            closable: {
                type: Boolean,
                default: true
            },
            maskClosable: {
                type: Boolean,
                default: true
            },
            mask: {
                type: Boolean,
                default: true
            },
            maskStyle: {
                type: Object
            },
            styles: {
                type: Object
            },
            scrollable: {
                type: Boolean,
                default: false
            },
            // 4.6.0 add top, bottom
            placement: {
                validator (value) {
                    return oneOf(value, ['left', 'right', 'top', 'bottom']);
                },
                default: 'right'
            },
            zIndex: {
                type: Number,
                default: 10000
            },
            transfer: {
                type: Boolean,
                default () {
                    return !this.$IVIEW || this.$IVIEW.transfer === '' ? true : this.$IVIEW.transfer;
                }
            },
            className: {
                type: String
            },
            inner: {
                type: Boolean,
                default: false
            },
            // Whether drag and drop is allowed to adjust width
            draggable: {
                type: Boolean,
                default: false
            },
            beforeClose: Function,
        },
        data () {
            return {
                prefixCls: prefixCls,
                visible: this.value,
                wrapShow: false,
                showHead: true,
                canMove: false,
                dragWidth: this.width,
                dragHeight: this.height,
                wrapperWidth: this.width,
                wrapperHeight: this.height,
                wrapperLeft: 0,
                minWidth: 520,
                minHeight: 256
            };
        },
        computed: {
            wrapClasses () {
                return [
                    `${prefixCls}-wrap`,
                    {
                        [`${prefixCls}-hidden`]: !this.wrapShow,
                        [`${this.className}`]: !!this.className,
                        [`${prefixCls}-no-mask`]: !this.mask,
                        [`${prefixCls}-wrap-inner`]: this.inner,
                        [`${prefixCls}-wrap-dragging`]: this.canMove
                    }
                ];
            },
            mainStyles () {
                let style = {};

                if (this.placement === 'left' || this.placement === 'right') {
                    const width = parseInt(this.dragWidth);

                    const styleWidth = {
                        width: width <= 100 ? `${width}%` : `${width}px`
                    };

                    Object.assign(style, styleWidth);
                } else {
                    const height = parseInt(this.dragHeight);

                    const styleHeight = {
                        height: height <= 100 ? `${height}%` : `${height}px`
                    };

                    Object.assign(style, styleHeight);
                }

                return style;
            },
            contentClasses () {
                return [
                    `${prefixCls}-content`,
                    {
                        [`${prefixCls}-content-no-mask`]: !this.mask
                    }
                ];
            },
            classes () {
                return [
                    `${prefixCls}`,
                    `${prefixCls}-${this.placement}`,
                    {
                        [`${prefixCls}-no-header`]: !this.showHead,
                        [`${prefixCls}-inner`]: this.inner
                    }
                ];
            },
            maskClasses () {
                return [
                    `${prefixCls}-mask`,
                    {
                        [`${prefixCls}-mask-inner`]: this.inner
                    }
                ];
            },
            transitionName () {
                if (this.placement === 'left' || this.placement === 'right') return `move-${this.placement}`;
                else if (this.placement === 'top') return 'move-up';
                else return 'move-down';
            }
        },
        methods: {
            close () {
                if (!this.beforeClose) {
                    return this.handleClose();
                }

                const before = this.beforeClose();

                if (before && before.then) {
                    before.then(() => {
                        this.handleClose();
                    });
                } else {
                    this.handleClose();
                }
            },
            handleClose () {
                this.visible = false;
                this.$emit('input', false);
                this.$emit('on-close');
            },
            handleMask () {
                if (this.maskClosable && this.mask) {
                    this.close();
                }
            },
            handleWrapClick (event) {
                // use indexOf,do not use === ,because ivu-modal-wrap can have other custom className
                const className = event.target.getAttribute('class');
                if (className && className.indexOf(`${prefixCls}-wrap`) > -1) this.handleMask();
            },
            handleMousemove (event) {
                if (!this.canMove || !this.draggable) return;
                // 更新容器宽度和距离左侧页面距离，如果是window则距左侧距离为0
                this.handleSetWrapperWidth();
                const left = event.pageX - this.wrapperLeft;
                // 如果抽屉方向为右边，宽度计算需用容器宽度减去left
                let width = this.placement === 'right' ? this.wrapperWidth - left : left;
                // 限定最小宽度
                width = Math.max(width, parseFloat(this.minWidth));
                event.atMin = width === parseFloat(this.minWidth);
                // 如果当前width不大于100，视为百分比
                if (width <= 100) width = (width / this.wrapperWidth) * 100;
                this.dragWidth = width;
                this.$emit('on-resize-width', parseInt(this.dragWidth));
            },
            handleSetWrapperWidth () {
                const {
                    width,
                    left
                } = this.$el.getBoundingClientRect();
                this.wrapperWidth = width;
                this.wrapperLeft = left;
            },
            handleMouseup () {
                if (!this.draggable) return;
                this.canMove = false;
            },
            handleTriggerMouseleave () {
                if (!this.draggable) return;
                this.canMove = false;
            },
            handleTriggerMousedown () {
                this.canMove = true;
                // 防止鼠标选中抽屉中文字，造成拖动trigger触发浏览器原生拖动行为
                window.getSelection().removeAllRanges();
            },
        },
        mounted () {
            if (this.visible) {
                this.wrapShow = true;
            }

            let showHead = true;

            if (this.$slots.header === undefined && !this.title) {
                showHead = false;
            }

            this.showHead = showHead;

            on(document, 'mousemove', this.handleMousemove);
            on(document, 'mouseup', this.handleMouseup);
            this.handleSetWrapperWidth();
        },
        beforeDestroy () {
            off(document, 'mousemove', this.handleMousemove);
            off(document, 'mouseup', this.handleMouseup);
            this.removeScrollEffect();
        },
        watch: {
            value (val) {
                this.visible = val;
            },
            visible (val) {
                if (val === false) {
                    this.timer = setTimeout(() => {
                        this.wrapShow = false;
                        // #4831 Check if there are any drawers left at the parent level
                        const brotherDrawers = findBrothersComponents(this, 'Drawer') || [];
                        const parentDrawers = findComponentsUpward(this, 'Drawer') || [];

                        const otherDrawers = [].concat(brotherDrawers).concat(parentDrawers);

                        const isScrollDrawer = otherDrawers.some(item => item.visible && !item.scrollable);

                        if (!isScrollDrawer) {
                            this.removeScrollEffect();
                        }
                    }, 300);
                } else {
                    if (this.timer) clearTimeout(this.timer);
                    this.wrapShow = true;
                    if (!this.scrollable) {
                        this.addScrollEffect();
                    }
                }
                this.broadcast('Table', 'on-visible-change', val);
                this.broadcast('Slider', 'on-visible-change', val);  // #2852
                this.$emit('on-visible-change', val);
            },
            scrollable (val) {
                if (!val) {
                    this.addScrollEffect();
                } else {
                    this.removeScrollEffect();
                }
            },
            title (val) {
                if (this.$slots.header === undefined) {
                    this.showHead = !!val;
                }
            },
            width (val) {
                this.dragWidth = val;
            },
            height (val) {
                this.dragHeight = val;
            }
        }
    };
</script>
<style lang="scss">

.ivu-drawer {
    width: auto;
    height: 100%;
    position: fixed;
    top: 0;
    .vlayout {
        margin: 0 20px!important;
    }
    .v-form  .layout {
        margin: 0 4px!important;
        .flex {
            width: 100%!important;
            max-width: 100%!important;
            flex-basis: 100%!important;
            padding: 0!important;
        }
        .v-messages {
            display: none;
        }
    }
    .v-text-field .v-label--active {
        left: -8px!important;
    }
}

.ivu-drawer-inner {
    position: absolute
}

.ivu-drawer-left {
    left: 0
}

.ivu-drawer-right {
    right: 0
}

.ivu-drawer-bottom,.ivu-drawer-top {
    width: 100%;
    height: auto;
    top: auto
}

.ivu-drawer-bottom {
    bottom: 0
}

.ivu-drawer-hidden {
    display: none!important
}

.ivu-drawer-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.ivu-drawer-wrap-inner {
    position: absolute;
    overflow: hidden
}

.ivu-drawer-wrap-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ivu-drawer-wrap * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent
}

.ivu-drawer-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(55,55,55,.6);
    height: 100%;
    z-index: 1000
}

.ivu-drawer-mask-hidden {
    display: none
}

.ivu-drawer-mask-inner {
    position: absolute
}

.ivu-drawer-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #fff;
    border: 0;
    background-clip: padding-box;
    box-shadow: 0 4px 12px rgba(0,0,0,.15)
}

.ivu-drawer-content-no-mask {
    pointer-events: auto
}

.ivu-drawer-header {
    padding: 21px 16px;
    line-height: 1;
    background: #1976D2;
}

.ivu-drawer-header-inner,.ivu-drawer-header p {
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.ivu-drawer-close {
    z-index: 1;
    position: absolute;
    right: 16px;
    top: 16px;
    overflow: hidden;
    cursor: pointer;
}

.ivu-drawer-close .ivu-icon-ios-close {
    font-size: 32px;
    transition: color .2s ease;
    position: relative;
    top: 1px
}

.ivu-drawer-close .ivu-icon-ios-close:hover {
    color: #444
}

.ivu-drawer-body {
    width: 100%;
    height: calc(100% - 51px);
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
    position: absolute;
    overflow: auto
}

.ivu-drawer-no-header .ivu-drawer-body {
    height: 100%
}

.ivu-drawer-no-mask {
    pointer-events: none
}

.ivu-drawer-no-mask .ivu-drawer-drag {
    pointer-events: auto
}

.ivu-drawer-drag {
    top: 0;
    height: 100%;
    width: 0;
    position: absolute
}

.ivu-drawer-drag-left {
    right: 0
}

.ivu-drawer-drag-bottom,.ivu-drawer-drag-top {
    top: auto;
    width: 100%;
    height: 0
}

.ivu-drawer-drag-top {
    bottom: 0
}

.ivu-drawer-drag-bottom {
    top: 0
}

.ivu-drawer-drag-move-trigger {
    width: 8px;
    height: 100px;
    line-height: 100px;
    position: absolute;
    top: 50%;
    background: #f3f3f3;
    transform: translate(-50%,-50%);
    border-radius: 4px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
    cursor: col-resize
}

.ivu-drawer-drag-move-trigger-point {
    display: inline-block;
    width: 50%;
    transform: translateX(50%)
}

.ivu-drawer-drag-move-trigger-point i {
    display: block;
    border-bottom: 1px solid silver;
    padding-bottom: 2px
}

.ivu-drawer-drag-bottom .ivu-drawer-drag-move-trigger,.ivu-drawer-drag-top .ivu-drawer-drag-move-trigger {
    width: 100px;
    height: 8px;
    line-height: 8px;
    top: auto;
    left: 50%;
    cursor: row-resize
}

.ivu-drawer-drag-bottom .ivu-drawer-drag-move-trigger-point,.ivu-drawer-drag-top .ivu-drawer-drag-move-trigger-point {
    width: 100%;
    height: 50%;
    transform: translateY(-75%);
    text-align: center
}

.ivu-drawer-drag-bottom .ivu-drawer-drag-move-trigger-point i,.ivu-drawer-drag-top .ivu-drawer-drag-move-trigger-point i {
    display: inline-block;
    border-bottom: inherit;
    width: 1px;
    height: 100%;
    border-left: 1px solid silver;
    padding-bottom: inherit;
    margin-left: 2px
}
.fade-appear,.fade-enter-active,.fade-leave-active {
    animation-duration: .15s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.fade-appear,.fade-enter-active {
    animation-name: ivuFadeIn;
    animation-play-state: running
}

.fade-leave-active {
    animation-name: ivuFadeOut;
    animation-play-state: running
}

.fade-appear,.fade-enter-active {
    opacity: 0
}

.fade-appear,.fade-enter-active,.fade-leave-active {
    animation-timing-function: linear
}

@keyframes ivuFadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes ivuFadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.move-up-appear,.move-up-enter-active,.move-up-leave-active {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-up-appear,.move-up-enter-active {
    animation-name: ivuMoveUpIn;
    animation-play-state: running
}

.move-up-leave-active {
    animation-name: ivuMoveUpOut;
    animation-play-state: running
}

.move-up-appear,.move-up-enter-active {
    opacity: 0;
    animation-timing-function: ease-in-out
}

.move-up-leave-active {
    animation-timing-function: ease-in-out
}

.move-down-appear,.move-down-enter-active,.move-down-leave-active {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-down-appear,.move-down-enter-active {
    animation-name: ivuMoveDownIn;
    animation-play-state: running
}

.move-down-leave-active {
    animation-name: ivuMoveDownOut;
    animation-play-state: running
}

.move-down-appear,.move-down-enter-active {
    opacity: 0;
    animation-timing-function: ease-in-out
}

.move-down-leave-active {
    animation-timing-function: ease-in-out
}

.move-left-appear,.move-left-enter-active,.move-left-leave-active {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-left-appear,.move-left-enter-active {
    animation-name: ivuMoveLeftIn;
    animation-play-state: running
}

.move-left-leave-active {
    animation-name: ivuMoveLeftOut;
    animation-play-state: running
}

.move-left-appear,.move-left-enter-active {
    opacity: 0;
    animation-timing-function: ease-in-out
}

.move-left-leave-active {
    animation-timing-function: ease-in-out
}

.move-right-appear,.move-right-enter-active,.move-right-leave-active {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-play-state: paused
}

.move-right-appear,.move-right-enter-active {
    animation-name: ivuMoveRightIn;
    animation-play-state: running
}

.move-right-leave-active {
    animation-name: ivuMoveRightOut;
    animation-play-state: running
}

.move-right-appear,.move-right-enter-active {
    opacity: 0;
    animation-timing-function: ease-in-out
}

.move-right-leave-active {
    animation-timing-function: ease-in-out
}

@keyframes ivuMoveDownIn {
    0% {
        transform-origin: 0 0;
        transform: translateY(100%);
        opacity: 0
    }

    to {
        transform-origin: 0 0;
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes ivuMoveDownOut {
    0% {
        transform-origin: 0 0;
        transform: translateY(0);
        opacity: 1
    }

    to {
        transform-origin: 0 0;
        transform: translateY(100%);
        opacity: 0
    }
}

@keyframes ivuMoveLeftIn {
    0% {
        transform-origin: 0 0;
        transform: translateX(-100%);
        opacity: 0
    }

    to {
        transform-origin: 0 0;
        transform: translateX(0);
        opacity: 1
    }
}

@keyframes ivuMoveLeftOut {
    0% {
        transform-origin: 0 0;
        transform: translateX(0);
        opacity: 1
    }

    to {
        transform-origin: 0 0;
        transform: translateX(-100%);
        opacity: 0
    }
}

@keyframes ivuMoveRightIn {
    0% {
        opacity: 0;
        transform-origin: 0 0;
        transform: translateX(100%)
    }

    to {
        opacity: 1;
        transform-origin: 0 0;
        transform: translateX(0)
    }
}

@keyframes ivuMoveRightOut {
    0% {
        transform-origin: 0 0;
        transform: translateX(0);
        opacity: 1
    }

    to {
        transform-origin: 0 0;
        transform: translateX(100%);
        opacity: 0
    }
}

@keyframes ivuMoveUpIn {
    0% {
        transform-origin: 0 0;
        transform: translateY(-100%);
        opacity: 0
    }

    to {
        transform-origin: 0 0;
        transform: translateY(0);
        opacity: 1
    }
}

@keyframes ivuMoveUpOut {
    0% {
        transform-origin: 0 0;
        transform: translateY(0);
        opacity: 1
    }

    to {
        transform-origin: 0 0;
        transform: translateY(-100%);
        opacity: 0
    }
}
</style>
