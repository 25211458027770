<template>
  <v-breadcrumbs class="pl-3 pb-4 pt-1 pr-0" :items="list">
    <v-icon slot="divider" >chevron_right</v-icon>
    <template slot="item" slot-scope="props">
        <template v-if="isLast(props.item)">
          <span class="active content--text body-2" >{{ showName(props.item) }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="primary" left v-on="on" @click="doBack" v-show="showBack">mdi-chevron-left-circle-outline</v-icon>
            </template>
            <span>返回</span>
          </v-tooltip>
        </template>
        <router-link :to="props.item" class="v-breadcrumbs__item body-2" v-else>{{ showName(props.item) }}</router-link>
    </template>
  </v-breadcrumbs>
</template>


<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    showBack() {
      return this.$route.name != '首页'
    }
  },
  methods: {
    isLast(item) {
      return this.list[this.list.length - 1].name === item.name;
      // return index === this.list.length - 1;
    },
    showName(item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label;
      }
      if (item.name) {
        item = item.name;
      }
      return item;
    },
    doBack() {
      this.$router.back(-1)
    }
  }
};
</script>
