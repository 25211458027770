<template>
    <v-container fluid>
        <v-stepper v-model="step" vertical>
            <v-stepper-step step="1" :complete="step>1">基本信息</v-stepper-step>
            <v-stepper-content step="1" class="pl-1">
                <v-form ref="formStep1" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
                    <v-card flat>
                        <v-card-text style="padding-top: 0px; padding-bottom: 0px">
                            <v-container fluid>
                                <v-layout wrap>
                                    <v-flex xs12 style="height: 60px">
                                        <span v-show="false">{{ isShow }}</span>
                                        <v-text-field label="姓名" v-model="individual.individual_name"
                                                      :rules="rules.individual_name"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                        <v-radio-group v-model="individual.individual_gender" row
                                                       :rules="rules.individual_gender" label="性别：">
                                            <v-radio label="男" value="MALE" color="primary"></v-radio>
                                            <v-radio label="女" value="FEMALE" color="primary"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                        <v-text-field label="出生日期"
                                                      v-model="individual.individual_birthday"
                                                      :rules="rules.individual_birthday"
                                                      hint="格式YYYY-MM-DD"
                                                      required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                      <v-text-field label="手机号码（接收电子报告的唯一凭证）" v-model="individual.phone_number" :rules="rules.phone_number"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 style="height:80px">
                                        <v-text-field label="客户识别号（可选）："
                                                      v-model="individual.individual_additional_no"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 v-if="actual_individual_tags.length > 1">
                                        <v-input style="height: 24px" v-model="individual.individual_tags">
                                            <span>产品：</span></v-input>
                                        <v-layout wrap>
                        <span v-for="tag in actual_individual_tags" :key="tag.sid"
                              style="padding-right: 10px; padding-left: 4px">
                          <v-checkbox v-model="individual.individual_tags" :label="tag.tag_name" :value="tag.sid"
                                      color="primary"></v-checkbox>
                        </span>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-dialog v-model="showDialog" persistent max-width="400">
                                <template v-slot:activator="{ click}">
                                    <v-btn color="teal" v-on="click" @click.stop="doCheckStep1" width="100px"><span
                                            class="white--text">下一步</span></v-btn>
                                </template>
                                <v-card v-if="showDialog">
                                    <v-card-title style="height: 60px" class="text-subtitle-2"><span
                                            class="text-subtitle-2 orange--text text--darken-3">发现相似信息的客户</span>
                                    </v-card-title>
                                    <v-card-text><span class="text-body-2">您要创建的客户可能已经在您的机构中建过档，如果是同一个客户，您不需要重复建档，您可以点击下面的客户姓名查看具体信息，或者你也可以选择继续创建该客户信息
                      </span></v-card-text>
                                    <v-card-title v-for="customer in records" :key="customer.sid">
                                        <span class="text-caption">姓名：</span>
                                        <router-link v-bind:to="{ name: '客户信息', params: { }}"
                                                     class="v-breadcrumbs__item"
                                                     @mousedown.native="doSetParams(customer.sid)">
                                            <span class="text-caption">{{ customer.individual_name }}</span>
                                        </router-link>
                                        <v-spacer></v-spacer>
                                        <span class="text-caption">初诊医生：</span>
                                        <span class="text-caption">{{ customer.doctor.doctor_name }}</span>
                                        <v-spacer></v-spacer>
                                        <span class="text-caption">创建日期：</span>
                                        <span class="text-caption">{{ customer.created_time | formatDateDash }}</span>
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="teal" text @click.native="showDialog = false">不创建了</v-btn>
                                        <v-btn color="primary" text @click.native="step=2; showDialog=false">继续创建
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- <v-btn color="primary" @click="step = 2">保存</v-btn> -->
                            <v-btn class="ml-2" color="grey" @click="doBack">取消</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-stepper-content>
            <v-stepper-step step="2">扩展信息
                <small>可选</small>
            </v-stepper-step>
            <v-stepper-content step="2" class="pl-1">
                <v-form ref="formStep2" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
                    <v-card flat>
                        <v-card-text style="padding-top: 0px; padding-bottom: 20px">
                            <v-container fluid>
                                <v-layout wrap>
                                    <v-flex xs12 style="height: 60px">
                                        <span v-show="false">{{ isShow }}</span>
                                        <v-select :items="folks" label="民族" v-model="individual.folk"
                                                  :rules="rules.folk"></v-select>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                        <v-radio-group v-model="individual.marital_status" label="婚姻状况：" row
                                                       :rules="rules.marital_status">
                                            <v-radio label="已婚" value="已婚" color="primary"></v-radio>
                                            <v-radio label="未婚" value="未婚" color="primary"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                        <v-radio-group v-model="individual.education" label="学历：" row
                                                       :rules="rules.education">
                                            <v-radio label="学士" value="学士" color="primary"></v-radio>
                                            <v-radio label="硕士" value="硕士" color="primary"></v-radio>
                                            <v-radio label="博士" value="博士" color="primary"></v-radio>
                                            <v-radio label="其他" value="其他" color="primary"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                    <v-flex lg4 md6 xs12 style="height: 60px">
                                        <v-text-field label="职业" v-model="individual.profession"
                                                      :rules="rules.profession"></v-text-field>
                                    </v-flex>
                                    <v-flex lg4 md6 xs12 style="height: 60px">
                                        <v-text-field label="籍贯" v-model="individual.native_place"
                                                      :rules="rules.native_place"></v-text-field>
                                    </v-flex>
                                    <v-flex lg4 md6 xs12 style="height: 60px">
                                        <v-text-field label="住址" v-model="individual.address"></v-text-field>
                                    </v-flex>
                                    <v-flex lg4 md6 xs12 style="height: 60px">
                                        <v-text-field label="邮政编码" v-model="individual.postcode"></v-text-field>
                                    </v-flex>

                                    <v-flex lg4 md6 xs12 style="height: 60px">
                                        <v-text-field label="一级亲属联系电话"
                                                      v-model="individual.second_phone_number"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 style="height: 60px">
                                        <v-radio-group v-model="individual.case_source" label="病例来源：" row>
                                            <v-radio label="门诊" value="门诊" color="primary"></v-radio>
                                            <v-radio label="病房" value="病房" color="primary"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="teal" @click="step = 1" width="100px"><span class="white--text">上一步</span>
                            </v-btn>
                            <v-btn color="primary" @click.stop="doSubmit" :disabled="!valid || dialog_save"
                                   :loading="dialog_save">保存
                            </v-btn>
                            <v-btn color="grey" @click="doBack">取消</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-stepper-content>
        </v-stepper>
    </v-container>
</template>
<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";

  export default {
    data() {
      return {
        step: 1,
        valid: true,
        showDialog: false,
        dialog_save: false,
        isShow: false,
        org: {},
        actual_individual_tags: [],
        individual: {
          individual_name: '',
          individual_gender: '',
          individual_birthday: '',
          phone_number: '',
          address: '',
          postcode: '',
          folk: '',
          profession: '',
          education: '',
          native_place: '',
          marital_status: '',
          second_phone_number: '',
          individual_additional_no: '',
          case_source: '',
          individual_tags: [],
        },
        rules: {
          individual_name: [v => !!v || '不能为空'],
          individual_gender: [v => !!v || '不能为空'],
          phone_number: [v => /^\s{0}$|^((17[0-9])|(14[0-9])|(13[0-9])|(15[0-9])|(19[0-9])|(18[0-9]))\d{8}$/.test(v) || '手机号码格式错误'],
          individual_birthday: [
            v => !!v || '不能为空，格式YYYY-MM-DD',
            v => /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(v) || "日期格式错误"
          ],
          folk: [],
          native_place: [],
          marital_status: [],
          education: [],
          profession: [],
          product_tags: [v => v.length >= 1 || "至少选择1项"]
        },
        records: [],
        tags: [],
        folks: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '僳僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族', '其他']
      }
    },
    methods: {
      doSetExtraRules() {
        let isSetRules = false
        for (let tag of this.actual_individual_tags) {
          for (let selected_tag of this.individual.individual_tags) {
            if (tag.sid === selected_tag) {
              isSetRules = tag.tag_no === 'TCIC-001'
            }
          }
        }
        if (this.org.org_tags.length === 1) {
          for (let tag of this.tags) {
            for (let ot of this.org.org_tags) {
              if (tag.sid === ot) {
                isSetRules = tag.tag_no === 'TCIC-001'
              }
            }

          }
        }
        if (isSetRules) {
          this.rules.folk.push(v => !!v || '不能为空')
          this.rules.native_place.push(v => !!v || '不能为空')
          this.rules.marital_status.push(v => !!v || '不能为空')
          this.rules.education.push(v => !!v || '不能为空')
          this.rules.profession.push(v => !!v || '不能为空')
        } else {
          this.rules.folk = []
          this.rules.native_place = []
          this.rules.marital_status = []
          this.rules.education = []
          this.rules.profession = []
        }
        // this.isShow = !this.isShow
      },
      async doCheckStep1() {
        // this.$refs.form.resetValidation()

        if (this.$refs.formStep1.validate()) {
          this.records = await api.get("individuals/get_same_individuals/?n=" + this.individual.individual_name +
            '&g=' + this.individual.individual_gender + '&b=' + this.individual.individual_birthday, null)
          if (this.records.length > 0) {
            this.showDialog = true
          } else {
            this.step = 2
            this.doSetExtraRules()
          }
        }
      },
      doBack() {
        this.$router.replace({
          name: "客户列表",
          params: {}
        });
      },
      doSetParams(value) {
        let params = this.$store.getters.getParams;
        params.id = value;
        this.$store.dispatch("setParams", params);
      },
      async doSubmit() {

        if (this.$refs.formStep2.validate()) {
          try {
            await api.post("individuals/?", this.individual)
            this.dialog_save = false
            nf.showSuccessMsg("数据保存成功！");
            await this.$router.replace({
              name: "客户列表",
              params: {}
            });
          } catch (error) {
            nf.showErrorMsg(error)
            this.dialog_save = false
          }
        }
      },
      async doLoadData() {
        this.tags = await api.get("tag_infos/get_individual_type_list/?", null)
        this.org = await api.get("organizations/get_org_info_by_current_user/?", null)
        for (let org_tag of this.org.org_tags) {
          for (let tag of this.tags) {
            if (org_tag === tag.sid) {
              this.actual_individual_tags.push(tag)
            }
          }
        }
      }
    },
    mounted() {
      this.doLoadData()
    }
  }
</script>