<template>
  <div class="vernier-caliper">
    <div
      v-for="(item, i) in colorList"
      :key="i"
      :style="{
        width: `${item.width * 100}%`,
        background: item.color
      }"
      class="vernier-caliper-block"
    />
    <div
      :style="{
        left: `${current * 100}%`,
        borderTopColor: currentColor
      }"
      class="vernier-caliper-cursor"
    />
  </div>
</template>
<script>
export default {
  name: 'vernier-caliper',
  props: {
    color: {
      type: Array,
      default: () => [
        [0.7, '#FF9802'],
        [0.2, '#029688'],
        [0.1, '#8BC34B']
      ]
    },
    current: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    currentColor() {
      const { colorList, current } = this
      const c = colorList.find(c => current >= c.min && current <= c.max)
      if (c) {
        return c.color
      } else {
        return ''
      }
    },
    colorList() {
      const colorList = []
      let min = 0
      this.color.map(c => {
        const [max, color] = c
        colorList.push({
          min,
          max: max,
          width: max - min,
          color
        })
        min = max
      })
      return colorList
    }
  }
}
</script>
<style scoped lang="scss">
.vernier-caliper {
  display: flex;
  align-items: center;
  padding: 12px 0 8px;
  position: relative;
  &-block {
    height: 3px;
  }
  &-cursor {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid red;
    transition: left .3s;
    border-radius: 3px;
    transform: translate(-50%, 0);
  }
}
</style>