import { ActionContext } from 'vuex'

export interface State {
  permissionList: [],
  permissionMap: {},
}
const state: State = {
  permissionList: [],
  permissionMap: {},
}

const mutations = {
  setPermissionList(state: State, result: []) {
    state.permissionList = result;
  },
  setPermissionMap(state: State, data: Object) {
    state.permissionMap = data
  }
}

const getters = {
  permissionList(state: State) {
    return state.permissionList
  },
  getPermission(state: State) {
    return (module: string, permission: string) => {
      if (!state.permissionMap[module]) {
        return false
      }
      return state.permissionMap[module].includes(permission)
    }
  }
}

const actions = {
  setPermissionList(context: ActionContext<State, any>, result: {children: Object, icon: string, name: string, order_no: number, path: string}[]) {
    context.commit('setPermissionList', result)
    const permissionMap = {}
    result.map(r => {
      const children = r.children || {}
      Object.keys(children).map(c => {
        const [module, permission] = c.split('.')
        const [permissionName, moduleName] = permission.split('_')
        if (!permissionMap[moduleName]) {
          permissionMap[moduleName] = []
        }
        permissionMap[moduleName].push(permissionName)
      })
    })
    context.commit('setPermissionMap', permissionMap)
  }
}

const permission = {
  state,
  mutations,
  getters,
  actions
}
export default permission;

