<template>
  <v-card class="manage-list">
    <v-btn v-if="has_add_right" fab text small color="grey darken-2" class="newbtn" @click="$router.push({ name: '管理计划详情', params: { id: individualId, planId: 0 }})">
      <v-icon color="primary">mdi-plus-circle</v-icon>
    </v-btn>
    <v-card-text>
      <v-data-table
        :headers="[
          { text: '计划名称', value: 'name' },
          { text: '计划开始日期', value: 'plan_start_date', align: 'center' },
          { text: '计划结束日期', value: 'plan_end_date', align: 'center' },
          { text: '健康管理师', value: 'doctor_name', align: 'center' },
          { text: '创建日期', value: 'created_time', align: 'center' },
          { text: '状态', value: 'state', align: 'center' },
          { text: '完成日期', value: 'actually_end_date', align: 'center' },
          { text: '', value: 'k', align: 'center' }
        ]"
        :items="data"
        :items-per-page="10"
        :footer-props="{ itemsPerPageText : '每页行数：', itemsPerPageOptions: [5, 10]}"
        disable-sort
      >
        <template v-slot:item.name="{ item }">
          <router-link v-if="has_change_right" v-bind:to="{ name: '管理计划详情', params: { id: individualId, planId: item.sid }}" class="v-breadcrumbs__item">
            {{item.name}}
          </router-link>
          <div v-else>{{item.name}}</div>
        </template>
        <template v-slot:item.plan_start_date="{ item }">
          {{formatDate(item.start_date || item.plan_start_date)}}
        </template>
        <template v-slot:item.plan_end_date="{ item }">
          {{formatDate(item.end_date || item.plan_end_date)}}
        </template>
        <template v-slot:item.created_time="{ item }">
          {{formatDate(item.created_time)}}
        </template>
        <template v-slot:item.state="{ item }">
          {{STATE_MAP[item.state]}}
        </template>
        <template v-slot:item.k="{ item }">
          <template v-if="has_change_right">
            <v-btn color="info" small dark class="mr-2" :loading="item.loading" @click="() => handleDownLoadProspectus(item)">
              管理计划书
            </v-btn>
            <v-btn color="info" small v-if="!['R'].includes(item.state) && has_action_view_right" dark class="mr-2" :loading="item.loading" @click="() => handleToActionList(item)">
              活动列表
            </v-btn>
            <v-btn v-if="item.state == 'R'" class="mr-2" color="primary" small dark :loading="item.loading" @click="() => handleShowStartPicker(item)">
              开始
            </v-btn>
            <v-btn v-if="['E', 'O'].includes(item.state)" class="mr-2" color="success" small dark :loading="item.loading" @click="() => handleCompletePlan(item)">
              完成
            </v-btn>
            <v-btn v-if="['E', 'O'].includes(item.state)" class="mr-2" color="error" small dark :loading="item.loading" @click="() => handleShowDialogStop(item)">
              终止
            </v-btn>
          </template>
          <v-btn v-if="['R', 'T'].includes(item.state) && has_delete_right" class="mr-2" color="error" small dark :loading="item.loading" @click="() => handleShowDialogDelete(item)">
            删除
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="showDate" width="300">
      <v-card>
        <v-toolbar color="primary" dark>{{showDateTitle}}</v-toolbar>
        <v-card-text class="mt-4">
          <v-menu
            v-model="showPicker"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                label="选择日期"
                v-model="startDate"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              scrollable
              locale="cn"
              @change="() => { showPicker = false }"
            />
          </v-menu>
          <p style="font-size:12px">一旦开始将视为计划开始生效，将无法修改管理计划的任何内容。</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="handleStartPlan">确定</v-btn>
          <v-btn color="primary" @click="showDate = false">关闭</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogDelete" persistent max-width="320">
      <v-card>
        <v-card-title class="text-subtitle-2">您确认要删除该记录吗?</v-card-title>
        <v-card-text>删除后该记录将无法还原，请您谨慎操作！</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="showDialogDelete = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleDelete">我想好了</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogStop" persistent max-width="320">
      <v-card>
        <v-card-title class="text-subtitle-2">您确认要终止管理计划【{{tempPlan.name}}】吗?</v-card-title>
        <v-card-text>
          操作后将无法还原，请您谨慎操作！
          <v-textarea
            label="终止原因"
            outline
            auto-grow
            max-length="1000"
            v-model="tempPlan.termination_reason"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="showDialogStop = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleTermination">我想好了</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { api } from '@/utils/api'
import nf from "@/utils/notification";
import { formatDate } from "@/utils/date"
export default {
  name: 'ManagePlanList',
  inject: ['individualId', 'instance'],
  data() {
    return {
      formatDate,
      data: [],
      STATE_MAP: {
        R: '未开始',
        E: '执行中',
        C: '完成',
        O: '超时',
        T: '终止'
      },
      showDate: false,
      showDateTitle: '',
      startDate: formatDate(new Date()),
      showPicker: false,
      showDialogDelete: false,
      showDialogStop: false,
      tempPlan: {}
    }
  },
  computed: {
    has_add_right() {
      return this.$store.getters.getPermission('manageplan', 'add')
    },
    has_change_right() {
      return this.$store.getters.getPermission('manageplan', 'change')
    },
    has_delete_right() {
      return this.$store.getters.getPermission('manageplan', 'delete')
    },
    has_action_view_right() {
      return this.$store.getters.getPermission('manageplanaction', 'view')
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.data = await api.get(`health_manage/?i=${this.individualId}`)
      } catch(e) {
        // nf.showErrorMsg(`获取管理计划失败`)
        console.error(e)
      }
    },
    async handleDownLoadProspectus(plan) {
      plan.loading = true
      this.$forceUpdate()
      try {
        const { report_date, report_id } = await api.post(`health_manage/create_manage_plan_report/`, {
          individual_id: this.individualId,
          character_instance_id: plan.instance,
          planId: plan.sid
        })
        const pdf = await api.get(`report_instances/${report_id}/get_pdf_report/`, { responseType: "blob" })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([pdf], { type: "application/pdf" }))
        link.setAttribute('download', `${this.instance.customer.individual_name}_健康管理计划书_${report_date.replaceAll(/-/g, '')}.pdf`)
        link.click()
      } catch(e) {
        nf.showErrorMsg(`生成管理计划书失败`)
        console.error(e)
      }
      plan.loading = false
      this.$forceUpdate()
    },
    handleToActionList(plan) {
      this.$router.push({ name: '管理计划活动列表', params: { id: this.individualId, planId: plan.sid }})
    },
    handleShowStartPicker(plan) {
      this.tempPlan = plan
      this.showDate = true
      this.showDateTitle = '开始日期'
      this.startDate = formatDate(new Date())
    },
    async handleStartPlan() {
      this.showDate = false
      const plan = this.tempPlan
      plan.loading = true
      try {
        await api.post(`health_manage/start_plan/`, { health_manage_id: plan.sid, start_date: this.startDate })
        this.load()
      } catch(e) {
        // nf.showErrorMsg(`开始管理计划失败`)
        console.error(e)
      }
      plan.loading = false
      this.$forceUpdate()
    },
    handleShowDialogDelete(plan) {
      this.tempPlan = plan
      this.showDialogDelete = true
    },
    async handleDelete() {
      this.showDialogDelete = false
      const plan = this.tempPlan
      plan.loading = true
      try {
        await api.post(`health_manage/delete_plan/`, { plan_id: plan.sid })
        await this.load()
      } catch(e) {
        // nf.showErrorMsg(`删除管理计划失败`)
        console.error(e)
      }
      plan.loading = false
      this.$forceUpdate()
    },
    async handleCompletePlan(plan) {
      plan.loading = true
      try {
        await api.post(`health_manage/complete_plan/`, { plan_id: plan.sid, complete_date: formatDate(new Date()) })
        await this.load()
      } catch(e) {
        // nf.showErrorMsg(`完成管理计划失败`)
        console.error(e)
      }
      plan.loading = false
      this.$forceUpdate()
    },
    handleShowDialogStop(plan) {
      this.tempPlan = plan
      this.showDialogStop = true
    },
    async handleTermination() {
      this.showDialogStop = false
      const plan = this.tempPlan
      plan.loading = true
      try {
        await api.post(`health_manage/termination_plan/`, {
          plan_id: plan.sid,
          termination_date: formatDate(new Date()),
          termination_reason: plan.termination_reason
        })
        await this.load()
      } catch(e) {
        // nf.showErrorMsg(`终止管理计划失败`)
        console.error(e)
      }
      plan.loading = false
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-list {
  position: relative;
  .newbtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>