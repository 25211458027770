<template>
  <div ref="chart" :style="{ height: height }"></div>
</template>
<script>
import 'echarts-wordcloud'
export default {
  name: 'chart-app',
  props: {
    options: {
      type: Object,
      default: () => { return {} }
    },
    height: {
      type: String,
      default: '100%'
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      handler () {
        this.reRender()
      },
      deep: true
    }
  },
  mounted () {
    this.chart = this.$echarts.init(this.$refs['chart'], this.dark && 'dark')
    this.reRender()
    let cacheWidth = this.$refs['chart'].offsetWidth
    const itv = setInterval(() => {
      if(this._isDestroyed) return clearInterval(itv)
      if (cacheWidth !== this.$refs['chart'].offsetWidth) {
        cacheWidth = this.$refs['chart'].offsetWidth
        this.chart.resize()
      }
    }, 100)
  },
  methods: {
    reRender () {
      const {
        chart,
        options
      } = this
      chart.setOption(Object.assign(options, {}))
      chart.off('click')
      chart.on('click', (params) => {
        console.log(params)
        if ('function' === typeof options.onClick) {
          options.onClick(params)
        }
      })
    }
  }
}

</script>
