<template>
  <v-layout row style="margin:0">
    <v-flex d-flex xs12 md12 lg3>
      <v-card width="100%" style="padding: 0 0 16px 8px;">
        <v-toolbar style="height: 40px;line-height:40px;" flat>
          <h4>指标模板</h4>
          <v-spacer></v-spacer>
          <v-switch
            style="margin:0"
            v-model="showHasData"
            label="仅显示有数据模板"
            hide-details
          />
        </v-toolbar>
        <v-card-text>
          <v-radio-group v-model="selected_tab" row style="margin:0">
            <v-radio v-for="(tag, i) in data_tags" :key="tag.tag_no" :label="tag.tag_name" :value="tag.tag_no" :style="{ width: 'calc(50% - 16px)', margin: i%2==0?'0 16px 0 0':'0 0 0 16px' }"/>
          </v-radio-group>
          <template v-if="currentTag">
            <div v-for="item in currentTag.result_items" :key="item.sid" v-show="showHasData ? (item.instance_count > 0): true">
              <RecordTemplateCard :template_item="item" :org_info="org" :customer_info="customer" v-if="item.data_type==='T'" />
              <ReportTemplateCard :report_item="item" :org_info="org" :customer_info="customer" v-if="item.data_type==='R'" />
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex d-flex xs12 md12 lg9>
      <v-card width="100%" style="margin-left:24px">
        <v-toolbar style="height: 40px;line-height:40px;" flat>
          <h4>指标数据</h4>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="[
              { text: '模板名称', value: 'instance_name' },
              { text: '发生日期', value: 'happen_date', align: 'center' },
              { text: '录入人', value: 'doctor_name', align: 'center' },
              { text: '', value: 'xxx', align: 'center' },
            ]"
            :items="checks"
            :items-per-page="10"
            :footer-props="{ itemsPerPageText : '每页行数：', itemsPerPageOptions: [5, 10]}"
            disable-sort
          >
            <template v-slot:item.instance_name="{ item }">
              <router-link v-bind:to="{ name: '查看数据', params: { sid: item.sid }}" class="v-breadcrumbs__item" v-if="item.can_view_detail">
                {{ item.instance_name }}
              </router-link>
              <span v-else>{{ item.instance_name }}</span>
            </template>
            <template v-slot:item.xxx="{ item }">
              <router-link v-bind:to="{ name: '编辑数据', params: { sid: item.sid }}" class="v-breadcrumbs__item" v-if="item.can_be_edit && has_change_right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon color="primary" v-on="on">
                        mdi-pencil-circle-outline
                    </v-icon>
                  </template>
                  <span>编辑</span>
                </v-tooltip>
              </router-link>
              &nbsp;
              <v-tooltip
                bottom
                v-if="item.can_be_edit && can_delete_right"
                
              >
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click="tempItem = item;dialog_delete = true;" class="v-breadcrumbs__item">
                    <v-icon color="error">
                      mdi-trash-can-outline
                    </v-icon>
                  </a>
                </template>
                <span>删除</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog_delete" persistent max-width="290">
      <v-card>
        <v-card-title class="title">您确认要删除该记录吗?</v-card-title>
        <v-card-text>删除后该记录将无法还原，请您谨慎操作</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="dialog_delete = false">取消</v-btn>
          <v-btn color="error" text @click.native="doDelete">我想好了，删</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { api } from "@/utils/api";
import RecordTemplateCard from "@/components/template/RecordCard";
import ReportTemplateCard from "@/components/template/ReportCard";
import nf from "@/utils/notification";
export default {
  name: "customers-v2-character-index",
  components: { RecordTemplateCard, ReportTemplateCard },
  inject: ['individualId', 'instance'],
  props: {
    id: {
      type: String,
      required: true,
      default: () => ""
    }
  },
  data() {
    return {
      show: true,
      tab: null,
      showHasData: false,
      org: {},
      customer: {},
      character_templates: [],
      character_templates_backup: [],
      instance_statistic: [],
      report_instance_statistic: [],
      report_templates:[],
      report_templates_backup: [],
      data_tags: [],
      total_templates: [],
      dialog_delete: false,
      tempItem: {}
    }
  },
  computed: {
    has_add_right() {
      return this.$store.getters.getPermission('charactertemplateinstance', 'add')
    },
    has_change_right() {
      return this.$store.getters.getPermission('charactertemplateinstance', 'change')
    },
    can_delete_right() {
      return this.$store.getters.getPermission('charactertemplateinstance', 'delete')
    },
    selected_tab: {
      get() {
        let tab = this.$store.getters.getSelectedTab
        if (!tab) {
          if (this.data_tags.length > 0) {
            tab = this.data_tags[0].tag_no
          }
        }
        return tab
      },
      set(value) {
        this.$store.dispatch("setSelectedTab", value);
      }
    },
    currentTag() {
      const tag = this.data_tags.find(tag => tag.tag_no == this.selected_tab)
      if (tag && !tag.isInitCharacterData) {
        this.constructCharacterData(tag)
      }
      return tag
    },
    checks() {
      const checks = [...this.$store.getters.getTemplateFilters]
      if (checks.length === 0) {
        const tag = this.currentTag
        if (!tag) return []
        tag.result_items.map(template => {
          if (template.characterData) {
            checks.push(template)
          }
        })
      }
      const result = []
      checks.map(template => {
        template.characterData.map(data => {
          result.push(data)
        })
      })
      result.sort((a, b) => Date.parse(b.happen_date) - Date.parse(a.happen_date))
      return result
    }
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    doFilter(value) {
      if (value.is_filter) {
        this.$store.dispatch("addTemplateFilters", value);
      } else {
        this.$store.dispatch("deleteTemplateFilters", value);
      }
    },
    doConstructTemplate(templates, instance_statistic, data_type) {
      let template_filters = this.$store.getters.getTemplateFilters
      for (let template of templates) {
        template.is_filter = false;
      }
      for (let tf of template_filters) {
        for (let template of templates) {
          if (tf.sid === template.sid) {
            template.is_filter = true
          }
        }
      }
      for(let item of templates) {
        item.instance_count = 0;
        for (let instance of instance_statistic) {
          if (instance.template_id === item.sid) {
            item.instance_count = instance.instance_count;
          }
        }
        item.data_type = data_type
        this.total_templates.push(item)
      }
    },
    async doLoadData() {
      const individualId = this.individualId
      this.total_templates = []
      // 获得特征模版列表
      this.character_templates = await api.get(`character_templates/get_template_list/?c=${individualId}`)
      this.instance_statistic = await api.get(`individuals/${individualId}/instance_statistic/?`)
      this.doConstructTemplate(this.character_templates, this.instance_statistic, 'T')

      // 获得报告模版列表
      this.report_templates = await api.get(`report_templates/get_report_templates/?c=${individualId}`)
      this.report_instance_statistic = await api.get(`individuals/${individualId}/report_instance_statistic/?`)
      this.doConstructTemplate(this.report_templates, this.report_instance_statistic, 'R')

      this.org = this.instance.org
      this.customer = this.instance.customer

      let data_tags = await api.get("tag_infos/get_data_type_tag_list/?")
      if (this.$store.getters.getSelectedTab == null) {
        await this.$store.dispatch("setSelectedTab", data_tags[0].tag_no);
      }
      for(let tag of data_tags) {
        tag.result_items = this.getFilteredItems(tag)
      }
      // console.log(data_tags)
      // debugger
      // data_tags = data_tags.filter(dt => !dt.result_items.some(ri => ri.report_type))
      this.data_tags = data_tags
    },
    getFilteredItems(tag) {
      const { individual_tags } = this.customer
      let result_items = []
      for(let item of this.total_templates) {
        let tags = []
        if (item.data_type === 'T') {
          tags = item.template_tags
        } else if (item.data_type === 'R') {
          tags = item.report_tags
        }
        if (tags.some(tt => tt.tag_no.includes(tag.tag_no))) {
          if (tags.some(tt => individual_tags.some(it => tt.tag_no.includes(it.tag_no))) && !item.report_type) {
            result_items.push(item)
          }
        }
      }
      return result_items
    },
    async constructCharacterData(tag) {
      tag.isInitCharacterData = true
      const template_ids = []
      for(let template of tag.result_items) {
        if (template.instance_count > 0) {
          template_ids.push(template.sid)
        }
      }
      const data = await api.post(`template_instances/get_template_instances_by_customer/`, {
        i: this.individualId,
        template_ids
      })
      for(let template of tag.result_items) {
        template.characterData = data.filter(d => d.template === template.sid)
      }
      if(this._isDestroyed) return
      if (data.length > 0) {
        // 主动触发计算属性checks 重新计算
        this._computedWatchers.checks.run()
        // 但是本次计算不会触发视图刷新，需要主动调用$forceUpdate
        this.$forceUpdate()
      }
    },
    async doDelete() {
      const i = this.checks.findIndex(c => c.sid == this.tempItem.sid)
      await api.delete(`template_instances/${this.tempItem.sid}/?`)
      nf.showSuccessMsg("数据删除成功！")
      this.checks.splice(i, 1)
      this.dialog_delete = false
    }
  }
};
</script>