<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <router-link
          v-bind:to="{ name: '新增客户信息', params: {} }"
          class="v-breadcrumbs__item"
          v-if="has_add_customer_right"
        >
          <v-btn absolute dark fab top left color="primary">
            <v-icon>add</v-icon>
          </v-btn>
        </router-link>
        <v-flex d-flex xs12 style="position: relative;">
          <v-layout row wrap :class="{ 'search-container': true, active: showMore }">
            <v-flex xs12 class="pt-4 pl-6 pr-4">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="客户编号/识别号/姓名/医生/机构"
                hide-details
                @keyup.enter.native="doSearch"
                clearable
                @click:clear="doSearchClear"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="pl-6 pr-4">
              <v-radio-group class="hidden-sm-and-down" v-model="radios" row dense hide-details="auto" v-if="actual_individual_tags.length > 1" @change="doSearch">
                <v-radio label="全部" value="" color="primary" style="padding-bottom: 8px" />
                <v-radio
                  v-for="tag in actual_individual_tags"
                  :key="tag.sid"
                  :value="tag.sid"
                  :label="tag.tag_name"
                  color="primary"
                  style="padding-bottom: 8px"
                ></v-radio>
              </v-radio-group>
              <v-select
                class="hidden-md-and-up"
                v-model="radios"
                label="产品分类"
                :items="actual_individual_tags"
                item-text="tag_name"
                item-value="sid"
                single-line
                clearable
                @change="doSearch"
              ></v-select>
            </v-flex>
            <v-flex xs12 class="pl-4 pr-4">
              <v-radio-group v-model="gender" row style="padding: 0 8px 0 8px" @change="doSearch">
                <v-radio label="全部" value="" color="primary" />
                <v-radio value="MALE" label="男" color="primary" />
                <v-radio value="FEMALE" label="女" color="primary" />
              </v-radio-group>
            </v-flex>
            <v-flex xs12 class="pl-6 pr-4">
              <v-range-slider v-model="age" label="年龄" :max="SEARCH_AGE_MAX" :min="SEARCH_AGE_MIN" hide-details="auto" @change="doSearch">
                <template v-slot:thumb-label="props">
                  {{ props.value }}
                </template>
              </v-range-slider>
            </v-flex>
            <v-flex xs12 sm12 md6 class="pl-6 pr-4">
              <v-radio-group label="建档日期" v-model="datetype" row @change="doSearch" >
                <v-radio label="全部" value="" color="primary" />
                <v-radio value="30" label="近一个月" color="primary" class="hidden-md-and-down" />
                <v-radio value="90" label="近三个月" color="primary" class="hidden-sm-and-down" />
                <v-radio value="183" label="近半年" color="primary" class="hidden-md-and-down" />
                <v-radio value="365" label="近一年" color="primary" class="hidden-sm-and-down" />
                <v-radio value="-1" label="按时间段" color="primary" />
              </v-radio-group>
            </v-flex>
            <v-flex xs12 sm12 md6 class="hidden-sm-and-down pl-6 pr-4 datepicker" v-if="datetype == -1">
              <v-menu v-model="showDatePicker1" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable style="margin-right: 16px;" readonly label="开始日期" v-model="createStartDate" v-bind="attrs" v-on="on" @change="doSearch" />
                </template>
                <v-date-picker v-model="createStartDate" scrollable :max="createEndDate" locale="cn" @change="() => { doSearch();showDatePicker1 = false; }" />
              </v-menu>
              <v-menu v-model="showDatePicker2" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable readonly label="截止日期" v-model="createEndDate" v-bind="attrs" v-on="on" @change="doSearch" />
                </template>
                <v-date-picker v-model="createEndDate" scrollable :min="createStartDate" locale="cn" @change="() => { doSearch();showDatePicker2 = false; }" />
              </v-menu>
            </v-flex>
            <v-flex xs12 sm12 md6 class="hidden-md-and-up pl-6 pr-4 datepicker" v-if="datetype == -1">
              <v-dialog ref="dialog1" v-model="showDialogDatePicker1" :return-value.sync="createStartDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable style="margin-right: 16px;" readonly label="开始日期" v-model="createStartDate" v-bind="attrs" v-on="on" @change="doSearch" />
                </template>
                <v-date-picker v-model="createStartDate" scrollable :max="createEndDate" locale="cn" @change="() => { doSearch();$refs.dialog1.save(createStartDate); }" />
              </v-dialog>
              <v-dialog ref="dialog2" v-model="showDialogDatePicker2" :return-value.sync="createEndDate" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable readonly label="截止日期" v-model="createEndDate" v-bind="attrs" v-on="on" @change="doSearch" />
                </template>
                <v-date-picker v-model="createEndDate" scrollable :min="createStartDate" locale="cn" @change="() => { doSearch();$refs.dialog2.save(createEndDate); }" />
              </v-dialog>
            </v-flex>
          </v-layout>
          <div class="more" @click="showMore = !showMore">
            <v-icon color="primary">
              {{showMore ? 'mdi-chevron-up':'mdi-chevron-down'}}
            </v-icon>
          </div>
        </v-flex>
      </v-card-title>
      <v-card-text style="padding: 16px 16px 0 24px">
        <v-data-table
          :headers="getHeaders"
          :items="items"
          :options.sync="pagination"
          :server-items-length="totalItems"
          :loading="loading"
          locale="zh-CH"
          :footer-props="{
            itemsPerPageText: '每页行数：',
            itemsPerPageOptions: pageItems,
          }"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.individual_no">
                <td>
                  <router-link
                    v-bind:to="{ name: '客户信息', params: { id: item.sid } }"
                    class="v-breadcrumbs__item"
                  >
                    <!-- <router-link v-bind:to="'/customer/detail/'+item.sid" class="v-breadcrumbs__item"> -->
                    {{ item.individual_no }}
                  </router-link>
                </td>
                <td>{{ item.individual_name }}</td>
                <td>{{ item.individual_gender === "MALE" ? "男" : "女" }}</td>
                <td class="hidden-sm-and-down">{{ item.age }}</td>
                <td class="hidden-sm-and-down">
                  {{ item.individual_birthday }}
                </td>
                <td class="hidden-sm-and-down">
                  {{ item.individual_additional_no }}
                </td>
                <td class="hidden-sm-and-down">
                  {{ item.doctor ? item.doctor.doctor_name : "" }}
                </td>
                <td class="hidden-sm-and-down">
                  {{ item.organization ? item.organization.org_name : "" }}
                </td>
              </tr>
            </tbody>
          </template>

          <template slot="no-data">
            <p class="text-xs-center grey--text">对不起，暂时无数据</p>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { api } from "@/utils/api";
import { formatDate, getCurrentDateDash } from "@/utils/date";
import { has_perm } from "@/utils/auth";
const SEARCH_AGE_MIN = 1;
const SEARCH_AGE_MAX = 100;
export default {
  name: "Customer",
  data() {
    return {
      SEARCH_AGE_MIN,
      SEARCH_AGE_MAX,
      dialog: false,
      valid: true,
      totalItems: 0,
      items: [],
      org: {
        org_tags: [],
      },
      actual_individual_tags: [],
      tags: {
        sid: "",
      },
      individual: {
        individual_name: "",
        individual_gender: "",
        individual_birthday: "",
      },
      rules: {
        individual_name: [(v) => !!v || "不能为空"],
        individual_gender: [(v) => !!v || "不能为空"],
        individual_birthday: [
          (v) => !!v || "不能为空，格式YYYY-MM-DD",
          (v) =>
            /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
              v
            ) || "日期格式错误",
        ],
      },
      loading: true,
      pagination: {},
      pageItems: [10, 20, 50],
      search: "",
      records: [],
      nextUrl: "",
      totalCount: 0,
      record_fields: [
        {
          value: "individual_no",
          text: "客户编号",
          sortable: true,
          breakpoints: [],
        },

        {
          value: "individual_name",
          text: "姓名",
          sortable: true,
          breakpoints: [],
        },
        {
          value: "individual_gender",
          text: "性别",
          sortable: true,
          breakpoints: [],
        },
        {
          value: "age",
          text: "年龄",
          sortable: true,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "birthday",
          text: "出生日期",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "individual_additional_no",
          text: "识别号",
          sortable: true,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "doctor",
          text: "医生",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "organization",
          text: "机构",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
      ],
      radios: '',
      can_add_individual: false,
      showMore: false,
      gender: '',
      age: [SEARCH_AGE_MIN, SEARCH_AGE_MAX],
      datetype: '',
      createStartDate: '',
      createEndDate: getCurrentDateDash(),
      showDatePicker1: false,
      showDatePicker2: false,
      showDialogDatePicker1: false,
      showDialogDatePicker2: false
    };
  },

  computed: {
    getHeaders() {
      let headers = [];
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    },
    has_add_customer_right() {
      return has_perm("新增客户信息");
    },
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        // const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          try {
            this.loading = true;
            let url = this.nextUrl.split("?");
            let data = await api.get(
              "individuals/?" + url[url.length - 1],
              null
            );
            for (let item of data["results"]) {
              this.records.push(item);
            }
            this.nextUrl = data["next"];
            this.totalCount = data["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true
    },
    '$route.query': {
      deep: true,
      handler(query) {
        if (this.$route.name == '客户列表' && Object.keys(query).length > 0) {
          this.init()
        }
      }
    }
  }, 
  async mounted() {
    await this.doLoadData();
    await this.init()
  },
  methods: {
    async init() {
      const { yearMonth, tagName, gender, ageMin, ageMax } = this.$route.query;
      if (yearMonth) {
        const dateParam = new Date(`${yearMonth}-01 00:00:00`);
        dateParam.setMonth(dateParam.getMonth() + 1);
        const endDate = (new Date(Date.parse(dateParam) - 1)).getDate();
        this.datetype = '-1';
        this.createStartDate = `${yearMonth}-01`;
        this.createEndDate = `${yearMonth}-${endDate}`;
        this.search = '';
        this.radios = '';
        this.gender = '';
        this.age = [SEARCH_AGE_MIN, SEARCH_AGE_MAX];
      }
      if (tagName) {
        this.search = tagName;
        this.radios = '';
        this.gender = '';
        this.age = [SEARCH_AGE_MIN, SEARCH_AGE_MAX];
        this.datetype = '';
      }
      if (gender || ageMin || ageMax) {
        this.search = '';
        this.radios = '';
        this.datetype = '';
        if (ageMin && ageMax) {
          this.age = [ageMin, ageMax];
        } else if (ageMin) {
          this.age = [ageMin, SEARCH_AGE_MAX];
        } else if (ageMax) {
          this.age = [SEARCH_AGE_MIN, ageMax];
        } else {
          this.age = [SEARCH_AGE_MIN, SEARCH_AGE_MAX];
        }
        if (gender) {
          this.gender = gender;
        } else {
          this.gender = '';
        }
      }
      await this.doSearch();
    },
    async doLoadData() {
      this.loading = true;
      const data = await api.get("users/can_add_individual/?", null);
      this.can_add_individual = data["result"];
      this.tags = await api.get("tag_infos/get_individual_type_list/?", null);
      this.org = await api.get(
        "organizations/get_org_info_by_current_user/?",
        null
      );
      for (let org_tag of this.org.org_tags) {
        for (let tag of this.tags) {
          if (org_tag === tag.sid) {
            this.actual_individual_tags.push(tag);
          }
        }
      }
    },
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },
    async doSearch() {
      try {
        this.loading = true;
        const { gender, age, datetype } = this
        const queryStringArr = [`search=${this.search || ''} ${this.radios}`]

        // 性别处理
        if (gender != 0) {
          queryStringArr.push(`gender=${this.gender}`)
        }

        // 年龄处理 根据选择的年龄转换到他的出生日期起止，后台直接拿来判断
        const [minAge, maxAge] = age
        const nowDate = getCurrentDateDash()
        const [year, month, day] = nowDate.split('-')
        const birthdayStart = `${Math.round(year - maxAge - 1)}-${month}-${day}`
        const birthdayEnd = `${Math.round(year - minAge)}-${month}-${day}`
        queryStringArr.push(`birthdayStart=${birthdayStart}`, `birthdayEnd=${birthdayEnd}`)

        // 建档时间处理
        if (datetype != 0) {
          let createStartDate = this.createStartDate
          let createEndDate = this.createEndDate
          if (datetype != -1) {
            createStartDate = formatDate(Date.now() - datetype * 24 * 60 * 60 * 1000)
            createEndDate = nowDate
          }
          createStartDate && queryStringArr.push(`createStartDate=${createStartDate}`)
          createEndDate && queryStringArr.push(`createEndDate=${createEndDate}`)
        }

        let data = await api.get(
          `individuals/?${queryStringArr.join('&')}`,
          null
        );
        this.loading = false;
        this.records = data["results"];
        this.nextUrl = data["next"];
        this.totalCount = data["count"];
        this.pagination.page = 1;
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        console.error(error)
        this.loading = false;
      }
    },
    doSearchClear() {
      this.search = "";
      this.doSearch();
    },
    doSetParams(value) {
      let params = this.$store.getters.getParams;
      params.id = value;
      this.$store.dispatch("setParams", params);
      this.$store.dispatch("clearTemplateFilters");
    },
  },
};
</script>

<style scoped>
.search-container {
  max-height: 66px;
  overflow: hidden;
  transition: max-height .3s;
}
.search-container.active {
  max-height: 1000px;
}
.datepicker {
  display: flex;
}
.more {
  position: absolute;
  bottom: -35px;
  left: 50%;
  height: 24px;
  line-height: 24px;
  animation: arrowFrame 1s infinite;
  cursor: pointer;
  transform: translateX(-50%);
  padding: 0 100px;
}
@keyframes arrowFrame {
  0% { bottom: -30px; }
  50% { bottom: -35px; }
  100% { bottom: -30px; }
}
</style>