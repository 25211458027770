<template>
  <div class="customers-v2-product">
    <v-chip style="color: #ffffff;margin-right:16px;margin-bottom: 4px;font-size:16px;" small color="#9E9E9E" v-for="tag in selectedProductList" :key="tag">
      {{tag}}
    </v-chip>
    <v-btn color="primary" fab text small @click="tagDialog=true">
      <v-icon color="primary">mdi-pencil-circle-outline</v-icon>
    </v-btn>
    <v-dialog v-model="tagDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">设置 {{customer.individual_name}} 的产品</span>
        </v-card-title>
        <v-card-text>
<!--          <v-flex xs12 v-if="selectedProductList.length >= 1">-->
          <v-layout wrap>
            <span v-for="tag in allProductList" :key="tag.sid" style="padding-right: 10px; padding-left: 4px">
              <v-checkbox v-model="modifyProductIds" :label="tag.tag_name" :value="tag.sid" color="primary"></v-checkbox>
            </span>
          </v-layout>
<!--          </v-flex>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="doSetTags">确认修改</v-btn>
          <v-btn color="blue darken-1" text @click="tagDialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { api } from "@/utils/api"
import nf from "@/utils/notification"
export default {
  name: 'customers-v2-product',
  inject: ['individualId', 'instance'],
  data() {
    return {
      customer: {},
      allProductList: [],
      selectedProductList: [],
      tagDialog: false,
      modifyProductIds: []
    }
  },
  mounted() {
    this.customer = this.instance.customer
    this.init()
  },
  methods: {
    async init() {
      const selectedProductList = []
      const modifyProductIds = []
      const tags = await api.get('tag_infos/get_individual_type_list/')
      for (let i_tag of this.customer.individual_tags) {
        for (let tag of tags) {
          if (i_tag.sid === tag.sid) {
            selectedProductList.push(tag.tag_name)
            modifyProductIds.push(tag.sid)
          }
        }
      }
      this.selectedProductList = selectedProductList
      this.modifyProductIds = modifyProductIds

      const allProductList = []
      for (let org_tag of this.instance.org.org_tags) {
        for (let tag of tags) {
          if (org_tag === tag.sid) {
            allProductList.push(tag)
          }
        }
      }
      this.allProductList = allProductList
    },
    async doSetTags() {
      await api.post(`individuals/${this.$route.params.id}/set_tags/`, {
        individual_tags: this.modifyProductIds
      })
      this.tagDialog = false
      await this.init()
      nf.showSuccessMsg('产品设置成功！')
    }
  }
}
</script>