import { api } from "@/utils/api"
export default class Customer {
  constructor(customerId) {
    this.sid = customerId
  }
  sid = ''
  customer = {}
  org = {}

  async initBaseInfo() {
    this.customer = await api.get(`individuals/${this.sid}/`)
    this.org = await api.get('organizations/get_org_info_by_current_user/')
  }
}