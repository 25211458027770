<template>
  <div class="rpt-mpbh-v1" :style="{ fontSize: `${rpxRate * 14}px` }" v-if="ok">
    <img src="/static/images/minigram/topbg.png" class="topbg" :style="{ minHeight: `${rpxRate * 200}px`}"/>
    <div class="contain">
      <div class="title">
        {{title}}
        <div style="font-size: 14px;">评估日期：{{tm}}</div>
      </div>
      <v-card width="100%" flat>
        <div class="flex" style="padding: 0 16px;margin-bottom: 8px;border-bottom: 1px solid #F1F1F1;">
          <div class="flex1" style="text-align:center">
            <h3>女性健康指数</h3>
            <div style="margin-top: 12px;">{{whiLv}}平均水平</div>
            <div style="margin-top: 12px;font-size: 14px;color:#999999">平均分：{{whiAvgMin}}-{{whiAvgMax}}分</div>
          </div>
          <div class="score" :style="{ height: `${rpxRate * 140}px`, width: `${rpxRate * 140}px` }">
            <div class="ring-floor" :style="{ top: `${-30 * rpxRate}px` }"></div>
            <canvas id="whi"></canvas>
            <div class="score_d">
              <div :style="{ fontSize: `${rpxRate * 32}px`, lineHeight: `${rpxRate * 32}px`, color: '#FFA2B4' }">
                {{whiScore}}
              </div>
              <div class="split"></div>
              <div>健康评分</div>
              <div>WHI</div>
            </div>
          </div>
        </div>
        <div 
          class="bodyin-detail" 
          v-for="whidt in whiDetails" 
          :key="whidt.name" 
          @click="() => handleShowRiskFactor(whidt)"
        >
          <div class="bodyin-detail-title flex">
            <div class="flex1">{{whidt.name}}</div>
            <div class="desc">{{whidt.score}}(分)</div>
          </div>
          <div class="bodyin-detail-legend">
            <div :style="{
              background: whidt.color,
              width: whidt.width
            }"></div>
          </div>
          <div class="desc">
            {{whidt.desc}}
          </div>
        </div>
        <div class="flex bodyin-legend">
          <div class="flex1">
            <div class="t1"></div>
            高于平均水平
          </div>
          <div class="flex1">
            <div class="t2"></div>
            处于平均水平
          </div>
          <div class="flex1">
            <div class="t3"></div>
            低于平均水平
          </div>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;" v-if="risk.length > 0">
        <div class="card-title">
          <div class="card-title-left">主要健康问题</div>
        </div>
        <div class="risk">
          <v-chip class="chip" v-for="r in risk" :key="r.tagName" @click="() => handleHealthClick(r)">
            {{r.tagName}}
          </v-chip>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;">
        <div class="card-title">
          <div class="card-title-left">身体器官年龄</div>
          <div class="card-title-right">您的实际年龄：{{suggest.age}}岁</div>
        </div>
        <div class="bodyage">
          <div class="bodyage1 flex">
            <div class="bodyage0 flex1" @click="() => handleShowRiskFactor(bodyAgeMap['乳腺'])">
              <img src="/static/images/minigram/rx.png" class="bg"/>
              <div class="txt0">乳腺年龄
                <label v-if="bodyAgeMap['乳腺'].age > suggest.age" class="abnormal">!</label>
              </div>
              <div class="txt1" v-if="!bodyAgeMap['乳腺'].is_effective_score">
                暂无法评估
              </div>
              <div class="txt1" v-else>
                <label class="agecolor">{{bodyAgeMap['乳腺'].age}}</label> 岁
              </div>
            </div>
            <div class="flex1">
              <div class="bodyageitem" @click="() => handleShowRiskFactor(bodyAgeMap['甲状腺'])">
                <img src="/static/images/minigram/jzx.png" class="bg"/>
                <div class="txt0">甲状腺年龄
                  <label v-if="bodyAgeMap['甲状腺'].age > suggest.age" class="abnormal">!</label>
                </div>
                <div class="txt1" v-if="!bodyAgeMap['甲状腺'].is_effective_score">
                  暂无法评估
                </div>
                <div class="txt1" v-else>
                  <label class="agecolor">{{bodyAgeMap['甲状腺'].age}}</label> 岁
                </div>
              </div>
              <div class="bodyageitem" style="margin-top: 8px;" @click="() => handleShowRiskFactor(bodyAgeMap['肝脏(女性)'])">
                <img src="/static/images/minigram/gz2.png" class="bg"/>
                <div class="txt0">肝脏年龄
                  <label v-if="bodyAgeMap['肝脏(女性)'].age > suggest.age" class="abnormal">!</label>
                </div>
                <div class="txt1" v-if="!bodyAgeMap['肝脏(女性)'].is_effective_score">
                  暂无法评估
                </div>
                <div class="txt1" v-else>
                  <label class="agecolor">{{bodyAgeMap['肝脏(女性)'].age}}</label> 岁
                </div>
              </div>
            </div>
          </div>
          <div class="bodyage2 flex" style="margin-top: 8px;">
            <div class="bodyageitem flex1" style="margin: 0;" @click="() => handleShowRiskFactor(bodyAgeMap['肠道(女性)'])">
              <img src="/static/images/minigram/cd2.png" class="bg"/>
              <div class="txt0">肠道年龄
                  <label v-if="bodyAgeMap['肠道(女性)'].age > suggest.age" class="abnormal">!</label>
              </div>
              <div class="txt1" v-if="!bodyAgeMap['肠道(女性)'].is_effective_score">
                暂无法评估
              </div>
              <div class="txt1" v-else>
                <label class="agecolor">{{bodyAgeMap['肠道(女性)'].age}}</label> 岁
              </div>
            </div>
            <div class="flex1">
              <div class="bodyageitem" @click="() => handleShowRiskFactor(bodyAgeMap['肾上腺'])">
                <img src="/static/images/minigram/ssx.png" class="bg"/>
                <div class="txt0">肾上腺年龄
                  <label v-if="bodyAgeMap['肾上腺'].age > suggest.age" class="abnormal">!</label>
                </div>
                <div class="txt1" v-if="!bodyAgeMap['肾上腺'].is_effective_score">
                  暂无法评估
                </div>
                <div class="txt1" v-else>
                  <label class="agecolor">{{bodyAgeMap['肾上腺'].age}}</label> 岁
                </div>
              </div>
            </div>
          </div>
          <div class="bodyage2 flex" style="margin-top: 8px;">
            <div class="bodyageitem flex1" style="margin: 0;" @click="() => handleShowRiskFactor(bodyAgeMap['子宫'])">
              <img src="/static/images/minigram/zg.png" class="bg"/>
              <div class="txt0">子宫年龄
                  <label v-if="bodyAgeMap['子宫'].age > suggest.age" class="abnormal">!</label>
              </div>
              <div class="txt1" v-if="!bodyAgeMap['子宫'].is_effective_score">
                暂无法评估
              </div>
              <div class="txt1" v-else>
                <label class="agecolor">{{bodyAgeMap['子宫'].age}}</label> 岁
              </div>
            </div>
            <div class="flex1">
              <div class="bodyageitem">
                <img src="/static/images/minigram/lc.png" class="bg" @click="() => handleShowRiskFactor(bodyAgeMap['卵巢'])"/>
                <div class="txt0">卵巢年龄
                  <label v-if="bodyAgeMap['卵巢'].age > suggest.age" class="abnormal">!</label>
                </div>
                <div class="txt1" v-if="!bodyAgeMap['卵巢'].is_effective_score">
                  暂无法评估
                </div>
                <div class="txt1" v-else>
                  <label class="agecolor">{{bodyAgeMap['卵巢'].age}}</label> 岁
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;">
        <div class="card-title">
          <div class="card-title-left">饮食建议</div>
        </div>
        <div class="suggest-dietary">
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/staple.png"/>
            <div class="flex1">
              <div class="title">主食</div>
              <div class="desc0">食量：{{suggest.dietary.main_food_desc}}</div>
              <div class="desc1">主要以谷类，薯类为主</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/fist.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/veg.png"/>
            <div class="flex1">
              <div class="title">蔬菜</div>
              <div class="desc0">食量：{{suggest.dietary.vegetable_desc}}</div>
              <div class="desc1">其中叶类蔬菜占1/3以上</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/praise.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/fruit.png"/>
            <div class="flex1">
              <div class="title">水果</div>
              <div class="desc0">食量：{{suggest.dietary.fruit_desc}}</div>
              <div class="desc1">建议选择新鲜当季的水果</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/fist.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/protein.png"/>
            <div class="flex1">
              <div class="title">蛋白质</div>
              <div class="desc0">食量：{{suggest.dietary.protein_food_desc}}</div>
              <div class="desc1">肉，豆类，蛋及鱼（痛风或高尿酸者慎选）</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/palm.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/milk.png"/>
            <div class="flex1">
              <div class="title">奶类</div>
              <div class="desc0">食量：{{suggest.dietary.milk_desc}}</div>
              <div class="desc1">牛奶或其它奶类</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/cup.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/nut.png"/>
            <div class="flex1">
              <div class="title">坚果</div>
              <div class="desc0">食量：{{suggest.dietary.nut_desc}}</div>
              <div class="desc1">去壳</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/thumb.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/oil.png"/>
            <div class="flex1">
              <div class="title">食用油</div>
              <div class="desc0">食量：{{suggest.dietary.oil_desc}}</div>
              <div class="desc1">矿泉水瓶蛋盖</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/cap.png"/>
          </div>
          <div class="row flex" style="background-image:url(/static/images/minigram/dss_bg.png)">
            <img class="dietary-icon" src="/static/images/minigram/salt.png"/>
            <div class="flex1">
              <div class="title">食盐</div>
              <div class="desc0">食量：{{suggest.dietary.salt_desc}}</div>
            </div>
            <img class="dietary-unit-icon" src="/static/images/minigram/littlefinger.png"/>
          </div>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;">
        <div class="card-title">
          <div class="card-title-left">运动建议</div>
          <div class="card-title-right">下列任选一种运动</div>
        </div>
        <div class="suggest-sport">
          <div class="sport">
            <div class="sport-item" style="background-image: url(/static/images/minigram/skiprope.png);">
              <div class="flex1">
                <div class="sport-item-0">快速跳绳</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.skiprope}}</label>分钟
                </div>
              </div>
            </div>
            <div class="sport-item" style="background-image: url(/static/images/minigram/bike.png);">
              <div class="flex1">
                <div class="sport-item-0">骑自行车</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.bike}}</label>分钟
                </div>
              </div>
            </div>
          </div>
          <div class="sport">
            <div class="sport-item" style="background-image: url(/static/images/minigram/swim.png);">
              <div class="flex1">
                <div class="sport-item-0">游泳</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.swim}}</label>分钟
                </div>
              </div>
            </div>
            <div class="sport-item" style="background-image: url(/static/images/minigram/run.png);">
              <div class="flex1">
                <div class="sport-item-0">慢跑</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.run}}</label>分钟
                </div>
              </div>
            </div>
          </div>
          <div class="sport">
            <div class="sport-item" style="background-image: url(/static/images/minigram/yoga.png);">
              <div class="flex1">
                <div class="sport-item-0">瑜伽</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.yoga}}</label>分钟
                </div>
              </div>
            </div>
            <div class="sport-item" style="background-image: url(/static/images/minigram/badminton.png);">
              <div class="flex1">
                <div class="sport-item-0">羽毛球</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.badminton}}</label>分钟
                </div>
              </div>
            </div>
          </div>
          <div class="sport">
            <div class="sport-item" style="background-image: url(/static/images/minigram/fastrun.png);">
              <div class="flex1">
                <div class="sport-item-0">快速行走</div>
                <div class="sport-item-1">
                  <label>{{suggest.sport.fastrun}}</label>分钟
                </div>
              </div>
            </div>
            <div class="sport-item" style="visibility: hidden;"></div>
          </div>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;" v-if="suggest.medicine.advice_detail.length > 0">
        <div class="card-title">
          <div class="card-title-left">营养素推荐</div>
        </div>
        <div class="suggest-advice">
          <div
            class="advicerow flex"
            v-for="item in suggest.medicine.advice_detail"
            :key="item.sid"
            @click="() => handleMedicineClick(item)"
          >
            <img :src="item.imgPath" />
            <div class="flex1">
              <div class="lheight title">
                <div class="maininfo">
                  {{item.medicine_info.display_name || item.medicine_info.medicine_name}}
                  <label class="subtitle" v-if="item.is_optional">(可选)</label>
                </div>
              </div>
              <div class="lheight">
                <div class="maininfo">
                  {{item.take_rate_display}}服用{{item.take_count_min}}次，每次{{item.recommend_number}}{{item.recommend_unit_display}}
                </div>
              </div>
            </div>
            <div class="opera" style="background-image: url(/static/images/minigram/medadvice.png);">
              <label>查看详细</label>
            </div>
          </div>
        </div>
      </v-card>
      <v-card width="100%" flat style="margin-top: 16px;">
        <div class="card-title">
          <div class="card-title-left">我的指标</div>
        </div>
        <v-data-table
          :headers="[
            { text: '指标', value: 'character_name', width: '25%' },
            { text: '结果', value: 'instance_value', width: '25%' },
            { text: '正常参考值', value: 'normal_range', width: '25%' },
            { text: '检测时间', value: 'happen_date', width: '25%' },
          ]"
          :items="newstCharacter"
          :mobile-breakpoint="Infinity"
          :items-per-page="-1"
          group-by="分类"
          hide-default-footer
          @click:row="handleCharacterRowClick"
        >
          <template  v-slot:item.instance_value="{ item }">
            {{item.instance_value}}<label v-if="item.trend" style="color:red;">{{item.trend}}</label>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="showHealthRisk" scrollable content-class="diy-dialog">
      <v-card width="100%" flat>
        <v-card-title>{{healthRiskTitle}}</v-card-title>
        <v-card-text style="max-height: 400px;">
          <v-chip style="margin: 0 8px 8px 0;" outlined color="red" v-for="r in healthRiskContent" :key="r.instance_value">
            {{r.instance_value}}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRiskFactor" scrollable content-class="diy-dialog">
      <v-card width="100%" flat>
        <v-card-title>主要危险因素 - {{riskFactorTitle}}</v-card-title>
        <v-card-text style="max-height: 400px;">
          <div style="margin: 8px 0;padding-top:8px;border-top: 1px solid rgba(0, 0, 0, 0.12);" v-for="item in riskFactorContent" :key="item.name">
            <div style="color:#000000;">{{item.factor_name}}</div>
            <p style="font-size:12px;color: #777777;margin:0;">{{item.factor_desc}}</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="medicine" v-model="showMedicineDetail" scrollable content-class="diy-dialog">
      <v-card width="100%" flat>
        <v-card-title>{{medicine.medicine_info.medicine_name}}</v-card-title>
        <v-card-text style="max-height: 400px;">
          <img class="modal-img" :src="medicine.imgPath" />
          <div class="modal-item">
            <div class="title">服用剂量</div>
            <div class="desc">{{medicine.take_rate_display}}服用{{medicine.take_count_min}}次，每次{{medicine.recommend_number}}{{medicine.recommend_unit_display}}</div>
          </div>
          <div class="modal-item" v-if="medicine.note">
            <div class="title">备注</div>
            <div class="desc">{{medicine.note}}</div>
          </div>
          <div class="modal-item" v-for="prop in medicine.medicine_info.property" :key="prop.sid">
            <div class="title">{{prop.property_name}}</div>
            <div class="desc">{{prop.property_value.DESC || prop.property_value}}</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCharacterHistory" eager content-class="diy-dialog">
      <v-card width="100%" flat>
        <v-card-title>指标历史 - {{characterHistoryTitle}}</v-card-title>
        <v-card-text>
          <div id="characterHistory"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="tips" v-if="isShowTip">
      暂无可用数据
    </div>
  </div>
  <div v-else class="apploader"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10" class="path"></circle></svg></div>
</template>
<script>
import { api } from "@/utils/api";
import axios from "axios";
let timer
export default {
  name: 'RPT-MPBH-V1',
  props: {
    token: {
      type: String,
      default: null
    },
    instanceid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ok: false,
      rpxRate: document.body.offsetWidth / 375,
      width: document.body.offsetWidth - 32,
      title: '',
      tm: '',
      whiScore: 0,
      whiLv: '',
      whiAvgMin: 0,
      whiAvgMax: 0,
      whiColor: '',
      whiDetails: [],
      risk: [],
      bodyAgeMap: {},
      suggest: {},
      newstCharacter: [],
      showHealthRisk: false,
      healthRiskTitle: '',
      healthRiskContent: [],
      showRiskFactor: false,
      riskFactorTitle: '',
      riskFactorContent: [],
      showMedicineDetail: false,
      medicine: null,
      showCharacterHistory: false,
      characterHistoryTitle: '',
      isShowTip: false
    }
  },
  async mounted() {
    let snapshotData = {}
    if (this.token == '0') {
      snapshotData = await api.get(`report_templates/get_data_snapshot/?i=${this.instanceid}`)
    } else {
      const { data: { data } } = await axios.get(`mp/report_instances/get_data_snapshot/?i=${this.instanceid}`, {
        baseURL: "/api",
        timeout: 60000,
        withCredentials: true,
        headers: {
          "Cache-Control": "no-cache",
          "Pragma": "no-cache",
          "Content-Type": "application/json",
          "token": this.token
        }
      })
      snapshotData = data
    }
    const { snapshot, snapshot_detail } = snapshotData
    for (let sd of snapshot_detail) {
      sd.detail_text = JSON.parse(sd.detail_text || '[]')
      if (sd.detail_name == '主要慢性病风险') {
        const tagMap = {}
        const typeMap = {}
        if (sd.detail_text.some(d => d.tag.tag_name === 'H型高血压')) {
          sd.detail_text.filter(d => d.tag.tag_name === '高血压').map(d => {
            d.tag.tag_name = 'H型高血压'
          })
        }
        if (sd.detail_text.some(d => d.tag.tag_name === '糖尿病')) {
          sd.detail_text.filter(d => d.tag.tag_name === '糖尿病前期').map(d => {
            d.tag.tag_name = '糖尿病'
          })
        }
        sd.detail_text.map(d => {
          if (!tagMap[d.tag.tag_name]) {
            tagMap[d.tag.tag_name] = []
          }
          if (d.character) {
            if (!tagMap[d.tag.tag_name].some(dd => dd.character && d.character && dd.character.sid == d.character.sid)) {
              tagMap[d.tag.tag_name].push(d)
            }
          } else {
            tagMap[d.tag.tag_name].push(d)
          }
          typeMap[d.tag.tag_name] = !d.character
          delete d.tag
        })
        Object.keys(tagMap).map(tagName => {
          return {
            tagName,
            isRisk: typeMap[tagName],
            list: tagMap[tagName]
          }
        }).filter(t => t.isRisk).map(risk => {
          this.risk.push(risk)
        })
      } else if (sd.detail_name == '健康指数') {
        sd.detail_text.map(dt => {
          if (dt.name == '总分') {
            const whi = dt
            this.whiScore = Number(whi.score)

            const [min, max] = JSON.parse(whi.threshold)
            const avgMin = Math.round(Number(whi.average) - min)
            const avgMax = Math.round(Number(whi.average) + max)
            this.whiAvgMin = avgMin
            this.whiAvgMax = avgMax
            if (this.whiScore < avgMin) {
              this.whiColor = '#FBBE41'
              this.whiLv = '低于'
            } else if (this.whiScore > avgMax) {
              this.whiColor = '#76C1AA'
              this.whiLv = '高于'
            } else {
              this.whiColor = '#FFA2B4'
              this.whiLv = '处于'
            }
          } else {
            const score = Number(dt.score)
            const width = `${dt.score / 10}%`
            let risk_factor = dt.risk_factor || '[]'
            risk_factor = risk_factor.replace(/'/g, '"')
            risk_factor = JSON.parse(risk_factor)

            const [min, max] = JSON.parse(dt.threshold)
            const avgMin = Math.round(Number(dt.average) - min)
            const avgMax = Math.round(Number(dt.average) + max)
            let color = ''
            if (score < avgMin) {
              color = '#FBBE41'
            } else if (score > avgMax) {
              color = '#76C1AA'
            } else {
              color = '#FFA2B4'
            }
            let desc = ''
            switch(dt.name) {
              case '生理指标':
                desc = '评价体检及深度检测指标'
                break
              case '生活习惯':
                desc = '评价饮食、运动、睡眠、吸烟及饮酒等相关因素'
                break
              case '外部环境':
                desc = '评价工作环境及居住环境等相关因素'
                break
              case '遗传基因':
                desc = '评价家族史、相关基因检测指标'
                break
              case '身体状态':
                desc = '评价疾病史、用药史及主观感受等相关因素'
                break
            }
            this.whiDetails.push({
              name: dt.name, desc, score, width, color, risk_factor
            })
          }
        })
      } else if (sd.detail_name == '器官年龄') {
        sd.detail_text.map(dt => {
          const age = dt.transform_info
          let risk_factor = dt.risk_factor || '[]'
          risk_factor = risk_factor.replace(/'/g, '"')
          risk_factor = JSON.parse(risk_factor)

          this.bodyAgeMap[dt.name] = {
            age, risk_factor, name: dt.name, is_effective_score: dt.is_effective_score
          }
        })
      } else if (sd.detail_name == '干预建议') {
        const data = sd.detail_text
        data.sport.skiprope = Math.round(60*data.sport.total_k_cal/640)
        data.sport.bike = Math.round(60*data.sport.total_k_cal/518)
        data.sport.swim = Math.round(60*data.sport.total_k_cal/491)
        data.sport.run = Math.round(60*data.sport.total_k_cal/491)
        data.sport.yoga = Math.round(60*data.sport.total_k_cal/446)
        data.sport.badminton = Math.round(60*data.sport.total_k_cal/402)
        data.sport.fastrun = Math.round(60*data.sport.total_k_cal/357)

        if (data.medicine.advice_detail) {
          for (let advice_detail of data.medicine.advice_detail) {
            try {
              const imgPath = `/static/images/medicine/${advice_detail.medicine_info.medicine_no}.jpg`
              await axios.get(imgPath)
              advice_detail.imgPath = imgPath
            } catch(e) {
              advice_detail.imgPath = `/static/images/medicine/medicineplace.jpg`
            }
          }
        } else {
          data.medicine.advice_detail = []
        }

        this.suggest = data
      } else if (sd.detail_name == '最新指标') {
        sd.detail_text.map(c => {
          c['分类'] = c.category
        })
        this.newstCharacter = sd.detail_text
      }
    }
    this.title = snapshot.snapshot_name
    this.tm = snapshot.created_time
    this.ok = true
    await this.$nextTick()
    this.initWHIChart()

    let snapshot_character = []
    if (this.token == '0') {
      snapshot_character = await api.get(`report_templates/get_data_snapshot_characters/?i=${this.instanceid}`)
    } else {
      const { data: { data } } = await axios.get(`mp/report_instances/get_data_snapshot_characters/?i=${this.instanceid}`, {
        baseURL: "/api",
        timeout: 60000,
        withCredentials: true,
        headers: {
          "Cache-Control": "no-cache",
          "Pragma": "no-cache",
          "Content-Type": "application/json",
          "token": this.token
        }
      })
      snapshot_character = data
    }
    let characterHistory = []
    if (snapshot_character && snapshot_character.length) {
      const range_characters = snapshot_character.find(sc => sc.character_type == 'range')
      if (range_characters) {
        characterHistory = JSON.parse(range_characters.character_text)
        characterHistory.map(ch => {
          ch.history = ch.history.filter(h => !['', undefined, null].includes(h.i_value))
        })
        const htHis = characterHistory.find(ch => ch.no == 'C-PE-A-001') // 身高
        const wtHis = characterHistory.find(ch => ch.no == 'C-PE-A-002') // 体重
        const bmiHis = {
          name: '体重指数（BMI）',
          no: 'C-PE-A-003',
          range: [{
            boundary: "BOTH",
            crowd: "COMMON",
            max: 23.9,
            min: 18.5
          }],
          history: [],
          unit: 'kg/㎡'
        }
        if (htHis) {
          htHis.history.map(ht => {
            const wt = wtHis.history.find(w => w.happen_date == ht.happen_date)
            if (wt) {
              bmiHis.history.push({
                happen_date: ht.happen_date,
                i_value: (wt.i_value / Math.pow(ht.i_value/100, 2)).toFixed(2)
              })
            }
          })
        }
        characterHistory.push(bmiHis)
      }
      this.newstCharacter.map(c => {
        const his = characterHistory.find(ch => ch.no == c.character_no)
        if (his && his.history.length > 1) {
          c.history = his.history
          c.range = his.range
        }
      })
    }
  },
  methods: {
    showTips() {
      this.isShowTip = true
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        this.isShowTip = false
      }, 3000)
    },
    initWHIChart() {
      const whi = document.getElementById('whi')
      const ctx = whi.getContext('2d')
      whi.width = this.width
      whi.height = this.width
      const circle_r = this.width / 2 //画布的一半，用来找中心点和半径
      const value = this.whiScore / 1000 //当前的值
      const lineColor = this.whiColor // 线条颜色
      const lineWidth = 24 // 线条宽度
      // 定义起始点
      ctx.translate(circle_r, circle_r)
      // 由于固定是3/4圆，所以先旋转好
      ctx.rotate(135 * Math.PI / 180)
      // 设置线条末端样式
      ctx.lineCap = "round"
      // 灰色圆弧
      ctx.beginPath();
      ctx.strokeStyle = "#F1F1F1";
      ctx.lineWidth = lineWidth;
      ctx.arc(0, 0, circle_r - lineWidth, 0, 1.5 * Math.PI, false);
      ctx.stroke();
      ctx.closePath();
      // 有色彩的圆弧
      ctx.beginPath();
      ctx.strokeStyle = lineColor;
      ctx.lineWidth = lineWidth + 2;
      ctx.arc(0, 0, circle_r - lineWidth, 0, value * 1.5 * Math.PI, false);
      ctx.stroke();
      ctx.closePath();
    },
    handleHealthClick(item) {
      this.healthRiskTitle = item.tagName
      this.healthRiskContent = item.list
      this.showHealthRisk = true
    },
    handleShowRiskFactor(item) {
      if (item.risk_factor.length == 0) {
        this.showTips()
        return
      }
      this.isShowTip = false
      this.riskFactorTitle = item.name
      this.riskFactorContent = item.risk_factor
      this.showRiskFactor = true
    },
    handleMedicineClick(item) {
      this.medicine = item
      this.showMedicineDetail = true
    },
    async handleCharacterRowClick(row) {
      if (!row.history) {
        this.showTips()
        return
      }
      this.isShowTip = false
      this.characterHistoryTitle = row.character_name
      this.showCharacterHistory = true

      const chart = this.$echarts.init(document.getElementById('characterHistory'))
      const seriesData = []
      const rangeDefine = row.range[0]
      row.history.map((t) => {
        const value = Number(t.i_value) || 0

        let color = undefined
        if (value > rangeDefine.range_max || value < rangeDefine.range_min) {
          color = 'red'
        }
        seriesData.push({ value, happen_date: t.happen_date, itemStyle: { color } })
      })
      chart.setOption({
        grid: {
          top: 10,
          right: 0,
          bottom: 20
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: this.rpxRate * 12,
            color: '#000000'
          },
          data: row.history.map(t => t.happen_date)
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: this.rpxRate * 12,
            color: '#000000'
          },
          max (value) { return (value.max * 1.05).toFixed(2) },
          min (value) { return (value.min * 0.95).toFixed(2) }
        },
        series: [{
          animation: false,
          data: seriesData,
          type: 'line'
        }]
      })
      await this.$nextTick()
      chart.resize()
    }
  }
}
</script>
<style scoped lang="scss">
.center {
  text-align: center;
}
.flex {
  display: flex;
  align-items: center;
}
.flex1 {
  flex: 1;
}
.card-title {
  @extend .flex;
  padding: 16px;
  line-height: 25px;
  padding-bottom: 11px;
  .card-title-left {
    @extend .flex1;
    font-weight: 600;
    font-size: 17px;
  }
  .card-title-right {
    font-size: 12px;
    color: #999999;
  }
}
.rpt-mpbh-v1 {
  height: 100%;
  padding: 16px;
  position: relative;
  background: #F6F6F6;
  h3 {
    color: #555555;
  }
  .topbg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .contain {
    position: relative;
    z-index: 2;
    & > .title {
      color: #ffffff;
      text-align: center;
      margin: 16px 0 40px;
      line-height: normal;
    }
    .whisplit {
      height: 1px;
      background: #E1E1E1;
      width: 100%;
      border-radius: 5px;
      margin: 4px auto;
      margin-top: 8px;
    }
    .score {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .ring-floor {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #ffffff;
        z-index: 3;
      }
      #whi {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 100%;
      }
      .score_d {
        position: relative;
        z-index: 5;
        text-align: center;
        color: #9F9F9F;
        .split {
          height: 3px;
          background: #E1E1E1;
          width: 60%;
          border-radius: 5px;
          margin: 4px auto;
        }
      }
    }
    .bodyin-detail {
      padding: 0 16px;
      margin-bottom: 8px;

      .bodyin-detail-title {
        font-size: 16px;
        font-weight: 400;
      }
      .desc {
        font-size: 14px;
        color: #999999;
      }
      .bodyin-detail-legend {
        margin: 4px 0;
        height: 8px;
        border-radius: 5px;
        background: #E8E8E8;
        position: relative;
      }
      .bodyin-detail-legend div {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 5px;
      }
    }
    .bodyin-legend {
      margin-top: 16px;
      padding-bottom: 16px;
      .flex1{
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        div {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          margin-right: 4px;
        }
      }
      .t1 {
        background: #76C1AA;
      } 
      .t2 {
        background: #FFA2B4;
      } 
      .t3 {
        background: #FBBE41;
      } 
    }
    .risk {
      border-top: 1px solid #F1F1F1;
      padding: 12px 4px 4px 12px;
      .chip {
        margin: 0 8px 8px 0;
        padding: 0 8px;
        background: #FEF8F9;
        color: #EE869A;
        display: inline-block;
      }
    }
    .bodyage {
      padding: 0 16px 16px 16px;
      .bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
      .txt0, .txt1 {
        position: relative;
        z-index: 2;
      }
      .txt1 {
        font-size: 14px;
      }
      .agecolor {
        color: #EE869A;
        font-weight: 600;
        font-size: 16px;
      }
      .bodyage0 {
        position: relative;
        height: 180px;
      }
      .txt0 {
        padding-top: 9px;
        padding-left: 9px;
        font-size: 15px;
        font-weight: 400;
      }
      .txt1 {
        padding-top: 4px;
        padding-left: 9px;
      }
      .bodyageitem {
        position: relative;
        height: 86px;
        margin-left: 8px;
      }
      .abnormal {
        font-weight: bold;
        color: orange;
      }
    }
    .suggest-dietary {
      padding: 0 16px 16px 16px;
      .row {
          align-items: center;
          background-size: 100% 100%;
          padding: 12px 0 6px;
          margin: 0;
      }
      .row:last-child {
          margin: 0;
      }
      .row:last-child .flex1 {
          border: 0;
          padding: 0;
      }
      .row .dietary-icon {
          height: 72px;
          width: 72px;
          margin-right: 14px;
      }
      .row .dietary-unit-icon {
          height: 50px;
          width: 50px;
          margin-left: 12px;
      }
      .row .flex1 {
          padding-bottom: 12px;
      }
      .row .title {
          font-size: 16px!important;
          font-weight: 600;
          color: #555555;
          line-height: 22px;
          margin: 6px 0;
      }
      .row .desc0 {
          font-size: 14px;
          font-weight: 500;
          color: #777777;
          line-height: 19px;
      }
      .row .desc1 {
          font-size: 12px;
          font-weight: 500;
          color: #999999;
          line-height: 18px;
      }
    }
    .suggest-sport {
      padding: 0 16px 1px 16px;
      .sport {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .sport .sport-item {
        padding: 12px;
        flex: 1;
        background-size: 100% 100%;
      }
      .sport .sport-item:nth-child(1) {
        margin-right: 6px
      }
      .sport .sport-item:nth-child(2) {
        margin-left: 6px
      }
      .sport .sport-item .flex1 {
        padding-left: 60px;
      }
      .sport .sport-img {
        width: 44px;
        margin-right: 12px;
      }
      .sport .sport-item-0 {
        font-weight: 500;
        font-size: 15px;
      }
      .sport .sport-item-1 {
        font-size: 12px;
        color: #959595;
      }
      .sport .sport-item-1 label {
        font-size: 16px;
        font-weight: 600;
        color: #EE869A;
        margin-right: 4px;
      }
    }
    .suggest-advice {
      padding: 0 16px 8px 16px;
      .advicerow {
        font-size: 14px;
        color: #777777;
        border: 8px solid #FEF8F9;
        border-radius: 8px;
        margin-bottom: 8px;
        height: 120px;
        .lheight {
          margin-bottom: 8px;
          display: flex;
        }
        .lheight .maininfo {
          flex: 1;
        }
        .subtitle {
          font-size: 14px;
          color: #585858;
        }
        .title {
          font-size: 15px!important;
          line-height: normal;
          font-weight: 800;
          color: #585858;
        }
        img {
          height: 90px;
          width: 90px;
        }
        .opera {
          color: #ffffff;
          width: 81px;
          height: 116px;
          background-size: 100% 100%;
          position: relative;
          top: 0;
          right: -8px;
          display: flex;
          align-items: center;
          justify-content: center;
          writing-mode: vertical-lr;
          letter-spacing: 8px;
        }
      }
    }
    ::v-deep {
      .v-data-table-header th.sortable .v-data-table-header__icon {
        display: none;
      }
      .v-data-table > .v-data-table__wrapper > table > thead > tr > th > span {
        font-weight: bold;
        font-size: 14px;
      }
      tr.v-row-group__header {
        background: #ffffff;
        td {
          text-align: left!important;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.6);
          button {
            display: none;
          }
        }
      }
      .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
      .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(.v-row-group__header) > td {
        padding: 0;
        text-align: center!important;
      }
    }
  }
  .tips {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px 6px;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border-radius: 10px;
    z-index: 9999;
    animation: tipsAnimat 3s linear 0s 1;
  }
  @keyframes tipsAnimat {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
}
::v-deep .diy-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
}
.modal-item {
  display: flex;
  border-bottom: 1px solid #E1E1E1;
  padding: 12px;
}
.modal-img {
  width: 200px;
  margin: 0 auto;
  height: 200px;
  display: block;
}
.modal-item .title {
  width: 64px;
  color: #777777;
  font-size: 14px!important;
  line-height: normal;
}
.modal-item .desc {
  color: #585858;
  font-size: 14px;
  flex: 1;
}
#characterHistory {
  width: 100%;
  height: 200px;
}
</style>