<template>
    <v-card style="width: 100%">
        <v-card-title style="padding-bottom: 0px">
            <v-subheader>所有{{template_config.template_name}}的数据</v-subheader>
        </v-card-title>
        <v-card-text style="padding-top: 0px; padding-left: 32px">
            <v-data-table
                    :headers="getFields"
                    :items="instance_details"
                    :loading="loading"
                    :footer-props="{
            itemsPerPageText : '每页行数：',
            itemsPerPageOptions: pageItems
          }">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.instance_sid">
                        <template v-for="instance in item.template_instances">
                            <td class="hidden-sm-and-down" v-if="instance.is_adjust && instance.is_display_in_list"
                                :key="instance.sid">
                                {{ instance.instance_value }}
                            </td>
                            <td v-else-if="instance.is_display_in_list" :key="instance.sid">
                                <router-link v-bind:to="{ name: '查看数据', params: { sid: instance.instance_sid }}"
                                             class="v-breadcrumbs__item" replace
                                             v-if="(instance.is_key && action!=='add' && item.can_be_edit) || (instance.is_key && action==='view')">
                                    {{ instance.instance_value }}
                                </router-link>
                                <span v-else>{{ instance.instance_value }}</span>
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </template>
                <template slot="no-data">
                    <p class="text-xs-center grey--text">对不起，暂时无数据</p>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
  import {api} from "@/utils/api";
  import {isTextField, isCheckBox, isRadioButton, isGenderMatch} from "@/utils/enumerations";
  import {clone} from "@/utils/strings";

  export default {
    name: "ComboRecord",
    data() {
      return {
        template: this.template_id,
        action: this.action_type,
        template_config: {},
        instance_details: [],
        loading: false,
        items: [],
        record_fields: [],
        max_column: 6,
        pageItems: [10, 20, 50]
      }
    },
    props: {
      template_id: {
        type: String,
        required: true,
        default: () => ""
      },
      action_type: {
        type: String,
        required: true,
        default: () => ""
      }
    },
    computed: {
      getFields() {
        let headers = [];
        for (let item of this.record_fields) {
          if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
            headers.push(item);
          }
        }
        return headers;
      },
      reloadTicket() {
        return this.$store.getters.reloadTicket
      }
    },
    watch: {
      reloadTicket: {
        async handler() {
          this.record_fields = []
          this.instance_details = []
          await this.doLoadData()
        }
      }
    },
    mounted() {
      this.doLoadData()
    },
    methods: {
      async doLoadData() {
        //获取模版信息
        this.template_config = await api.get("character_templates/" + this.template_id + "/?", null)
        let item_count = 0
        //插入发生日期
        let head_item = {}
        head_item.value = 'extra_column'
        head_item.text = '发生日期'
        head_item.sortable = false
        head_item.is_display_in_list = true
        head_item.breakpoints = []
        this.record_fields.push(head_item)
        for (let item of this.template_config.character_templates) {
          if (!item.is_display_in_list) {
            continue
          }
          item_count += 1
          let head_item = {}
          head_item.value = item.character.character_no
          head_item.text = item.character.character_name
          head_item.sortable = false
          head_item.is_display_in_list = item.is_display_in_list
          if (item_count > 2) {
            head_item.breakpoints = ["xs", "sm"]
          } else {
            head_item.breakpoints = []
          }
          this.record_fields.push(head_item)
        }
        this.items = await api.post("template_instances/get_template_instances_by_customer/", {
          i: this.$route.params.id,
          template_ids: [this.template_config.sid]
        })
        for (let item of this.items) {
          let index = 0
          for (let instance_detail of item.template_instances) {
            index += 1
            instance_detail.is_key = false
            instance_detail.instance_sid = item.sid
            instance_detail.is_adjust = false
            if (isCheckBox(instance_detail.character.character_input_type)) {
              instance_detail.instance_value = instance_detail.character_detail.detail_name + "," + instance_detail.instance_value
            }

            for (let rf of this.record_fields) {
              if (instance_detail.character.character_no === rf.value) {
                instance_detail.is_display_in_list = rf.is_display_in_list
                if (rf.breakpoints.length > 0) {
                  instance_detail.is_adjust = true
                }
              }
            }
          }
        }

        //组装最后的数据集
        for (let item of this.items) {
          let instance_item = clone(item)
          instance_item.template_instances = []
          //插入发生日期数据
          let temp_instance_item = {}
          temp_instance_item.instance_value = item.happen_date
          temp_instance_item.is_key = true
          temp_instance_item.instance_sid = item.sid
          temp_instance_item.is_adjust = false
          temp_instance_item.character = {}
          temp_instance_item.character.sid = ''
          temp_instance_item.character.character_no = 'happen_date'
          temp_instance_item.is_display_in_list = true
          instance_item.template_instances.push(temp_instance_item)
          temp_instance_item = {}
          for (let head_item of this.record_fields) {
            if (head_item.value === 'extra_column') {
              continue
            }
            let instance_is_existed = false
            for (let instance_detail of item.template_instances) {
              if (instance_detail.character.character_no === head_item.value) {
                instance_is_existed = true
              }
            }
            if (!instance_is_existed) {
              temp_instance_item = {}
              temp_instance_item.instance_value = ''
              temp_instance_item.is_key = false
              temp_instance_item.instance_sid = item.sid
              temp_instance_item.is_adjust = head_item.breakpoints.length > 0
              temp_instance_item.character = {}
              temp_instance_item.character.sid = ''
              temp_instance_item.character.character_no = ''
              temp_instance_item.is_display_in_list = true
              instance_item.template_instances.push(temp_instance_item)
            } else {
              for (let instance_detail of item.template_instances) {
                if (instance_detail.character.character_no !== head_item.value) {
                  continue
                }
                let item_is_existed = false
                for (let instance_item of instance_item.template_instances) {
                  if (instance_item.character.sid === instance_detail.character.sid) {
                    if (isCheckBox(instance_detail.character.character_input_type)) {
                      instance_item.instance_value = instance_detail.instance_value.replace(",", "") + "," + instance_item.character_detail.detail_name
                    }
                    if (isRadioButton(instance_detail.character.character_input_type)) {
                      instance_item.instance_value = instance_detail.character_detail.detail_name
                    }
                    item_is_existed = true
                  }
                }
                if (!item_is_existed) {
                  let instance_item_detail = clone(instance_detail)
                  if (isCheckBox(instance_item_detail.character.character_input_type)) {
                    instance_item_detail.instance_value = instance_item_detail.character_detail.detail_name
                  }
                  if (isRadioButton(instance_detail.character.character_input_type)) {
                    instance_item_detail.instance_value = instance_item_detail.character_detail.detail_name
                  }
                  instance_item.template_instances.push(instance_item_detail)
                }
              }
            }
          }

          this.instance_details.push(instance_item)
        }
      }
    }
  }
</script>